export const HUBSPOT_CONTACT_PROPERTIES = [
	{
		_id: 0,
		value: 'firstname',
		text: 'First Name',
		type: 'string',
	},
	{
		_id: 1,
		value: 'lastname',
		text: 'Last Name',
		type: 'string',
	},
	{
		_id: 2,
		value: 'phone',
		text: 'Phone',
		type: 'string',
	},
	{
		_id: 3,
		value: 'address',
		text: 'Address',
		type: 'string',
	},
	{
		_id: 4,
		value: 'city',
		text: 'City',
		type: 'string',
	},
	{
		_id: 5,
		value: 'state',
		text: 'State/Region',
		type: 'string',
	},
	{
		_id: 6,
		value: 'zip',
		text: 'Code Postal',
		type: 'string',
	},
	{
		_id: 7,
		value: 'country',
		text: 'Country',
		type: 'string',
	},
	{
		_id: 8,
		value: 'company',
		text: 'Company Name',
		type: 'string',
	},
	{
		_id: 9,
		value: 'jobtitle',
		text: 'Job Title',
		type: 'string',
	},
	{
		_id: 10,
		value: 'website',
		text: 'Website',
		type: 'string',
	},
	{
		_id: 11,
		value: 'lifecyclestage',
		text: 'Lifecycle Stage',
		type: 'string',
	},
]

export const HUBSPOT_PROPERTIES_GROUPS = {
	facebook_ad_properties: 'Facebook Ad Properties',
	facebook_ads_properties: 'Facebook Ads Properties',
	deal_information: 'Deal Information',
	contact_activity: 'Contact Activity',
	contactinformation: 'Contact Information',
	analyticsinformation: 'Analytics Information',
	conversioninformation: 'Converion Information',
	emailinformation: 'Email Information',
	sales_properties: 'Sales Properties',
	socialmediainformation: 'Social Media Information',
}
