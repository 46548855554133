import {
	AnnotationIcon,
	CalendarIcon,
	ClockIcon,
	CodeIcon,
	CursorClickIcon,
	FilmIcon,
	LocationMarkerIcon,
	PhotographIcon,
	PuzzleIcon,
	TagIcon,
} from '@heroicons/react/outline'
import {
	AddLink,
	AddReactionOutlined,
	Article,
	ArticleOutlined,
	Assignment,
	CancelOutlined,
	Casino,
	Dns,
	DynamicForm,
	Grade,
	LaunchOutlined,
	Leaderboard,
	Phone,
	RadioButtonChecked,
	Share,
	ShoppingCart,
	SmartButton,
	TextFields,
	TextureOutlined,
	UploadFile,
	ViewCarousel,
	ViewStream,
} from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

export const ELEMENTS_LIST = {
	// Common block elements
	text: {
		icon: <TextFields />,
		color: '#004AAD',
		name: <FormattedMessage id='editor.configuration.block_type.text' />,
	},
	image: {
		icon: <PhotographIcon />,
		color: '#CB6CE6',
		name: <FormattedMessage id='editor.configuration.block_type.image' />,
	},
	video: {
		icon: <FilmIcon />,
		color: '#CB6CE6',
		name: <FormattedMessage id='editor.configuration.block_type.video' />,
	},
	shape: {
		icon: <PuzzleIcon />,
		color: '#004AAD',
		name: <FormattedMessage id='editor.configuration.block_type.shape' />,
	},
	icon: {
		icon: <AddReactionOutlined />,
		color: '#004AAD',
		name: <FormattedMessage id='editor.configuration.block_type.icon' />,
	},
	button: {
		icon: <CursorClickIcon />,
		color: '#004AAD',
		name: 'Button',
	},
	popup: {
		icon: <ArticleOutlined />,
		color: '#004AAD',
		name: 'popup',
	},
	button_amp: {
		icon: <CursorClickIcon />,
		color: '#004AAD',
		name: 'Button',
	},
	button_invisible: {
		icon: <TextureOutlined />,
		color: '#004AAD',
		name: 'Invisible Button',
	},
	carousel: {
		icon: <ViewCarousel />,
		color: '#004AAD',
		name: <FormattedMessage id='editor.configuration.block_type.carousel' />,
	},
	buttonList: {
		icon: <Dns />,
		color: '#004AAD',
		name: <FormattedMessage id='editor.configuration.block_type.button_list' />,
	},
	communication: {
		icon: <Phone />,
		color: '#FFBD59',
		name: 'Communication',
	},
	other: {
		icon: <CancelOutlined />,
		color: '#000000',
		name: 'Other',
	},

	// Snackeet exclusive block elements
	tag: {
		icon: <TagIcon />,
		color: '#FF914D',
		name: <FormattedMessage id='editor.configuration.block_type.tag' />,
	},
	shopping: {
		icon: <ShoppingCart />,
		color: '#FF914D',
		name: 'Shopify',
	},
	iframe: {
		icon: <CodeIcon />,
		color: '#000000',
		name: <FormattedMessage id='editor.configuration.block_type.iframe' />,
	},
	leaderboard: {
		icon: <Leaderboard />,
		color: '#FFBD59',
		name: 'Leaderboard',
	},
	countdown: {
		icon: <ClockIcon />,
		color: '#FF914D',
		name: <FormattedMessage id='editor.configuration.block_type.countdown' />,
	},
	messenger: {
		icon: <AnnotationIcon />,
		color: '#00BF63',
		name: <FormattedMessage id='editor.configuration.block_type.messenger' />,
	},
	share: {
		icon: <Share />,
		color: '#00BF63',
		name: <FormattedMessage id='editor.configuration.block_type.share' />,
	},
	calendar: {
		icon: <CalendarIcon />,
		color: '#000000',
		name: <FormattedMessage id='editor.configuration.block_type.calendar' />,
	},
	location: {
		icon: <LocationMarkerIcon />,
		color: '#000000',
		name: <FormattedMessage id='editor.configuration.block_type.location' />,
	},
	'social-network': {
		icon: <LaunchOutlined />,
		color: '#00BF63',
		name: <FormattedMessage id='editor.configuration.block_type.social_network' />,
	},
	networks: {
		icon: <LaunchOutlined />,
		color: '#00BF63',
		name: <FormattedMessage id='editor.configuration.block_type.social_network' />,
	},

	// Interaction blocks
	multiple_choice: {
		icon: <SmartButton fontSize='small' />,
		color: '#FF5757',
		name: '',
	},
	form: {
		icon: <DynamicForm fontSize='small' />,
		color: '#FF5757',
		name: '',
	},
	upload_file: {
		icon: <UploadFile fontSize='small' />,
		color: '#FF5757',
		name: '',
	},
	rating: {
		icon: <Grade fontSize='small' />,
		color: '#FF5757',
		name: '',
	},
	story_results: {
		icon: <Assignment fontSize='small' />,
		color: '#FF5757',
		name: '',
	},
	answers: {
		icon: <ViewStream fontSize='small' />,
		color: '#FF5757',
		name: '',
	},
	media_answer: {
		icon: <RadioButtonChecked fontSize='small' />,
		color: '#FF5757',
		name: '',
	},
	game: {
		icon: <Casino fontSize='small' />,
		color: '#FF5757',
		name: '',
	},

	// AMP interactions elements
	outlink: {
		icon: <AddLink />,
		color: '#FF5757',
		name: <FormattedMessage id='editor.configuration.amp_attachment.outlink' />,
	},
	amp_attachment: {
		icon: <Article />,
		color: '#FF5757',
		name: <FormattedMessage id='editor.configuration.amp_attachment.content' />,
	},
	amp_form: {
		icon: <DynamicForm />,
		color: '#FF5757',
		name: <FormattedMessage id='editor.configuration.amp_attachment.form' />,
	},
	amp_interaction: {
		icon: <SmartButton />,
		color: '#FF5757',
		name: <FormattedMessage id='editor.configuration.interaction_type.multiple_choice' />,
	},
}

// Snackeet elements selector
export const SNACKEET_COMMON_SECTION = ['text', 'shape', 'icon', 'button', 'buttonList', 'popup', 'carousel']
export const SNACKEET_MEDIA_SECTION = ['image', 'video']
export const SNACKEET_COMMUNICATION_SECTION = ['share', 'networks']
export const SNACKEET_IFRAME_SECTION = ['iframe', 'location', 'calendar']
export const SNACKEET_PRODUCT_SECTION = ['tag', 'shopping', 'countdown']

export const SNACKEET_INTERACTION_SELECTOR = [
	'answers',
	'multiple_choice',
	'form',
	'upload_file',
	'rating',
	'game',
	'media_answer',
]
export const FORM_SELECTOR = [
	'contact_form',
	'contact_form_complet',
	'leaderboard_form',
	'newsletter_form',
	'event_registration',
]
export const BASIC_FORM_SELECTOR = ['number_form', 'dropdown_form', 'date_form']

export const POLL_SELECTOR = ['poll_text', 'poll_image']
export const QUIZ_SELECTOR = ['quiz_text', 'quiz_image']
export const VOTE_SELECTOR = ['vote_text', 'vote_image']
export const MULTIPLE_CHOICE_SELECTOR = ['answer_text', 'answer_image']
export const MEDIA_ANSWER_SELECTOR = [
	'media_answer_image',
	'media_answer_video',
	'media_answer_audio',
	// 'media_answer_all',
]
export const GAME_SELECTOR = ['wheel']

// AMP elements selector
export const AMP_COMMON_SECTION = ['text', 'button', 'shape', 'icon']
export const AMP_MEDIA_SECTION = ['image', 'video']

export const AMP_INTERACTION_SELECTOR = ['amp_interaction', 'amp_form', 'amp_attachment', 'outlink']
export const AMP_ATTACHMENT_SELECTOR = ['amp_form', 'amp_attachment', 'outlink']

// Buttons preview
export const URL_BUTTONS_PREVIEW = [
	{
		type: 'text',
		buttonType: 'url_click',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid rgba(0, 0, 0, 100)',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#FFFFFF',
			fontSize: '14px',
			fontWeight: 'bold',
		},
		name: 'Click Here',
	},
	{
		type: 'text',
		buttonType: 'url_shop',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '0px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '14px',
			fontWeight: 'bold',
		},
		name: '🛒 Shop Now',
	},
	{
		type: 'text',
		buttonType: 'url_book',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '0px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '15px',
			fontWeight: 'bold',
		},
		name: '🗓️ Book Now',
	},
	{
		type: 'text',
		buttonType: 'url_openInNewTab',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '0px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '12px',
			fontWeight: 'bold',
		},
		name: 'Open In New Tab 🚀',
	},
]

export const DOWNLOAD_BUTTONS_PREVIEW = [
	{
		type: 'text',
		buttonType: 'download_now',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '0px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '12px',
			fontWeight: 'bold',
		},
		name: '↓ Download Now',
	},
	{
		type: 'text',
		buttonType: 'download_save',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '13px',
			fontWeight: 'bold',
		},
		name: '💾 Save File',
	},
]

export const POPUP_BUTTONS_PREVIEW = [
	{
		type: 'text',
		buttonType: 'popup_read',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '0px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '12px',
			fontWeight: 'bold',
		},
		name: 'Read More...',
	},
	{
		type: 'text',
		buttonType: 'popup_open',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '13px',
			fontWeight: 'bold',
		},
		name: 'Open Now',
	},
]

export const COMMUNICATION_BUTTONS_PREVIEW = [
	{
		type: 'text',
		buttonType: 'email_text',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '13px',
			fontWeight: 'bold',
		},
		name: '✉️ Send Email',
	},
	{
		type: 'text',
		buttonType: 'phone_text',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '13px',
			fontWeight: 'bold',
		},
		name: '☎️ Call Me',
	},
	{
		type: 'text',
		buttonType: 'sms_text',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '13px',
			fontWeight: 'bold',
		},
		name: '💬 Send SMS',
	},
]

export const COMMUNICATION_AMP_BUTTONS_PREVIEW = [
	{
		type: 'text',
		buttonType: 'email_text',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid #000',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '13px',
			fontWeight: 'bold',
		},
		name: '✉️ Send Email',
	},
]

export const COPYTEXT_BUTTONS_PREVIEW = [
	{
		type: 'text',
		buttonType: 'copyText_code',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '2px solid #fff',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '12px',
			fontWeight: 'bold',
		},
		name: 'COPY CODE',
	},
	{
		type: 'text',
		buttonType: 'copyText_save',
		styles: {
			textAlign: 'center',
			borderRadius: '40px',
			border: '0px solid #fff',
			backgroundColor: 'rgba(0, 0, 0, 0.7)',
			color: '#fff',
			fontSize: '12px',
			fontWeight: 'bold',
		},
		name: 'TPXZG8U',
	},
]
