export const fr = {
	'editor.story_not_found.title': `La Story n'existe pas.`,
	'editor.story_not_found.button': 'Voir liste des stories',

	'editor.top_bar.saving': 'Sauvegarde en cours',
	'editor.top_bar.saved': 'Modifications sauvegardées',
	'editor.top_bar.unsaved': `Il y a des modifications non enregistrées`,
	'editor.top_bar.preview': 'Aperçu de votre story',
	'editor.top_bar.share': 'Partager votre story',
	'editor.top_bar.publish_change': 'Publier',
	'editor.top_bar.no_publish_change': 'Rien à publier',
	'editor.top_bar.story_errors': '{ count, plural, one { ⚠️ # erreur } other { ⚠️ # erreurs }}',
	'editor.top_bar.story_recommendations': '{ count, plural, one { # suggestion } other { # suggestions }}',
	'editor.top_bar.activate': 'Activer la story',
	'editor.top_bar.deactivate': 'Désactiver la story',
	'editor.top_bar.story_share.title': 'Partagez votre Story',
	'editor.top_bar.story_share.published_at': 'Dernière publication le',
	'editor.top_bar.story_share.customize_url': `Personnaliser l'URL`,
	'editor.top_bar.story_share.open_story': 'Ouvrir la Story',
	'editor.top_bar.story_test.title': 'Tester la Story',
	'editor.top_bar.story_test.link.description.1': 'Ne partagez pas ce lien de test à vos clients.',
	'editor.top_bar.story_test.link.description.2': 'Aucunes données ne seront enregistrées.',
	'editor.top_bar.story_test.qr_code.description.1': 'Ne le partagez pas à vos clients.',
	'editor.top_bar.story_test.qr_code.description.2': 'Scannez le code QR pour lancer le test.',

	'editor.import.button': 'Importer',
	'editor.tooltip.preview_page': 'Voir comment cette page sera affichée',
	'editor.hotspot.timer.disabled.title': `L'Auto-avance est désactivé`,
	'editor.hotspot.timer.disabled.message': `- pour améliorer l'expérience utilisateur<br></br>OU<br></br>- parce qu'au moins un des boutons ou champs de formulaire est obligatoire.`,
	'editor.hotspot.timer.enabled.title': 'Auto-avancer',
	'editor.hotspot.timer.enabled.message': `Passer automatiquement à la page suivante dès que le timer se termine.`,
	'editor.hotspot.timer.amp.message': `La durée de la page est égale à la longueur de la vidéo la plus longue.<br></br><br></br> Si aucune vidéo n'est trouvée, la durée est ajustée en fonction du nombre de mots (durée minimale de <b>10</b> secondes).`,
	'editor.hotspot.widget_text.title': 'Texte de réduction du widget',
	'editor.hotspot.widget_text.message': `Ceci est le texte qui s'affiche lorsque l'on réduit le widget`,

	// Background
	'editor.button.background_edition': `Arrière plan`,
	'editor.button.background_edition.color': `Couleur`,
	'editor.button.background_edition.video': `Vidéo`,
	'editor.button.background_edition.image': `Image, GIF, Sticker`,
	'editor.button.background_edition.empty': `Pas d'arrière plan`,
	'editor.background.title.color': `Couleur de fond`,
	'editor.background.title.video': `Vidéo de fond`,
	'editor.background.title.image': `Image de fond`,
	'editor.background.title.gif': `GIF de de fond`,

	// Overlay
	'editor.background.overlay_title': `Paramètres de l'Overlay`,
	'editor.background.overlay_opacity': `Opacité`,
	'editor.background.overlay_colors': `Couleurs`,

	// Text
	'editor.text_properties.header': 'Édition texte',

	// ImageImport
	'editor.image_import.tooltip.upload': ' Importer une image',
	'editor.image_import.button.crop': `Rogner`,
	'editor.image_import.info': `Vous pouvez coller ci-dessous le lien d'une image`,

	// VideoImport
	'editor.video_import.tooltip.upload': 'Importer une vidéo',
	'editor.video.start': 'Début',
	'editor.video.end': 'Fin',
	'editor.video.speed': 'Vitesse',
	'editor.video.estimated_time': 'Durée estimer',
	'editor.video.timeline_header': 'Options du lecteur vidéo',
	'editor.video_import.info': `*Prend en charge les URL de vidéos provenant de diverses sources, notamment YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion, Kaltura ...`,
	'editor.video.integration.error': `L'URL de la vidéo ne fonctionne pas`,
	'editor.video.integration.error_wavevideo': `Embed Wave Video ne fonctionne pas, assurez-vous que votre vidéo est intégrée`,

	// Appearance
	'editor.appearance.page_info': `Configurez l'apparence des éléments de vos Stories`,
	'editor.appearance.reset_default': `Rétablir les paramètres par défaut`,
	'editor.appearance.reset_warning': `Ceci supprimera tout vos changements.`,

	// Configuration

	'editor.configuration.page_type.start_page': `Page d'accueil`,
	'editor.configuration.page_type.ending_page': `Fin`,
	'editor.configuration.page_type.form': `Formulaire`,
	'editor.configuration.page_type.blank': `Aucune Réponse`,
	'editor.configuration.page_type.multiple_choice': `Bouton/Choix`,
	'editor.configuration.page_type.rating': `NPS/Notation`,
	'editor.configuration.page_type.game': 'Jeu',
	'editor.configuration.page_type.media_answer': 'Réponse média',

	'editor.configuration.block_type.text': `Texte`,
	'editor.configuration.block_type.text.heading_1': `Heading 1`,
	'editor.configuration.block_type.text.heading_2': `Heading 2`,
	'editor.configuration.block_type.text.heading_3': `Heading 3`,
	'editor.configuration.block_type.text.paragraph': `Paragraphe`,
	'editor.configuration.block_type.image': `Image, GIF, Sticker`,
	'editor.configuration.block_type.sticker': `Sticker animé`,
	'editor.configuration.block_type.video': `Vidéo`,
	'editor.configuration.block_type.calendar': `Calendrier`,
	'editor.configuration.block_type.shape': `Forme`,
	'editor.configuration.block_type.social_network': `Réseaux`,
	'editor.configuration.block_type.networks': `Réseaux et Messageries`,
	'editor.configuration.block_type.social-network': `Réseaux`,
	'editor.configuration.block_type.tag': `Étiquette`,
	'editor.configuration.block_type.carousel': `Carrousel`,
	'editor.configuration.block_type.location': `Localisation`,
	'editor.configuration.block_type.messenger': `Messageries`,
	'editor.configuration.block_type.iframe': `Iframe`,
	'editor.configuration.block_type.countdown': `Countdown`,
	'editor.configuration.block_type.leaderboard': `Leaderboard`,
	'editor.configuration.block_type.share': `Partage`,
	'editor.configuration.block_type.icon': 'Icône',
	'editor.configuration.block_type.button': 'CTA: Element',
	'editor.configuration.block_type.button_amp': 'Bouton CTA',
	'editor.configuration.block_type.button_invisible': 'Bouton Invisible',
	'editor.configuration.block_type.communication': 'Communication',
	'editor.configuration.block_type.other': 'Autre',
	'editor.configuration.block_type.popup': 'Pop-up',
	'editor.configuration.block_type.button_description': 'URL, téléchargement, contact...',
	'editor.configuration.block_type.button_amp_description': 'URL, envoyer un e-mail',
	'editor.configuration.block_type.buttonList': 'CTA: Boutons Liste',
	'editor.configuration.block_type.shopping': 'Shopify',

	'editor.configuration.amp_interaction': 'Interaction',
	'editor.configuration.amp_attachment.outlink': 'Lien',
	'editor.configuration.amp_attachment.form': 'Formulaire',
	'editor.configuration.amp_attachment.content': 'Pop-up / Iframe',
	'editor.configuration.amp_attachment.amp_form': 'Formulaire',
	'editor.configuration.amp_attachment.amp_attachment': 'Pop-up',

	'editor.configuration.interaction_type.form': 'Formulaire',
	'editor.configuration.interaction_type.rating': 'NPS / Notation',
	'editor.configuration.interaction_type.answer': 'Réponses',
	'editor.configuration.interaction_type.action': 'Lien/Fichier',
	'editor.configuration.interaction_type.upload_file': 'Téléverser fichier',
	'editor.configuration.interaction_type.multiple_choice': 'Quiz / Sondage / Vote',
	'editor.configuration.interaction_type.answers': 'Boutons réponse',
	'editor.configuration.interaction_type.media_answer': 'Réponse média',
	'editor.configuration.interaction_type.game': 'Roue de Fortune',
	'editor.configuration.interaction_type.story_results': 'Résultats de la Story',

	'editor.configuration.interaction_type.amp_interaction': 'Interaction',
	'editor.configuration.interaction_type.amp_attachment': 'Attachement',
	'editor.configuration.interaction_type.amp_outlink': 'Outlink',

	'editor.selector.instruction': `Cliquer sur les éléments pour les ajouter à Story`,

	'editor.configuration.empty_answers': 'Ajoutez une ou plusieurs boutons/choix',
	'editor.configuration.empty_form': 'Ajoutez un ou plusieurs champs de formulaire',

	'editor.configuration.rating_default_text': 'Votre titre',
	'editor.configuration.media_answer_default_text': 'Comment voulez-vous répondre ?',
	'editor.configuration.background.dimmer': 'Obscurité',

	'editor.configuration.page_settings.logic': 'Logique avancée',
	'editor.configuration.page_settings.animation': 'Animation de Page',
	'editor.configuration.left_tab.pages': 'Pages',
	'editor.configuration.left_tab.elements': 'Édition calques',
	'editor.configuration.editor_button.layers': 'Calques',
	'editor.configuration.editor_button.pageTemplates': 'Type de réponse et template',
	'editor.configuration.left_tab.pageType': 'Type de réponse',
	'editor.configuration.left_tab.pageTemplates': 'Utiliser un template existant',
	'editor.pages_nav.create_ending_page.disable': `L'ajout de plusieurs pages de fin n'est pas disponible pour le moment.<br></br>Si vous souhaitez utiliser cette fonctionnalité, veuillez nous contacter.`,
	// 'editor.pages_nav.create_ending_page.disable': 'Vous avez atteint le nombre limite de pages de fin.<br></br>Si vous souhaitez utiliser cette fonctionnalité, veuillez mettre à niveau votre plan',
	'editor.pages_nav.create_page.disable': `Vous avez atteint le nombre limite de pages par Story.<br></br>Si vous souhaitez utiliser cette fonctionnalité, veuillez mettre à niveau votre plan`,
	'editor.pages_nav.tooltip.add_page': 'Ajouter une page',

	'editor.configuration.share_settings.title': 'Partage paramètre',
	'editor.configuration.share_settings.description_field': 'Personnaliser la description de la story',
	'editor.configuration.share_settings.active_social_networks': 'Réseaux sociaux actifs',
	'editor.configuration.share_settings.enable_widget': 'Activer le widget de partage',
	'editor.configuration.share_settings.modal_title': 'Titre de la fenêtre',
	'editor.configuration.share_settings.modal_title.initial_value': 'Partage',

	// Preview Tooltips
	'editor.button.tooltip.background_edition': `Changez l'arrière-plan de la page avec une couleur, une image ou une vidéo.`,
	'editor.button.tooltip.settings':
		'Changez les paramètres de page ou ajoutez une animation à la page pour la rendre plus dynamique !',
	'editor.button.tooltip.layers': 'Réorganisez et gérez les différentes couches de la page',
	'editor.button.tooltip.pageType': 'Cliquez pour changer le type de réponse',
	'editor.button.tooltip.share_settings': `Configurer le widget de partage pour que vos utilisateurs puissent partager la story à partir de n'importe quelle page sur leurs réseaux.<br></br><br></br> ⚠️ Désactivé en mode test.`,
	'editor.button.tooltip.share_settings_amp': `Configurer le widget de partage pour que vos utilisateurs puissent partager la story à partir de n'importe quelle page sur leurs réseaux.`,

	// Element Selector
	'editor.element_selector.item_disabled': `Cet élément n'est pas disponible sur la première page, veuillez créer une nouvelle page.`,
	'editor.element_selector.item_disabled_ending_page': `Il ne peut y avoir qu'un seul de ces widgets par page.`,
	'editor.element_selector.form_disabled': `Il ne peut y avoir qu'une seule section formulaire sur la page.<br></br><br></br>Vous pouvez ajouter d'autres champs dans la section de droite.`,
	'editor.element_selector.multiple_choices_disabled': `Il ne peut y avoir qu'une seule section boutons/choix sur la page.<br></br><br></br>Vous pouvez ajouter d'autres boutons/choix dans la section de droite.`,
	'editor.element_selector.rating_disabled': "Il ne peut y avoir qu'une seule section NPS/Notation par page.",
	'editor.element_selector.calendar_disabled': "Il ne peut y avoir qu'un seul calendrier par page.",
	'editor.element_selector.leaderboard_disabled':
		'Le <b>Leaderboard</b> doit être <b>activé</b> dans tab <b>paramétrer</b> pour être ajouté à la page.',
	'editor.element_selector.location_disabled': "Il ne peut y avoir qu'une seule localisation par page.",
	'editor.element_selector.outlink_disabled':
		"Il ne peut y avoir qu'une seule <b>bouton lien</b> ou une seule <b>pop-up</b> par page.",
	'editor.element_selector.amp_interaction_disabled':
		"Il ne peut y avoir qu'une seule <b>interaction</b> ou <b>formulaire</b> ou <b>pop-up</b> par page.",
	'editor.element_selector.social_network_disabled': "Il ne peut y avoir qu'un bloc Réseaux sociaux par page.",
	'editor.element_selector.share_disabled': "Il ne peut y avoir qu'un bloc Partage par page.",
	'editor.element_selector.messenger_disabled': "Il ne peut y avoir qu'un bloc Messenger par page.",
	'editor.element_selector.snackeet_interaction_disabled':
		"Impossible d'ajouter une <b>interaction</b> à la <b>page de fin</b> ou à la <b>page d'accueil</b>",

	// Page Templates
	'editor.page_templates.modal.title': 'Appliquer un nouveau template de page',
	'editor.page_templates.modal.description': `Tous les visuels seront modifiés (couleurs, images, videos...).<br></br>Les interactions seront conservées (formulaires, boutons...).`,

	// Page Types
	'editor.page_type.start_page': `Page d'accueil`,
	'editor.page_type.ending_page': 'Page de fin',
	'editor.page_type.blank': 'Bouttons / Form / Interactions',
	'editor.page_type.form': 'Formulaire',
	'editor.page_type.rating': 'NPS/Notation',
	'editor.page_type.answer': 'Bouton classique',
	'editor.page_type.media_answer': 'Réponse média',
	'editor.page_type.game': 'Page de Jeu',
	'editor.page_type.action': 'Lien/Téléverser/Télécharger',
	'editor.page_type.change_page_type': 'Changer le type de réponse',

	// Media Answer
	'editor.interaction.media_answer.video': 'Vidéo',
	'editor.interaction.media_answer.audio': 'Audio',
	'editor.interaction.media_answer.text': 'Texte',
	'editor.interaction.media_answer.video_only': 'Enregistrez votre réponse',
	'editor.interaction.media_answer.audio_only': 'Enregistrez votre réponse',
	'editor.interaction.media_answer.text_only': 'Entrez votre réponse',

	// Countdown
	'editor.block.countdown.days': 'Jours',
	'editor.block.countdown.hours': 'Heures',
	'editor.block.countdown.minutes': 'Minutes',
	'editor.block.countdown.seconds': 'Secondes',

	// Preview Page
	'editor.page_preview.tooltip.timer_set': 'Auto-avance Timer réglé',
	'editor.page_preview.tooltip.animation_set': 'Animation configurée',
	'editor.page_preview.tooltip.logic_set': 'Logique configurée',
	'editor.page_preview.tooltip.interaction_required': 'Réponse Obligatoire',
	'editor.page_preview.tooltip.no_redirection': 'Pas de Redirection',

	// Form Consent
	// French translation for consent message, waiting for an available translation on story viewer
	// In the meantine, we will use EN version on both french and english mode
	// 'editor.form_consent.message': 'En cliquant sur Send, j\'accepte la',
	// 'editor.form_consent.message.policy': '<b><u>politique de confidentialité</u></b>.'
	// 'editor.form_consent.message': 'By clicking on Send, I agree with the',
	// 'editor.form_consent.message.policy': '<b><u>privacy policy</u></b>.',

	'editor.story_name.tooltip': 'Vous pouvez modifier le nom de la story ici',
	'editor.story_name.label': 'Nom de Story',

	'editor.selector.interaction.poll_text': 'Sondage texte',
	'editor.selector.interaction.poll_image': 'Sondage avec image',
	'editor.selector.interaction.quiz_text': 'Quiz texte',
	'editor.selector.interaction.quiz_image': 'Quiz avec image',
	'editor.selector.interaction.vote_text': 'Vote',
	'editor.selector.interaction.vote_image': 'Vote avec image',
	'editor.selector.interaction.answer_text': 'Texte',
	'editor.selector.interaction.answer_image': 'Image',

	'editor.selector.interaction.media_answer_image': 'Photo',
	'editor.selector.interaction.media_answer_video': 'Enregistreur vidéo',
	'editor.selector.interaction.media_answer_audio': 'Enregistreur audio',
	'editor.selector.interaction.media_answer_all': 'Options multiples',
	'editor.selector.interaction.media_answer_image.title': 'Réponse par photo',
	'editor.selector.interaction.media_answer_video.title': 'Réponse par vidéo',
	'editor.selector.interaction.media_answer_audio.title': 'Réponse par audio',

	'editor.selector.interaction.form_standard.title': 'Formulaire Standard',
	'editor.selector.interaction.form_basics.title': 'Formulaire Basique',

	'editor.selector.interaction.contact_form': 'Formulaire de contact court',
	'editor.selector.interaction.contact_form_complet': 'Formulaire de contact long',
	'editor.selector.interaction.leaderboard_form': 'Formulaire de Leaderboard',
	'editor.selector.interaction.newsletter_form': "Formulaire d'inscription à la newsletter",
	'editor.selector.interaction.event_registration': 'Inscription à un événement',

	'editor.selector.interaction.number_form': 'Champ numérique',
	'editor.selector.interaction.dropdown_form': 'Menu déroulant',
	'editor.selector.interaction.checkbox_form': 'Case à cocher',
	'editor.selector.interaction.date_form': 'Champ de date',

	'editor.selector.interaction.wheel': 'Roue de fortune',

	// Button block
	'editor.button.text_button': 'Bouton de texte',
	'editor.button.icon_button': `Bouton d'icône`,
	'editor.button.invisible_button': 'Bouton invisible',
	'editor.button.navigation_button': 'Bouton de navigation',
	'editor.button.url_button': 'Bouton URL',
	'editor.button.download_button': 'Bouton de téléchargement',
	'editor.button.popup_button': 'Bouton popup',
	'editor.button.communication_button': 'Bouton de communication',
	'editor.button.copyText_button': 'Bouton de copie de texte',

	'editor.button.text_rectangle': 'Bouton rectangulaire',
	'editor.button.text_outlined': 'Bouton contour',
	'editor.button.text_filled': 'Bouton rempli',
	'editor.button.text_circle_filled': 'Cercle rempli',
	'editor.button.text_circle_outlined': 'Cercle contour',

	'editor.button.invisible_rectangle': 'Bouton rectangulaire',
	'editor.button.invisible_circle': 'Bouton cercle',

	'editor.button.navigation_back': 'Retourner',
	'editor.button.navigation_next': 'Suivant',

	'editor.button.invisible.descrpition': `Le fond rayé est uniquement destiné à l'édition.`,

	'editor.selector.carousel.card': 'Carte',
	'editor.selector.carousel.detailed': 'Carte détaillée',
	'editor.selector.carousel.rounded': 'Carte circulaire',
	'editor.selector.carousel.product': 'Produit',

	'editor.selector.section.interactions': 'Interactions :',
	'editor.selector.section.common_elements': 'Éléments Courants :',
	'editor.selector.section.media': 'Média :',
	'editor.selector.section.shape': 'Forme :',
	'editor.selector.section.communication': 'Communication :',
	'editor.selector.section.iframe': 'Iframe :',
	'editor.selector.section.product': 'Produit et événement :',
	'editor.selector.section.leaderboard': 'Leaderboard :',

	'editor.selector.leaderboard.upgrade':
		"La fonctionnalité de Leaderboard n'est pas disponible pour votre plan actuel.",
}
