import _assign from 'lodash/assign'

export class PrivateRoute {
	constructor(args) {
		_assign(this, args)
	}

	getQuery(url) {
		if (typeof url !== 'string') {
			return {}
		}
		const match = url.match(this.regexPath)
		return match?.groups ?? {}
	}
}

export class Routes {
	static NON_REDIRECTABLE = [
		{
			regexPath: /^\/oauth/,
		},
		{
			regexPath: /^\/shopify-oauth/,
		},
		{
			regexPath: /^\/404/,
		},
	]

	static PUBLIC = [
		{
			regexPath: /^\/login$/,
		},
		{
			regexPath: /^\/register$/,
		},
		{
			regexPath: /^\/activation$/,
		},
		{
			regexPath: /^\/password_reset$/,
		},
		{
			regexPath: /^\/mobile$/,
		},
	]

	static PRIVATE = [
		{
			id: 'organizations-list',
			regexPath: /^\/$/,
		},
		{
			id: 'redirect-page',
			regexPath: /^\/redirect/,
		},
		{
			id: 'customer-account',
			regexPath: /^\/account/,
		},
		// Match list routes
		{
			id: 'stories-list',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/list/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
		},
		{
			id: 'widgets-list',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/widgets/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
		},
		{
			id: 'brands-list',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/brands/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
		},
		{
			id: 'leaderboards-list',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/leaderboards/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
			leaderboardRequired: true,
		},
		// Match editors routes
		{
			id: 'editor',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/edit\/(?<story_id>[^\s\?#]+)/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
		},
		{
			id: 'widget-editor',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/widget\/(?<widget_id>[^\s\?#]+)/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
		},
		{
			id: 'brand-editor',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/brand\/(?<brand_id>[^\s\?#]+)/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
		},
		{
			id: 'leaderboard-editor',
			regexPath: /^\/(?<organization_slug>[^\s]+)\/leaderboard\/(?<leaderboard_id>[^\s\?#]+)/,
			organizationRequired: true,
			projectRequired: true,
			subscriptionRequired: true,
			leaderboardRequired: true,
		},
		// Other routes
		{
			id: 'interactions',
			regexPath: /^\/(?<organization_slug>[^\s\/]+)\/interactions\/?/,
			projectRequired: true,
			organizationRequired: true,
			subscriptionRequired: true,
		},
		{
			id: 'settings',
			regexPath: /^\/(?<organization_slug>[^\s\/]+)\/settings\/?/,
			organizationRequired: true,
			projectRequired: true,
		},
		// Should be last matching route
		{
			id: 'workspaces-list',
			regexPath: /^\/(?<organization_slug>[^\s\/]+)\/?/,
			organizationRequired: true,
			subscriptionRequired: true,
			multipleWorkspacesRequired: true,
		},
	].map((r) => new PrivateRoute(r))

	getRoute(url) {
		return this.findRoute([...Routes.NON_REDIRECTABLE, ...Routes.PUBLIC, ...Routes.PRIVATE], url)
	}

	findRoute(routesArray, url) {
		const [path, queryParams] = url.split('?')
		return routesArray?.find((route) => route.regexPath.test(path))
	}

	// Non Redirectionable

	isNonRedirectable(url) {
		return !!this.findRoute(Routes.NON_REDIRECTABLE, url)
	}

	// Public Routes

	isPublic(url) {
		return !!this.findRoute(Routes.PUBLIC, url)
	}

	// Private Routes
	isPrivate(url) {
		return Boolean(this.getPrivateRoute(url))
	}

	getPrivateRoute(url) {
		const route = this.findRoute(Routes.PRIVATE, url)
		// Make sure route isn't public or non redirectable
		// because regex can match with organization_slug
		return !this.isPublic(url) && !this.isNonRedirectable(url) ? route : undefined
	}
}
