export const en = {
	'edition_menu.select_element': 'Select an element to start editing',

	'edition_menu.timer.checkbox_label_display': 'Is visible',
	'edition_menu.timer.checkbox_label_active': 'Is active',
	'edition_menu.timer.title': 'Timer Editor',

	'edition_menu.navigation.next_page': 'Next page or by logic',
	'edition_menu.navigation.none': 'NO REDIRECTION',
	'edition_menu.navigation.go_back': 'Go back',
	'edition_menu.navigation.goto': 'Go to',
	'edition_menu.navigation.your_pages': 'Your pages',
	'edition_menu.navigation.goto_on_confirm': 'On confirmation, redirect to',
	'edition_menu.navigation.button_type.text': 'Text Answer',
	'edition_menu.navigation.button_type.link': 'Link',
	'edition_menu.navigation.button_type.file_uploader': 'Upload',
	'edition_menu.navigation.button_type.file_downloader': 'Download',
	'edition_menu.navigation.button_type.image': 'Image Answer',
	'edition_menu.navigation.button_correct': 'Correct Answer',
	'edition_menu.navigation.button_wrong': 'Wrong Answer',

	'edition_menu.proportion.title': 'Move your {media}',
	'edition_menu.proportion.none': 'Original',
	'edition_menu.proportion.contain': 'Contain',
	'edition_menu.proportion.cover': 'Cover',

	'edition_menu.settings.position': 'Position',
	'edition_menu.settings.size': 'Size',
	'edition_menu.settings.reframe': 'Reframe',
	'edition_menu.settings.alignment': 'Text Align',
	'edition_menu.settings.align_to_page': 'Align to page',
	'edition_menu.settings.align_elements': 'Align elements',
	'edition_menu.slider.rotation': 'Rotation',
	'edition_menu.slider.skewX': 'Skew Horizontal',
	'edition_menu.slider.skewY': 'Skew Vertical',
	'edition_menu.slider.blur': 'Blur',
	'edition_menu.slider.opacity': 'Opacity',
	'edition_menu.slider.borderRadius': 'Boder Radius',
	'edition_menu.slider.borderWidth': 'Border Size',
	'edition_menu.slider.border': 'Border',
	'edition_menu.slider.iconSize': 'Size',
	'edition_menu.icon.title': 'Icon',

	'edition_menu.slider.width': 'Width',
	'edition_menu.slider.height': 'Height',
	'edition_menu.slider.position.horizontal': 'Horizontal',
	'edition_menu.slider.position.vertical': 'Vertical',

	'edition_menu.start_btn.rewards': 'Add emoji on click',

	'edition_menu.rounded_button.title': 'Rounded Button',
	'edition_menu.rounded_button.size': 'Size',
	'edition_menu.rounded_button.fontSize': 'Font Size',

	'edition_menu.block_default.value': 'Add some text here...',

	// Properties tabs
	'edition_menu.tabs.select_file': 'Select a file',
	'edition_menu.tabs.properties': 'Settings',
	'edition_menu.tabs.animation': 'Animation',
	'edition_menu.tabs.cta': 'Call to Action',
	'edition_menu.tabs.text_settings': 'Text Style',
	'edition_menu.tabs.style': 'Style',
	'edition_menu.tabs.location': 'Location',
	'edition_menu.tabs.countdown': 'Countdown',
	'edition_menu.tabs.share': 'Share',
	'edition_menu.tabs.social_network': 'Social Network',
	'edition_menu.tabs.display_delay': `Display delay`,

	// Tabs
	'edition_menu.tabs.gallery': 'Uploads',
	'edition_menu.tabs.pexels': 'Pexels',
	'edition_menu.tabs.stickers': 'Sticker',
	'edition_menu.tabs.emojis': 'Emoji',
	'edition_menu.tabs.gif': 'GIF',
	'edition_menu.tabs.link': 'Link',

	// Block Accordion
	'edition_menu.accordion.block_position': 'Position',
	'edition_menu.accordion.block_container': 'Block',
	'edition_menu.accordion.block_border': 'Border',
	'edition_menu.accordion.text_styles': 'Text',
	'edition_menu.accordion.shape_styles': 'Shape',
	'edition_menu.accordion.icon_styles': 'Icon',
	'edition_menu.accordion.countdown_appearance': 'Appearance',
	'edition_menu.accordion.countdown_title': 'Title',
	'edition_menu.accordion.countdown_clock': 'Clock',
	'edition_menu.accordion.countdown_subtitles': 'Subtitles',
	'edition_menu.accordion.countdown_days': 'Days',
	'edition_menu.accordion.countdown_hours': 'Hours',
	'edition_menu.accordion.countdown_minutes': 'Minutes',
	'edition_menu.accordion.countdown_seconds': 'Seconds',

	// Cta options
	'edition_menu.cta_options.header': 'Call To Action',
	'edition_menu.cta_options.enabled': 'Enabled',
	'edition_menu.cta_options.enabled.description':
		'Make this element clickable and redirect to external link, internal page, send e-mail or open pop up',
	'edition_menu.cta_options.selection': 'Select a type of CTA',
	'edition_menu.cta_options.tooltip_enabled': 'Tooltip Enabled',
	'edition_menu.cta_options.openInNewTab': 'Open in new tab',
	'edition_menu.cta_options.automatic_redirect': 'Redirect automatically',
	'edition_menu.cta_options.automatic_redirect_delay': 'Redirect after',
	'edition_menu.cta_options.redirect_directly': 'Redirect directly',
	'edition_menu.cta_options.redirection_type.none': 'None',
	'edition_menu.cta_options.redirection_type.external': 'External link',
	'edition_menu.cta_options.redirection_type.internal': 'Go to page',
	'edition_menu.cta_options.redirection_type.mailto': 'Send email',
	'edition_menu.cta_options.redirection_type.phoneTo': 'Phone Call',
	'edition_menu.cta_options.redirection_type.messageTo': 'Send SMS',
	'edition_menu.cta_options.redirection_type.attachment': 'Open Pop Up',
	'edition_menu.cta_options.redirection_type.copyToClipboard': 'Copy to Clipboard',
	'edition_menu.cta_options.redirection_type.download': 'Download File',
	'edition_menu.cta_options.link_external': 'Link',
	'edition_menu.cta_options.link_internal': 'Go to page',
	'edition_menu.cta_options.mail_mailto': 'Email',
	'edition_menu.cta_options.phoneTo': 'Phone number',
	'edition_menu.cta_options.phoneTo.description': 'Make a call to the phone number below on click',
	'edition_menu.cta_options.messageTo.description': 'Send a message to the phone number below on click',
	'edition_menu.cta_options.default_message': 'Default Message (Optional)',
	'edition_menu.cta_options.tooltip': 'Message',
	'edition_menu.cta_options.copyToClipboard.value': 'Text to Copy',
	'edition_menu.cta_options.copyToClipboard.placeholder': 'Enter text to copy',
	'edition_menu.cta_options.icon': 'Icon',
	'edition_menu.cta_options.icon.description': 'The favicon of the link domain will be chosen as the default icon.',
	'edition_menu.cta_options.mail_mailto.placeholder': 'Enter an email',
	'edition_menu.cta_options.messageTo.placeholder': 'Enter a default message',
	'edition_menu.cta_options.element_name': 'Name the element',

	'edition_menu.cta_options.error.link': 'URL cannot be empty',
	'edition_menu.cta_options.empty.mailto': 'Email cannot be empty',
	'edition_menu.cta_options.empty.phoneNumber': 'Phone number cannot be empty',
	'edition_menu.cta_options.error.mailto': 'Please enter a valid email',
	'edition_menu.cta_options.error.tooltip': 'Tooltip cannot be empty',
	'edition_menu.cta_options.redirect.warning':
		'The element must be at the bottom of the Story otherwise the direct redirection will not work.',

	// Interactions
	'edition_menu.interactions.remove_interaction.title': 'Remove The Interaction',
	'edition_menu.interactions.remove_interaction.description':
		'By removing this interaction, you will lose all gathered data from the page. <br></br> <br></br>Are you sure you want to continue?',

	// Attachment As Template
	'edition_menu.amp_attachment.save_as_template.title': 'Save the attachment as template',
	'edition_menu.amp_attachment.save_as_template.description':
		'Save the current attachment as a template to reuse later in the workplace stories.',

	// Form
	'edition_menu.form.title': 'Form Editor',
	'edition_menu.form_properties.title': 'Form Settings',
	'edition_menu.fields.title': 'Fields Settings',
	'edition_menu.field.title': 'Field Editor',
	'edition_menu.form.mandatory': 'Required*',
	'edition_menu.form.add_field': 'Add a new field',
	'edition_menu.form.add_option': 'Add a new option',
	'edition_menu.form.open_settings': 'Edit options',
	'edition_menu.form.indicator': 'Indicator',
	'edition_menu.form.style': 'Fields: Appearance',
	'edition_menu.form.border': 'Fields: border',
	'edition_menu.label.style': 'Labels: Appearance',
	'edition_menu.gdpr.link.style': 'GDPR: Message',
	'edition_menu.gdpr.modal.style': 'GDPR: Popup',
	'edition_menu.gdpr.settings.description':
		'GDPR consent displayed in each form for Edit purposes. In a Story, once checked and submitted, it will not be displayed again.',

	'edition_menu.form.email_verification': 'Email Verification',
	'edition_menu.form.email_verification_instruction': `Verify the validity of the email by sending a verification code upon form submission.<br></br>An <b>email</b> field required`,
	'edition_menu.form.email_captcha': 'Activate reCAPTCHA',
	'edition_menu.form.email_captcha_instruction': `Protects your form submission from fraud and abuse without creating friction`,
	'edition_menu.form.email_disposable': 'Block disposable emails',
	'edition_menu.form.email_disposable_instruction': `Prevents fraud and abuse by blocking the use of known temporary emails.<br></br><br></br>⚠️ Does not block newly created valid temporary emails generated daily ⚠️`,
	'edition_menu.form.unique_email': 'Unique Email',
	'edition_menu.form.unique_email_instruction': `Email addresses must be <b>unique</b> among all users.<br></br>The variable named <b>email</b> must be assigned to an email field.`,
	'edition_menu.form.email_required':
		'In order to use this, you need to add an email field and link it to the <b>email</b> variable',
	'edition_menu.form.custom_error_messages': 'Error Messages',
	'edition_menu.form.custom_error_messages.instruction':
		'Allows you to personalize the text displayed to users when a form field is not properly filled out.',

	'edition_menu.form.consent.title': 'GDPR consent',
	'edition_menu.form.consent.toggle': 'Ask for consent',
	'edition_menu.form.consent.text': 'Customize consent text',
	'edition_menu.form.consent.text.placeholder': 'Type your text here',
	'edition_menu.form.consent.text.error': 'Customize the consent message text',
	'edition_menu.form.consent.message_color': 'Consent message color',
	'edition_menu.form.consent.customize_message': 'Customize consent message',
	'edition_menu.form.consent.customize_message.placeholder': 'Type your message here',
	'edition_menu.form.consent.customize_message.instruction': `*Embed in consent text your link with HTML tag. Ex: <a href='https://www.snackeet.com/' target='_blank'> Anchor Link </a>`,
	'edition_menu.form.consent.url': 'Redirect to your privacy policy',
	'edition_menu.form.consent.url.placeholder': 'Insert your URL...',
	'edition_menu.form.consent.button_hyperlien': 'Hyperlink button',
	'edition_menu.form.consent.button_hyperlien.placeholder': 'Text visible on the button (e.g: click here)',
	'edition_menu.form.consent.button_validation': 'Validation button',
	'edition_menu.form.consent.button_validation.placeholder': 'Accept',
	'edition_menu.form.label.title': 'Label (Optional):',

	'edition_menu.form.variable.tooltip.title': `Assigning a variable allows to:`,
	'edition_menu.form.variable.tooltip.subtitle': `<ul><li>Store it for later use (i.e. in text blocks)</li><li>Do calculations based on user input</li><li>Use it in conditional logic</li></ul>`,
	'edition_menu.form.variable.title': 'Insert Variables',

	'edition_menu.form.field_type.firstname': 'First Name',
	'edition_menu.form.field_type.lastname': 'Last Name',
	'edition_menu.form.field_type.text': 'Text',
	'edition_menu.form.field_type.email': 'Email',
	'edition_menu.form.field_type.phone': 'Phone',
	'edition_menu.form.field_type.number': 'Number',
	'edition_menu.form.field_type.select': 'Dropdown',
	'edition_menu.form.field_type.date': 'Date',
	'edition_menu.form.field_type.boolean': 'Boolean',
	'edition_menu.form.field_type.website': 'Website',
	'edition_menu.form.field_type.consent': 'Consent',
	'edition_menu.form.field_type.checkbox': 'Checkbox',
	'edition_menu.form.field_type.title': 'Title',
	'edition_menu.form.field_type.paragraph': 'Paragraph',
	'edition_menu.form.field_type.image': 'Image',
	'edition_menu.form.field_type.button': 'Button',
	'edition_menu.form.field_type.disclaimer': 'Disclaimer',
	'edition_menu.form.field_type.iframe': 'Iframe',
	'edition_menu.form.field_type.username': 'Leaderboard Username',

	'edition_menu.form.field_description.defaultDomain': 'Default Domain:',
	'edition_menu.form.field_description.advanced_settings': 'Advanced Settings',
	'edition_menu.form.field_description.advanced_settings.number': 'Number Options and Restrictions',
	'edition_menu.form.field_description.advanced_settings.text': 'Text Options',
	'edition_menu.form.field_description.advanced_settings.date': 'Date Options',
	'edition_menu.form.field_description.advanced_settings.select': 'Add Elements in List',
	'edition_menu.form.field_description.advanced_settings.email': 'Email Options',
	'edition_menu.form.field_description.advanced_settings.gdprConsent': 'Consent description and link',
	'edition_menu.form.field_description.options_settings': 'Options Settings',
	'edition_menu.form.field_description.before': 'Before:',
	'edition_menu.form.field_description.after': 'After:',
	'edition_menu.form.field_description.multiple_selections': 'Multiple Selections',
	'edition_menu.form.field_description.limit_selections': 'Limit Selections:',
	'edition_menu.form.field_description.required': 'Required',
	'edition_menu.form.field_description.tooltip.required':
		'Users will not be able to go to the next page without giving an answer.',
	'edition_menu.form.field_description.fields_settings': 'Fields Settings',

	'edition_menu.form.field.default_domain.add': 'Add a new domain',
	'edition_menu.form.field.text_limit.detail': 'Number of characters: ',
	'edition_menu.form.field.number_limit.detail': 'Number of digits: ',

	'edition_menu.form.modal_title.text': 'Text Field Settings',
	'edition_menu.form.modal_title.email': 'Email Field Settings',
	'edition_menu.form.modal_title.number': 'Number Field Settings',
	'edition_menu.form.modal_title.date': 'Date Field Settings',

	'edition_menu.form.field_settings.multiple': 'Multiple Selections',
	'edition_menu.form.field_settings.multiple.message': 'Allow user to choose multiple answers',
	'edition_menu.form.field_settings.limit_choices': 'Limit choices',
	'edition_menu.form.field_settings.limit_choices.message': 'Limit maximum number of choices for this input field',
	'edition_menu.form.field_settings.options': 'Options list',
	'edition_menu.form.field_settings.new_option': 'New Option',
	'edition_menu.form.field_settings.default_country': 'Default Country',
	'edition_menu.form.customize_errors': 'Customize Error Messages',
	'edition_menu.form.field_settings.activeMinMax': 'Min-Max limit',
	'edition_menu.form.field_settings.activeMinMax.message':
		'Allows users to input a numeric value within a specified range, helping to ensure that only valid data is entered',
	'edition_menu.form.field_settings.date_format.eu': 'dd/mm/yyyy',
	'edition_menu.form.field_settings.date_format.us': 'mm/dd/yyyy',
	'edition_menu.form.field_settings.date_format.title': 'Format',
	'edition_menu.form.field_settings.activeDateRange': 'Date Range',
	'edition_menu.form.field_settings.hasDefaultDomain': 'Default Domain',
	'edition_menu.form.field_settings.hasDefaultDomain.message':
		'Add a default domain to the end of an email form field, saving time and reducing the risk of typos',
	'edition_menu.form.field_settings.defaultDomain': 'Domain',
	'edition_menu.form.field_settings.defaultDomainPlaceholder': 'Example: @snackeet.com',
	'edition_menu.form.field_settings.defaultDomainEmpty': 'Domain cannot be empty',
	'edition_menu.form.field_settings.defaultDomainInvalid': 'Invalid Domain',
	'edition_menu.form.field_settings.limit_number': 'Limit number of digits',
	'edition_menu.form.field_settings.limit_number.message':
		'Allows you to restrict the number of digits that a user can enter into a number field',
	'edition_menu.form.field_settings.limit_text': 'Limit number of characters',
	'edition_menu.form.field_settings.limit_text.message':
		'Allows you to restrict the number of characters that a user can enter into a text field',

	'edition_menu.form.field.default_placeholder.firstname': 'Enter your first name',
	'edition_menu.form.field.default_placeholder.lastname': 'Enter your last name',
	'edition_menu.form.field.default_placeholder.text': 'Type something...',
	'edition_menu.form.field.default_placeholder.website': 'https://',
	'edition_menu.form.field.default_placeholder.email': 'Enter your email',
	'edition_menu.form.field.default_placeholder.phone': 'Enter your phone',
	'edition_menu.form.field.default_placeholder.number': 'Enter a number',
	'edition_menu.form.field.default_placeholder.select': 'Choose an option',
	'edition_menu.form.field.default_placeholder.date': 'Enter your date',
	'edition_menu.form.field.default_label.checkbox': 'Subscribe to our newsletter',
	'edition_menu.form.field.default_anchor.title': 'Privacy policy',
	'edition_menu.form.field.gdpr_tooltip': `This consent box will remain the same throughout the entire story. If you need checkboxes that are only available on this form page, please use standard checkboxes.`,

	'edition_menu.form.field.delete_error': 'Cannot delete the only Field, add a new one to delete',

	'edition_menu.form.gdpr.activate': 'Activate GDPR Consent',
	'edition_menu.form.gdpr.gdpr_activated': 'GDPR Consent Activated',

	'edition_menu.form.label.placeholder': 'Enter your label',
	'edition_menu.amp_form.url.placeholder': 'Enter your URL',

	'edition_menu.amp_form.modal.form_tab': 'Form',
	'edition_menu.amp_form.modal.success_tab': 'Post-submission',

	'edition_menu.form.button.add': 'Add a new field',
	'edition_menu.form.add_field.description': 'Add a new field',
	'edition_menu.form.add_element.description': 'Add a new element',
	'edition_menu.form.edit_styles.description': 'Form Styles',
	'edition_menu.form.edit_form.description': 'Form Content',
	'edition_menu.form.submit_button.description': 'Submit Button',
	'edition_menu.form.helper.mandatory':
		'* When the required field is activated, the navigation to the next page is disabled. The user must fill in the field to be able to submit the form and go to the next page.',

	'edition_menu.amp_form.cta_text.title': 'Swipe up text',
	'edition_menu.amp_form.edit': 'Edit Form',
	'edition_menu.amp_attachment.edit': 'Edit Pop Up',
	'edition_menu.amp_attachment.create_new': 'Add A Pop Up',
	'edition_menu.amp_attachment.iframe_warning': `By using this element, the Story may not appear in Google Search results.`,

	// Attachment
	'edition_menu.attachement.edit_content.description': 'Pop Up Content',

	// Outlink
	'edition_menu.attachment.outlink_header': 'Link Editor',
	'edition_menu.attachment.outlink_link': 'Link',
	'edition_menu.attachment.outlink_link_placeholder': 'Enter a valid link',
	'edition_menu.attachment.outlink_link_error': 'Please enter a valid URL. For example: https://example.com',
	'edition_menu.attachment.outlink_dark_theme': 'Dark Theme',
	'edition_menu.attachment.outlink_title': 'Title',
	'edition_menu.attachment.outlink_title_placeholder': 'Enter a title',
	'edition_menu.attachment.outlink_title_error': `Button's title should not be empty`,
	'edition_menu.attachment.outlink_has_icon': 'Has Icon',
	'edition_menu.attachment.outlink_icon_image': 'Icon Image',

	'edition_menu.attachment.outlink_default': 'Swipe up',

	// AMP Form
	'edition_menu.attachment.form_header': 'Form Editor',

	// AMP Attachment
	'edition_menu.attachment.attachment_header': 'Pop Up Editor',

	// AMP Interactions
	'edition_menu.amp_interactions.list_header': 'Interactions List',
	'edition_menu.amp_interactions.amp_poll.title': 'Poll',
	'edition_menu.amp_interactions.amp_binary_poll.title': 'Binary Poll',
	'edition_menu.amp_interactions.amp_image_poll.title': 'Image Poll',
	'edition_menu.amp_interactions.amp_quiz.title': 'Quiz',
	'edition_menu.amp_interactions.amp_image_quiz.title': 'Image Quiz',
	'edition_menu.amp_interactions.amp_slider.title': 'Slider',

	'edition_menu.amp_interactions.options.header': 'Options',
	'edition_menu.amp_interactions.amp_poll.header': 'Poll',
	'edition_menu.amp_interactions.amp_quiz.header': 'Quiz',
	'edition_menu.amp_interactions.amp_binary_poll.header': 'Binary Poll',
	'edition_menu.amp_interactions.amp_image_poll.header': 'Image Poll',
	'edition_menu.amp_interactions.amp_image_quiz.header': 'Image Quiz',
	'edition_menu.amp_interactions.amp_slider.header': 'Slider',

	'edition_menu.amp_interactions.style.question': 'Question',
	'edition_menu.amp_interactions.style.question_placeholder': 'Enter a question',
	'edition_menu.amp_interactions.style.size': 'Size',
	'edition_menu.amp_interactions.style.color': 'Color',
	'edition_menu.amp_interactions.style.backgroundColor': 'Back. color',
	'edition_menu.amp_interactions.style.alignment': 'Title align',
	'edition_menu.amp_interactions.style.theme': 'Theme',
	'edition_menu.amp_interactions.style.option_style': 'Option style',
	'edition_menu.amp_interactions.style.option_color': 'Option color',
	'edition_menu.amp_interactions.style.slider_color': 'Color',
	'edition_menu.amp_interactions.style.slider_icon': 'Icon',

	'edition_menu.amp_interactions.options.title_placeholder': 'Enter an option',
	'edition_menu.amp_interactions.options.alt_placeholder': 'Enter image alt text',
	'edition_menu.amp_interactions.options.add_option': 'Add a new option',
	'edition_menu.amp_interactions.options.warning': 'The number of options must be between 2 and 4',
	'edition_menu.amp_interactions.options.isCorrect': 'Is Correct',

	'edition_menu.amp_interaction.title_error': `Option's title cannot be empty`,
	'edition_menu.amp_interaction.image_error': `Option's image cannot be empty`,
	'edition_menu.amp_interaction.image_title_error': `Option's alternative title cannot be empty`,

	'edition_menu.amp_interaction.tooltip.confetti': 'This emoji will appear when this button is clicked',
	'edition_menu.amp_interaction.tooltip.delete': `Remove Option`,
	'edition_menu.amp_interaction.tooltip.correct': 'AMP Interaction only supports one correct answer at a time',

	// Start Button
	'edition_menu.results.display_start_button': 'Display start button',
	'edition_menu.results.undisplay_start_button.title': 'Hide Start Button',
	'edition_menu.results.undisplay_start_button.warning': `You are about to <b>hide</b> the <b>start button</b>. <b>A timer</b> will be <b>required</b> after the <b>start button</b> is hidden.`,

	// Rating
	'edition_menu.rating.title': 'NPS/Rating Editor',
	'edition_menu.rating.redirection_description': `Use <b>Advanced Logic { icon }</b> to redirect according to the rating`,
	'edition_menu.rating.hasForm': 'Show feedback form',
	'edition_menu.rating.hasForm.description': 'Show a feedback form after the rating is submitted',
	'edition_menu.rating.feedback_form_btn': 'Customize feedback form',
	'edition_menu.rating.feedback_display_conditions': 'Display feeback form when the value of rating is:',
	'edition_menu.rating.has_percentage': 'Display percentage',

	'edition_menu.rating.modal.title': 'Feedback Form Settings',
	'edition_menu.rating.modal.form_title': 'Form title',
	'edition_menu.rating.modal.form_messenger_placeholder': 'Form message placeholder',
	'edition_menu.rating.modal.form_submit_btn': 'Submit button',

	// Sharing
	'edition_menu.sharing.title': 'Sharing Editor',
	'edition_menu.sharing.settings': 'Sharing Settings',
	'edition_menu.sharing.active_networks': 'Active Sharing',

	// Social networks
	'edition_menu.social_networks.title': 'Social link Editor',
	'edition_menu.social_networks.active_networks': 'Active Social link',
	'edition_menu.networks.title': 'Networks link Editor',
	'edition_menu.networks.active_platforms': 'Active network platforms',

	// Messengers
	'edition_menu.messenger.title': 'Messenger Editor',
	'edition_menu.messenger.active_platforms': 'Active messenger platforms',
	'edition_menu.messenger.phonecall': 'Phonecall',
	'edition_menu.messenger.username': 'Username',
	'edition_menu.messenger.number': 'Number',
	'edition_menu.messenger.message': 'Message',

	// Media Answer
	'edition_menu.media_answer.buttons_style': 'Buttons',
	'edition_menu.media_answer.header': 'Media Answers',
	'edition_menu.media_answer.title': 'Title',
	'edition_menu.media_answer.video_title': 'Video Title',
	'edition_menu.media_answer.selfie_title': 'Selfie Title',
	'edition_menu.media_answer.audio_title': 'Audio Title',
	'edition_menu.media_answer.text_title': 'Text Title',
	'edition_menu.media_answer.hasVideo': 'Video Answer',
	'edition_menu.media_answer.hasSelfie': 'Selfie Answer',
	'edition_menu.media_answer.hasAudio': 'Audio Answer',
	'edition_menu.media_answer.hasText': 'Text Answer',
	'edition_menu.media_answer.duration': 'Video/Audio duration',
	'edition_menu.media_answer.required': 'Required',
	'edition_menu.media_answer.replayable': 'Replayable',
	'edition_menu.media_answer.setting_styles': 'Settings',
	'edition_menu.media_answer.buttons': 'Buttons',
	'edition_menu.media_answer.styles': 'Styles',
	'edition_menu.media_answer.messages': 'Messages',

	'edition_menu.media_answer.start_button': 'Start Button',
	'edition_menu.media_answer.restart_button': 'restart Button',
	'edition_menu.media_answer.confirm_button': 'Confirm Button',
	'edition_menu.media_answer.text_placeholder': 'Text placeholder',
	'edition_menu.media_answer.modify_response': 'Modify Response',
	'edition_menu.media_answer.continue_button': 'Continue Button',

	// Game
	'edition_menu.game.title': 'Game Editor',
	'edition_menu.game.button.title': 'Spin the wheel',
	'edition_menu.game.button_next.title': 'Go Next',
	'edition_menu.game.field.font_size': 'Text size',

	'edition_menu.game.component.title.wheel': 'Wheel Options',
	'edition_menu.game.component.title.form': 'Contact Form',
	'edition_menu.game.component.title.winning': 'Winning Pop-up',
	'edition_menu.game.component.title.losing': 'Losing Pop-up',

	'edition_menu.game.wheel.select.helper': `Select the item to configure`,
	'edition_menu.game.wheel.button.title': 'Spin the wheel',
	'edition_menu.game.wheel.button.header': 'Buttons',
	'edition_menu.game.wheel.button_next.header': 'Next Button',
	'edition_menu.game.wheel.button_submit.header': 'Submit Button',
	'edition_menu.game.wheel.button_link.header': 'Link Button',
	'edition_menu.game.wheel.field.text_colors': 'Text color',
	'edition_menu.game.wheel.field.background_colors': 'Background color',
	'edition_menu.game.wheel.field.wheel.header': 'Wheel',
	'edition_menu.game.wheel.pointer_color': 'Pointer color',
	'edition_menu.game.wheel.field.wheel_border.size': 'Border size',
	'edition_menu.game.wheel.field.wheel_border.color': 'Border color',
	'edition_menu.game.wheel.field.items.header': 'Options',
	'edition_menu.game.wheel.field.items_border.size': 'Border size',
	'edition_menu.game.wheel.field.items_border.color': 'Border color',
	'edition_menu.game.wheel.field.wheel_pointer.header': 'Wheel Pointer',
	'edition_menu.game.wheel.field.center_circle.header': 'Center',
	'edition_menu.game.wheel.field.center_circle.size': 'Size',
	'edition_menu.game.wheel.field.center_circle.border_size': 'Border size',
	'edition_menu.game.wheel.field.center_circle.color': 'Border color',
	'edition_menu.game.wheel.field.perpendicular_text': 'Perpendicular text',
	'edition_menu.game.wheel.field.button_play': 'Button Play',
	'edition_menu.game.wheel.prizes.title': 'Prizes',
	'edition_menu.game.wheel.prizes.field.chances': 'Chances',
	'edition_menu.game.wheel.prizes.field.prize_label': 'Prize label',
	'edition_menu.game.wheel.prizes.field.code': 'Code (optional)',
	'edition_menu.game.wheel.prizes.field.weight': 'Weight',
	'edition_menu.game.wheel.modal.winning.header': 'Won',
	'edition_menu.game.wheel.modal.winning.description': 'Congratulation! You won {{prize}}',
	'edition_menu.game.wheel.modal.losing.header': 'Lost',
	'edition_menu.game.wheel.modal.losing.description': `Too bad. You'll have better luck next time!`,
	'edition_menu.game.wheel.modal.form.header': 'Thank you for playing!',
	'edition_menu.game.wheel.modal.form.description': `Enter your email to claim your prize.`,
	'edition_menu.game.wheel.modal.form.placeholder': `Enter your email`,
	'edition_menu.game.wheel.modal.form_enable': 'Enable form',
	'edition_menu.game.wheel.modal.form_description':
		'Display a form after spinning the wheel, submit the form to claim the prize',
	'edition_menu.game.wheel.modal.redirection_button': 'Redirection Button',
	'edition_menu.game.wheel.modal.code': 'Display Code',
	'edition_menu.game.wheel.prizes.field.losing_option': 'Losing Pop-up*',
	'edition_menu.game.wheel.add_prizes': 'Add new option',

	'edition_menu.game.wheel.prize_variable': 'Prize Variable',
	'edition_menu.game.wheel.prize_variable_description': `<b>Assign</b> the <b>prize result</b> of the Wheel to a <b>variable</b> to reuse it later in the story (Display, Conditions,...) or for integrations. <br></br><br></br>If the option <b>contains code</b> the prize result will be [option] - [code]. <br></br>Ex. Discount 50% - DISCOUNT50`,
	'edition_menu.game.wheel.email_variable': 'Email Variable',
	'edition_menu.game.wheel.email_variable_description': `<b>Assign email</b> to a <b>variable</b> to reuse it later in the story for Display, Conditions, Auto Reply, Integrations,...`,

	// Results
	'edition_menu.results.display_results': 'Results',
	'edition_menu.results.display_results.tooltip': `Display users results`,
	'edition_menu.results.display_all_results': 'Display all Results',
	'edition_menu.results.display_all_results.tooltip':
		'Display all question results of the story instead of only answered questions',

	// Answers Menu
	'edition_menu.answer.title': 'Button/choice Editor',
	'edition_menu.answer.correct': 'Correct answer',
	'edition_menu.answer.multiple_selection': 'Multiple selections',
	'edition_menu.answer.required_answer': 'Answer required',
	'edition_menu.answer.other_answer': 'Input Field',
	'edition_menu.answer.count_in_results': 'Enable quiz mode (❓)',
	'edition_menu.answer.display_results.quiz': 'Display quiz results (✅ or ❌)',
	'edition_menu.answer.count_response_percentage': 'Display participants in real time',
	'edition_menu.answer.display_choice_letters': 'Display choice letters (A, B, C, ...)',
	'edition_menu.answer.add_button.answer': 'Add a choice/button',
	'edition_menu.answer.add_button.link': '+ link button',
	'edition_menu.answer.add_button.file_uploader': '+ upload button',
	'edition_menu.answer.add_button.file_downloader': '+ download button',
	'edition_menu.answer.text_actions': 'Text and actions',
	'edition_menu.answer.up': 'Up',
	'edition_menu.answer.down': 'Down',

	'edition_menu.modal.nextPage.title': 'Apply the redirection',
	'edition_menu.modal.nextPage.description': 'Apply this redirection to all buttons?',
	'edition_menu.modal.nextPage.to_all': 'Apply to all buttons',
	'edition_menu.modal.nextPage.btn_only': 'This button only',

	'edition_menu.answer.actions.required_answer': 'All Answers required',
	'edition_menu.answer.actions.partial_required_answer': 'Answer required',
	'edition_menu.answer.actions.required_answer.description': 'Users must upload all files to go to the next page.',
	'edition_menu.answer.actions.partial_required_answer.description':
		'Users will not be able to go to the next page without giving at least an answer.',

	'edition_menu.answer.required_answer.description': `Users won’t be able to skip this page without giving an answer.`,
	'edition_menu.answer.multiple_selection.description': `Users will be able to select several choices before validating.`,
	'edition_menu.answer.other_answer.description': `Users will be able to write his answer in an additional field.`,
	'edition_menu.answer.variable.description': `Assign a variable to be able to re-use it (i.e. display in text block) or identify answer easily when exporting data.`,
	'edition_menu.answer_config.button_type.title': 'Button Type',
	'edition_menu.answer_config.button_type.type.text': 'Text',
	'edition_menu.answer_config.button_type.type.image': 'Image',

	'edition_menu.answer_config.header.title': 'Title',
	'edition_menu.answer_config.header.emoticone': 'On Click',
	'edition_menu.answer_config.header.emoticone.tooltip': 'Display emoji when click on button',
	'edition_menu.answer_config.header.emoticone.tooltip_multipleSelection': 'The emoji is set by the Confirm button',
	'edition_menu.answer_config.header.redirect_to': 'Redirect to',
	'edition_menu.answer_config.header.limit_choices': 'Limit choices',
	'edition_menu.answer_config.header.confirm_emoji': 'Confirm button emoji',
	'edition_menu.answer_config.header.type': 'Type',
	'edition_menu.answer_config.header.settings': 'Settings',
	'edition_menu.answer_config.header.multiple_redirect': 'Redirect via multiple selections',

	'edition_menu.answer.upload_file.button': 'Add a file',
	'edition_menu.answer.settings_modal.buttons': 'Buttons Settings',

	'edition_menu.answer.title.error': 'Title cannot be empty',
	'edition_menu.answer.image_url.error': 'Image cannot be empty',
	'edition_menu.answer.web_url.error': 'URL cannot be empty',
	'edition_menu.answer.file_download.error': 'File download cannot be empty',

	'edition_menu.answer.default.title': 'Answer',
	'edition_menu.answer.other_answer.title': 'Other',
	'edition_menu.answer.link.title': 'Link Here',
	'edition_menu.answer.file_uploader.title': 'Upload Here',
	'edition_menu.answer.file_downloader.title': 'Download Here',

	'edition_menu.answer.link.redirection_timer_end.tooltip':
		'When this option is activated, at the end of the timer a new tab opens with the link indicated. <br></br><br></br> ⚠️ Enabled only if there is only one link button.',

	'edition_menu.story_results': 'Results',

	'bottom_toolbar.allow_restart.tooltip': 'Add a restart button',
	'bottom_toolbar.url_button.tooltip': 'Add URL button',
	'bottom_toolbar.url_button.label': 'URL',

	'edition_menu.answer.state.title': 'Choices/buttons State',
	'edition_menu.answer.state.tooltip': `You can customize the appearance of the choices when they are unselected, selected, etc.`,
	'edition_menu.answer.state.default': 'Unselected', // Not used
	'edition_menu.answer.state.selected': 'Selected', // Not used
	'edition_menu.answer.state.correct': 'Correct', // Not used
	'edition_menu.answer.state.incorrect': 'Incorrect', // Not used
	'edition_menu.answer.confirm_button': 'Confirm Button',
	'edition_menu.answer.limit_answers': 'Limit Choices',
	'edition_menu.answer.isHidden': 'Show the button',
	'edition_menu.answer.isVisible': 'Hide the button',
	'edition_menu.answer.partial_correct': 'Allow Partial Answers',
	'edition_menu.answer.url_preview': 'Redirect Directly',
	'edition_menu.answer.url_preview.hotspot':
		'When the user clicks on the button, redirect to the website instead of open in new tab.',
	'edition_menu.answer.redirect_timer_end': 'Redirect at the end of timer',
	'edition_menu.answer.next_button': 'Add a redirection button',
	'edition_menu.answer.rewards_multiple': 'Reward confirm button with emoji',
	'edition_menu.answer.multiple_active': `The <b>multiple selection</b> is <b>activated</b>, after the validation, the user will be redirected to: <b>{page}</b>`,
	'edition_menu.answer.quiz_redirect_start': `On confirmation, if page has answer`,
	'edition_menu.answer.quiz_redirect_start_next': `If`,
	'edition_menu.answer.quiz_redirect_correct': ` correct `,
	'edition_menu.answer.quiz_redirect_incorrect': ` incorrect `,
	'edition_menu.answer.quiz_redirect_partial': ` partially correct `,
	'edition_menu.answer.quiz_redirect_end': `then go to:`,
	'edition_menu.quizz.answers.partial_correct.title': 'Partial Answers',
	'edition_menu.quizz.answers.partial_correct.message': `E.g., if there are 2 correct answers and the user only chooses 1 of them. He will be granted 1 point out of 2, and the answer will be tagged as partially correct.`,

	'edition_menu.answer.praticipants.title': 'Display participants in',
	'edition_menu.answer.praticipants.percentage': 'Percentage',
	'edition_menu.answer.praticipants.number': 'Number',
	'edition_menu.answer.praticipants.number_and_icon': 'Votes (Number and ♥)',

	'edition_menu.quizz.answers.delete_error': `Cannot delete the only Button, add a new one to delete`,

	'edition_menu.quiz_mode.title': 'Quiz Mode',
	'edition_menu.quiz_mode.description': `Story's score will be increased and results will be displayed at the End page.`,
	// Video
	'edition_menu.video.title': 'Video Editor',
	'edition_menu.video.tooltip.capture': 'Record a video of the webcam or the screen',
	'edition_menu.video.tooltip.camera_capture': 'Record a video (Webcam)',
	'edition_menu.video.tooltip.screen_capture': 'Record you Screen',
	'edition_menu.video.record': 'Record',
	'edition_menu.video.camera': 'Webcam record',
	'edition_menu.video.screen': 'Screen record',
	'edition_menu.video.camera.confirm': 'Validate',
	'edition_menu.video.camera.use_another': 'Record again',
	'edition_menu.video.unusable': 'Unusable Video',
	'edition_menu.video.audio_off': 'Microphone off',
	'edition_menu.video.audio_on': 'Microphone on',
	'edition_menu.video.uploading': 'Your video is uploading...',
	'edition_menu.video.message': `Select a video`,
	'edition_menu.video.add_video': 'Add a video',
	'edition_menu.video.change_video': `Click to change video`,
	'edition_menu.video.empty_list': `No videos found`,

	// Subtitle
	'edition_subtitle.noSubtitle': 'This video does not have any subtitles',
	'edition_subtitle.processing': 'Your subtitles are being processed',
	'edition_subtitle.activate': 'Activate',
	'edition_subtitle.invalid': 'Use a video from your gallery to add subtitles',
	'edition_subtitle.add': 'Add new Subtitle',
	'edition_subtitle.edit': 'Modifier les sous-titres',
	'edition_subtitle.tooltip.auto': 'Automatically transcript the video',
	'edition_subtitle.tooltip.manual': 'Manually transcript the video',
	'edition_subtitle.save': 'Save subtitle',
	'edition_subtitle.tooltip.position': 'Vertical position',
	'edition_subtitle.error.message': 'An error occurred with the subtitle process',
	'edition_subtitle.button.try_again': 'Try Again',
	'edition_subtitle.status': 'Subtitles status',
	'edition_subtitle.active': 'Your subtitles are <b>Activated</b>',
	'edition_subtitle.deactive': 'Your subtitles are <b>Deactivated</b>',

	// Text
	'edition_menu.text.title': 'Text Editor',
	'edition_menu.text.font': 'Font',
	'edition_menu.text.halign.title': 'Horiz. Align',
	'edition_menu.text.valign.title': 'Vert. Align',
	'edition_menu.text.style.title': 'Style',
	'edition_menu.text.padding.top': 'Padding Top',
	'edition_menu.text.padding.bottom': 'Padding Bottom',
	'edition_menu.text.padding.right': 'Padding Right',
	'edition_menu.text.padding.left': 'Padding Left',
	'edition_menu.variables.title': 'Insert',
	'edition_menu.variables.insert': 'Insert { count, plural, one { variable } other { variables }}',
	'edition_menu.variables.add': '⚠️ Assign { count, plural, one { variable } other { variables }}',
	'edition_menu.variables.search': 'Search { count, plural, one { variable } other { variables }}',

	'edition_menu.effect.title': 'Text Effect',
	'edition_menu.effect.none': 'None',
	'edition_menu.effect.shadow': 'Shadow',
	'edition_menu.effect.hollow': 'Hollow',
	'edition_menu.effect.neon': 'Neon',
	'edition_menu.effect.echo': 'Echo',
	'edition_menu.effect.otto': 'Otto',
	'edition_menu.effect.relief': 'Relief',
	'edition_menu.effect.printer': 'Printer',
	'edition_menu.effect.glow': 'Glow',
	'edition_menu.effect_calibre.blur': 'Blur',
	'edition_menu.effect_calibre.shadow_color': 'Shadow color',
	'edition_menu.effect_calibre.fill_color': 'Fill color',
	'edition_menu.effect_calibre.thickness': 'Thickness',
	'edition_menu.effect_calibre.stroke_color': 'Stroke color',
	'edition_menu.icon.change': 'Change icon',
	'edition_menu.rating.change': 'Change Rating',
	'edition_menu.buttons.settings': 'Button',

	'edition_menu.font_family.group_brand': 'Brand',
	'edition_menu.font_family.group_custom': 'Imported',
	'edition_menu.font_family.group_default': 'Default',
	'edition_menu.font_family.group_recent': 'Recently Used',
	'edition_menu.font_family.add_new': '+ Add new font',

	'edition_menu.text.tooltip.bold': 'Bold',
	'edition_menu.text.tooltip.italic': 'Italic',
	'edition_menu.text.tooltip.underline': 'Underline',
	'edition_menu.text.tooltip.strikethrough': 'Strikethrough',

	'edition_menu.text.tooltip.halign.center': 'Center Horizontal Align',
	'edition_menu.text.tooltip.halign.left': 'Left Horizontal Align',
	'edition_menu.text.tooltip.halign.right': 'Right Horizontal Align',
	'edition_menu.text.tooltip.halign.flex-start': 'Left Horizontal Align',
	'edition_menu.text.tooltip.halign.flex-end': 'Right Horizontal Align',
	'edition_menu.text.tooltip.valign.flex-start': 'Top Vertical Align',
	'edition_menu.text.tooltip.valign.center': 'Center Vertical Align',
	'edition_menu.text.tooltip.valign.flex-end': 'Bottom Vertical Align',

	'edition_menu.block.position.top': 'Position Top',
	'edition_menu.block.position.left': 'Position Left',

	// Align Block
	'edition_menu.text.tooltip.align.left': 'Left Align',
	'edition_menu.text.tooltip.align.top': 'Top Align',
	'edition_menu.text.tooltip.align.right': 'Right Align',
	'edition_menu.text.tooltip.align.bottom': 'Bottom Align',
	'edition_menu.text.tooltip.align.center': 'Center Align',
	'edition_menu.text.tooltip.align.vertical_center': 'Center Vertical Align',

	// Image
	'edition_menu.image.title': 'Image Editor',
	'edition_menu.image.message': `Select a picture`,
	'edition_menu.pexel_placeholder.videos': 'Search videos in Pexels...',
	'edition_menu.pexel_placeholder.photos': 'Search images in Pexels...',
	'edition_menu.pixabay_placeholder.video': 'Search videos in Pixabay...',
	'edition_menu.pixabay_placeholder.photos': 'Search images in Pixabay...',
	'edition_menu.placeholder.images': 'Search images...',
	'edition_menu.image.add_image': 'Add an image',
	'edition_menu.image.empty_list': `No images found`,

	'edition_menu.media.resize.title': `Reset Size`,
	'edition_menu.media.frame.title': `Mask`,
	'edition_menu.media.current_frame.title': `Current Mask`,
	'edition_menu.media.resize.tooltip': `Resize to original aspect ratio`,

	// Sticker
	'edition_menu.sticker.title': 'Animated Sticker Editor',
	'edition_menu.sticker.message': `Select a sticker`,

	// Calendar
	'edition_menu.calendar.title': 'Calendar Editor',
	'edition_menu.calendar.header': 'Calendar',
	'edition_menu.calendar.works_with': 'Works with',
	'edition_menu.calendar.instructions': 'Add your appointment scheduling link',
	'edition_menu.calendar.input_placeholder': 'e.g. https://meetings.hubspot.com/example',
	'edition_menu.calendar.invalid_source': 'Enter a valid meeting link',
	'edition_menu.calendar.required': 'Action required',

	// Location
	'edition_menu.location.title': 'Location Editor',
	'edition_menu.location.header': 'Location',
	'edition_menu.location.instructions': 'Add your embed link from Google Maps',
	'edition_menu.location.input_placeholder': `e.g. <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10500.899077972286!2d2.2913514898040646!3d48.853924137380666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6701f7e8337b5%3A0xa2cb58dd28914524!2sEiffel%20Tower%2C%20Paris!5e0!3m2!1sen!2sfr!4v1638980136415!5m2!1sen!2sfr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
	'edition_menu.location.invalid_source': 'Enter a valid embed map iframe link',

	// Countdown
	'edition_menu.countdown.editor': 'Countdown Editor',
	'edition_menu.countdown.deadline': 'Deadline',
	'edition_menu.countdown.size': 'Size',
	'edition_menu.countdown.display_title': 'Display Title',
	'edition_menu.countdown.title': 'Title',
	'edition_menu.countdown.display_seconds': 'Display Seconds',
	'edition_menu.countdown.font_family': 'Font family',
	'edition_menu.countdown.header_title': 'Title',
	'edition_menu.countdown.header_clock': 'Clock',
	'edition_menu.countdown.size_small': 'small',
	'edition_menu.countdown.size_medium': 'medium',
	'edition_menu.countdown.size_large': 'large',

	// Iframe
	'edition_menu.iframe.title': 'Iframe Editor',
	'edition_menu.leaderboard.title': 'Leaderboard Editor',
	'edition_menu.iframe.instruction': 'Paste your html iframe to the input below',
	'edition_menu.iframe.invalid_source': 'Please, enter a valid iframe html',

	// Pexel and Gif instructions
	'edition_menu.video_selection_instructions': 'Click on a video to add it',
	'edition_menu.image_selection_instructions': `Click on an image to add it`,
	'edition_menu.emoji_selection_instructions': `Click on an emoji to add it`,
	'edition_menu.gif_selection_instructions': `Click on a GIF to add it`,
	'edition_menu.gif_instructions': `Search or select a category `,

	// Shape
	'edition_menu.shape.title': 'Shape Editor',
	'edition_menu.icon.title': 'Icon Editor',
	'edition_menu.shape.change': 'Change Shape',
	'editor.menu_shape.see_all': 'See all',
	'editor.menu_shape.section.preview': 'Select one',
	'editor.menu_shape.section.placeholder': 'Search a shape',
	'editor.menu_shape.section.classic': 'Classic',
	'editor.menu_shape.section.essential': 'Essential',
	'editor.menu_shape.section.arrows': 'Arrows',
	'editor.menu_shape.section.ribbons': 'Ribbons',
	'editor.menu_shape.section.social_network': 'Social network',
	'editor.menu_shape.section.survey': 'Survey',
	'editor.menu_shape.section.information': 'Information',
	'editor.menu_shape.section.sport': 'Sport',
	'editor.menu_shape.section.education': 'Education',
	'editor.menu_shape.section.emoji': 'Emoji',
	'editor.menu_shape.section.recently_used': 'Recently Used',

	// Animation
	'edition_menu.animation.InformationForPage': '*You can get a preview by hovering the animation',
	'edition_menu.animation.title': 'Animation',
	'edition_menu.animation.name': 'Animation Name',
	'edition_menu.animation.duration': 'Duration (seconds)',
	'edition_menu.animation.duration.tooltip': `<b>Duration</b> refers to the length of time that the animation of the block will take to complete.`,
	'edition_menu.animation.delay': 'Delay (seconds)',
	'edition_menu.animation.delay.tooltip': `<b>Delay</b> refers to the amount of time that elapses before the animation of the block begins once it is displayed.`,
	'edition_menu.animation.display_delay': 'Delay before appearance',
	'edition_menu.animation.display_delay.tooltip': `<b>Delay before appearance</b> refers to the amount of time that elapses before the block becomes visible on the screen.`,
	'edition_menu.animation.forever': 'Infinite Loop',
	'edition_menu.animation.clear': 'Reset',
	'edition_menu.animation.amp_cover_page':
		'Animations have been disabled on cover page of your story for performance reasons by the AMP Team',
	'edition_menu.animation.backgroundZoomIn': 'Zoom In',
	'edition_menu.animation.backgroundZoomOut': 'Zoom Out',

	// Buttons
	'edition_menu.button.title': 'Button Editor',
	'edition_menu.button.settings': 'Buttons settings',
	'edition_menu.button.style': 'Buttons: Appearance',
	'edition_menu.button.border': 'Buttons: Border',
	'edition_menu.confirm_button.style': 'Confirm and Next button: Appearance',
	'edition_menu.confirm_button.border': 'Confirm and Next button: Border',
	'edition_menu.button.image.open': 'Edit image',
	'edition_menu.button.shape.title': 'Buttons: Shape',
	'edition_menu.button.shape.type_none': 'No Border',
	'edition_menu.button.shape.type_contained_square': 'Contained Square',
	'edition_menu.button.shape.type_contained_round': 'Contained Round',
	'edition_menu.button.shape.type_contained_circle': 'Contained Circle',
	'edition_menu.button.shape.type_outlined_square': 'Outlined Square',
	'edition_menu.button.shape.type_outlined_round': 'Outlined Round',
	'edition_menu.button.shape.type_outlined_circle': 'Outlined Circle',
	'edition_menu.button.link.url': 'Valid link',
	'edition_menu.button.link.no_url': 'No link',
	'edition_menu.button.link.file': 'Valid file',
	'edition_menu.button.link.no_file': 'No file',
	'edition_menu.button.link.redirect': 'Redirects to: {next_page} ({label}) ',
	'edition_menu.button.link.redirect.next': 'Next page or by logic',
	'edition_menu.button.link.redirect.error': 'redirect page no longer exists',
	'edition_menu.restart_button.title': 'Restart Button',
	'edition_menu.link_button.title': 'URL Button',
	'edition_menu.background_to_image': 'Background to Image',
	'edition_menu.background_to_video': 'Background to Video',

	// Tag
	'edition_menu.tag.title': 'Tag Editor',
	'edition_menu.tag.tag_header': 'Tag Settings',
	'edition_menu.tag.pulse_header': 'Pulse Effect',
	'edition_menu.tag.titleTag': 'Title',
	'edition_menu.tag.price': 'Price',
	'edition_menu.tag.price_color': 'Price color',
	'edition_menu.tag_placeholder.titleTag': 'Enter your title',
	'edition_menu.tag_placeholder.price': 'Enter your price',
	'edition_menu.tag_placeholder.url': 'Provide a valid URL',

	'edition_menu.tag.add_tag': 'Add Tag',
	'edition_menu.tag.description':
		'Add pulse points to display product name, cost, and link directly to external product pages. Elevate your user experience effortlessly!',

	// File Uploader
	'edition_menu.file_uploader.label': 'Accepted files types',
	'edition_menu.file_uploader.file_type': 'Files types',

	// File Downloader
	'edition_menu.file_downloader.button': 'Upload',
	'editor_menu.file_downloader.tooltip': 'Upload a file you want to share in the story',
	'editor_menu.file_downloader.message': 'File',
	'editor_menu.file_downloader.error.type': 'The file has an invalid format',

	// HoverTools
	'edition_menu.hovertools.animation': 'Play Animation',
	'edition_menu.hovertools.variables': 'Insert Variables',
	'edition_menu.hovertools.duplicate': 'Duplicate',
	'edition_menu.hovertools.delete': 'Delete',
	'edition_menu.hovertools.ratio.lock': 'Lock Dimensions',
	'edition_menu.hovertools.ratio.unlock': 'Unlock dimensions',
	'edition_menu.hovertools.ratio.emoji': 'Add Emoji',

	'edition_menu.block.flip.header': 'Flip',
	'edition_menu.block.flip.vertical': 'Flip Vertically',
	'edition_menu.block.flip.horizontal': 'Flip Horizontally',

	// Interaction
	'edition_menu.interaction.changeType': 'Change Response Type',
	'edition_menu.interaction.delete': 'Delete Response',

	// Rating
	'edition_menu.rating.settings_header': 'Rating Settings',
	'edition_menu.rating.styles': 'Rating: Styles',
	'edition_menu.rating.add_title': 'Enter a title',
	'edition_menu.rating.add_subtitle': 'Enter a subtitle',
	'edition_menu.rating.list': 'Rating list',

	// Advanced Logics
	'edition_menu.advanced_logic.page_header': 'Page Logic Configuration',
	'edition_menu.advanced_logic.header': 'Advanced Logic',
	'edition_menu.advanced_logic.description':
		'If you need to redirect or execute an operation based on a condition, you can set the logic below.',
	'edition_menu.advanced_logic.logic_set': 'Advanced logic set',
	'edition_menu.advanced_logic.logic_unset': 'Advanced logic NOT set',
	'edition_menu.advanced_logic.logic_set.error': 'Advanced logic settings error ',
	'edition_menu.advanced_logic.button.config_logics': 'Setup Advanced Logic',
	'edition_menu.advanced_logic.button.config_variables': 'Create/Delete Variables',

	// Condition on_enter
	'edition_menu.condition.on_enter.header': 'Display condition',
	'edition_menu.condition.on_enter.description':
		'If you need to hide this page, you can set a condition to redirect to another page.',
	'edition_menu.condition.on_enter.if': 'Display page if:',
	'edition_menu.condition.on_enter.enabled': 'Enable',
	'edition_menu.condition.on_enter.otherwise': 'Otherwise',
	'edition_menu.condition.on_enter.button.config': 'Setup Conditon',
	'edition_menu.condition.on_enter.logic_unset': `Display condition NOT set`,

	// Background
	'edition_menu.background.recent_colors': 'Recently Used',
	'edition_menu.gradient.enabled': 'Gradient Background',
	'edition_menu.gradient.add_color': 'Add Gradient Color',
	'edition_menu.gradient.colors_limit': '4 colors maximum',
	'edition_menu.gradient.type_linear': 'Linear',
	'edition_menu.gradient.type_radial': 'Radial',
	'edition_menu.gradient.colors': 'Colors',

	'edition_menu.colors.recently_used': 'Recently Used',
	'edition_menu.none.description': 'Use the dropdown above to select a image, video or color as background',

	// Ending page button
	'edition_menu.ending_page.button.url': 'Link',
	'edition_menu.ending_page.button.download': 'Download',

	// Carousel
	'edition_menu.carousel.title': 'Carousel Editor',
	'edition_menu.carousel.tooltip.carousel': 'Carousel',
	'edition_menu.carousel.tooltip.styles': 'Styles',

	'edition_menu.carousel.carousel_settings': 'Carousel Settings',
	'edition_menu.carousel.carousel_settings.item_type': 'Item Type',
	'edition_menu.carousel.carousel_settings.title': 'Title',
	'edition_menu.carousel.carousel_settings.navigation_buttons': 'Navigation buttons',

	'edition_menu.carousel.carousel_settings.item_type.card': 'Card (Rectangle & Square)',
	'edition_menu.carousel.carousel_settings.item_type.detailed': 'Detailed  (Rectangle & Square)',
	'edition_menu.carousel.carousel_settings.item_type.rounded': 'Rounded',
	'edition_menu.carousel.carousel_settings.item_type.product': 'Product',

	'edition_menu.carousel.item_styles.image_shape.rectangle': 'Rectangle',
	'edition_menu.carousel.item_styles.image_shape.square': 'Square',

	'edition_menu.carousel.carousel_items': 'Carousel Cards',
	'edition_menu.carousel.carousel_item.image': 'Image',
	'edition_menu.carousel.carousel_item.title': 'Title',
	'edition_menu.carousel.carousel_item.click_action': 'Click action',
	'edition_menu.carousel.carousel_item.add_btn': 'Add a new card',
	'edition_menu.carousel.title_size': 'Title Size',

	'edition_menu.carousel.carousel_styles': 'Carousel Styles',

	'edition_menu.carousel.item_styles': 'Card Styles',
	'edition_menu.carousel.item_styles.image_size': 'Image Size',
	'edition_menu.carousel.item_styles.image_shape': 'Image Shape',
	'edition_menu.carousel.item_styles.overlay_color': 'Overlay Color',
	'edition_menu.carousel.item_styles.hasPlayBtn': 'Display play button',
	'edition_menu.carousel.item_styles.boxShadow': 'Box Shadow',
	'edition_menu.carousel.item_styles.shadowColor': 'Shadow Color',

	'editor_menu.carousel.carousel_styles.border': 'Border',
	'editor_menu.carousel.carousel_styles.borderWidth': 'Border Width',
	'editor_menu.carousel.carousel_styles.borderRadius': 'Border Radius',
	'editor_menu.carousel.carousel_styles.borderColor': 'Border Color',
	'editor_menu.carousel.item_styles.item_text': 'Card Text',
	'editor_menu.carousel.item_styles.item_border': 'Card Border',
	'editor_menu.carousel.item_styles.item_shadow': 'Card Shadow',
	'editor_menu.carousel.item_styles.inner_border': 'Inner Border',
	'editor_menu.carousel.item_styles.button_styles': 'Button Styles',

	'edition_menu.carousel_drawer.image': 'Image',
	'edition_menu.carousel_drawer.title': 'Title',
	'edition_menu.carousel_drawer.subtitle': 'Description and Price',
	'edition_menu.carousel_drawer.btn_title': 'Button title',
	'edition_menu.carousel_drawer.click_action': 'Click action',
	'edition_menu.carousel_drawer.link': 'Link',
	'edition_menu.carousel_drawer.link_error': 'Please enter a valid https URL',
	'edition_menu.carousel_drawer.goto': 'Go to Page',

	'edition_menu.carousel_drawer.get_product': 'Get product data from URL',
	'edition_menu.carousel_drawer.get_product_placeholder': 'Enter product URL',
	'edition_menu.carousel_drawer.get_product_btn': 'Get',

	// Button List
	'edition_menu.buttonList.title': 'Button List Editor',
	'edition_menu.buttonList.tooltip.settings': 'Button List',
	'edition_menu.buttonList.tooltip.styles': 'Styles',

	'edition_menu.buttonList.buttons': 'Buttons',
	'edition_menu.buttonList.appearance': 'Appearance',
	'edition_menu.buttonList.border': 'Border',
	'edition_menu.buttonList.add_button': 'Add new button',

	'edition_menu.buttonList.image': 'Image',
	'edition_menu.buttonList.icon': 'Icon',
	'edition_menu.buttonList.add_icon': 'Choose an icon',
	'edition_menu.buttonList.change_icon': 'Change current icon',
	'edition_menu.buttonList.get_favicon': 'Get image from URL',

	// Share
	'edition_menu.share.Facebook': 'Facebook',
	'edition_menu.share.Twitter': 'Twitter',
	'edition_menu.share.Linkedin': 'LinkedIn',
	'edition_menu.share.Pinterest': 'Pinterest',
	'edition_menu.share.Whatsapp': 'WhatsApp',
	'edition_menu.share.Email': 'Email',
	'edition_menu.share.SMS': 'SMS',
	'edition_menu.share.Messenger': 'Messenger',
	'edition_menu.share.Telegram': 'Telegram',
	'edition_menu.share.Reddit': 'Reddit',
	'edition_menu.share.Tumblr': 'Tumblr',
	'edition_menu.share.VK': 'VK',
	'edition_menu.share.copyLinkToClipboard': 'Copy link to clipboard',

	// Shopify
	'edition_menu.shopify.no_connection': 'You have not connected any Shopify store',
	'edition_menu.shopify.connect': 'Connect your Shopify store',
	'edition_menu.shopify.connecting': 'Connecting...',
	'edition_menu.shopify.add_new': 'Add new Shopify store',

	'edition_menu.shopify.asset_loading': 'Loading',
	'edition_menu.shopify.asset_error': 'Error has occurred',
	'edition_menu.shopify.asset_no_products': 'No products found',
	'edition_menu.shopify.asset_add_one': 'Choose a product',
	'edition_menu.shopify.asset_search': 'Search products by title',

	'edition_menu.shopify.product_out_of_stock': 'Out of stock',
	'edition_menu.shopify.product_added': 'Product added',
	'edition_menu.shopify.product_default_btn_title': 'View product',
	'edition_menu.shopify.go_to_shop': 'Go to product page',

	'edition_menu.shopify.title': 'Shopify Editor',
	'edition_menu.shopify.tooltip.shopify': 'Shopify',
	'edition_menu.shopify.tooltip.styles': 'Styles',
	'edition_menu.shopify.tooltip.product': 'Product',
	'edition_menu.shopify.menu_settings': 'Shopify Settings',

	'edition_menu.shopify.menu_shape': 'Shape',
	'edition_menu.shopify.menu_price': 'Price',
	'edition_menu.shopify.menu_description': 'Description',
	'edition_menu.shopify.button_title': 'Button Title',
	'edition_menu.shopify.button_icon': 'Button Icon',
	'edition_menu.shopify.products': 'Shopify Products',
	'edition_menu.shopify.add_product': 'Add a new product',
}
