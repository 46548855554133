export const fr = {
	// ----------------- Error messages ------------------

	// Page config
	'error_messages.page.next': "<b>Page:</b> La page de redirection n'est pas valide",
	'error_messages.page.on_enter.redirect': `<b>Page:</b> La redirection Sinon de la Condition d'affichage n'est pas valide`,

	'error_messages.page.logics.consequence_redirect': `<b>Page:</b> La page de redirection de la Logique Avancée n'est pas valide`,
	'error_messages.page.logics.conditions_field': `<b>Page:</b> Une champs dans condition de la Logique Avancée n'est pas valide`,
	'error_messages.page.logics.conditions_value': `<b>Page:</b> Une valeur dans condition de la Logique Avancée n'est pas valide`,

	'error_messages.page.redirection_button': "<b>Bouton de redirection:</b> Aucune URL n'a été renseignée",

	// Background
	'error_messages.background.video': "<b>Arrière-plan:</b> Aucune vidéo n'a été sélectionnée",
	'error_messages.background.image': "<b>Arrière-plan:</b> Aucune image n'a été séléctionnée",

	// Blocks
	'error_messages.block.video': "<b>Vidéo:</b> Aucun fichier n'a été sélectionné",
	'error_messages.block.image': "<b>Image:</b> Aucun fichier n'a été sélectionné",
	'error_messages.block.sticker': "<b>Sticker:</b> Aucun fichier n'a été sélectionné",
	'error_messages.block.calendar': "<b>Calendrier:</b> Aucune URL n'a été renseignée",
	'error_messages.block.social_network': "<b>Réseaux sociaux:</b> Aucune URL n'a été renseignée",
	'error_messages.block.location': `<b>Localisation:</b> aucune URL n'a été fournie`,
	'error_messages.block.iframe': `<b>Iframe:</b> aucune HTML iframe n'a été fournie`,
	'error_messages.block.share': "<b>Partage:</b> Aucune URL n'a été renseignée",
	'error_messages.block.text': "<b>Texte:</b> Aucune valeur à l'intérieur (supprimez-le ou remplissez-le)",
	'error_messages.block.video_size': '<b>Vidéo :</b> Il est recommandé d’utiliser la vidéo comme arrière-plan',
	'error_messages.block.image_size': '<b>Image :</b> Il est recommandé d’utiliser l’image comme arrière-plan',

	'error_messages.block.messenger.number': '<b>Messenger:</b> Pas de numéro dans le champ',
	'error_messages.block.messenger.user_id': `<b>Messenger:</b> Aucun nom d'utilisateur/ID dans le champ`,
	'error_messages.block.messenger.link': `<b>Messenger:</b> Aucune URL n\'a été renseignée`,
	'error_messages.block.messenger.mail': "<b>Messenger:</b> Aucun Mail n'a été renseignée",
	'error_messages.block.messenger.valid_number': 'Entrez un numéro de telephone valide',
	'error_messages.block.messenger.valid_mail': 'Entrez un email valide',

	'error_messages.block.networks.number': '<b>Réseaux :</b> Veuillez fournir un numéro de téléphone valide',
	'error_messages.block.networks.user_id': '<b>Réseaux :</b> Veuillez fournir un nom d’utilisateur/ID valide',
	'error_messages.block.networks.link': '<b>Réseaux :</b> Veuillez fournir une URL valide',
	'error_messages.block.networks.mail': '<b>Réseaux :</b> Veuillez fournir un email valide',

	'error_messages.block.cta.toolip': `<b>CTA:</b> Ajoutez un message à l'info-bulle`,
	'error_messages.block.cta.url.not_valid': `<b>CTA:</b> Merci de renseigner une URL https`,
	'error_messages.block.cta.url.unsecure': `<b>CTA:</b> Merci de renseigner une URL https valide`,
	'error_messages.block.cta.mailto': `<b>CTA:</b> Merci de renseigner un e-mail https valide`,
	'error_messages.block.cta.attachment': `<b>CTA:</b> Merci d'ajouter une pop-up vers le bloc`,
	'error_messages.block.cta.copyToClipboard': `<b>CTA:</b> Merci de renseigner un texte à copier `,
	'error_messages.block.cta.download': `<b>CTA:</b> Merci de téléverser un fichier`,
	'error_messages.block.cta.internal.nonexistent': `<b>CTA:</b> Merci de renseigner une page qui existe (non supprimée)`,

	'error_messages.page.first_page_disabled': `<b>Page:</b> La première page ne peut pas être désactivée`,

	'error_messages.block.api_template.name': `<b>API Template:</b> Les éléments modifiables sur une même page ne peuvent pas avoir le même nom`,

	// Tags
	'error_messages.tag.title': "<b>Tag:</b> Aucun titre n'a été renseigné",
	'error_messages.tag.url': "<b>Tag:</b> Aucuns URL n'a été renseignée",

	// Answer Buttons
	'error_messages.button.redirection': "<b>Réponse:</b> La page de redirection n'est pas valide",
	'error_messages.button.link': "<b>Bouton link:</b> Aucune URL n'a été renseignée",
	'error_messages.button.file_downloader': "<b>Bouton:</b> Button file downloaderAucun fichier n'a été renseigné",

	'error_messages.button.quiz.correct_redirect': `<b>Réponse:</b> La page de redirection, lorsqu'elle est <b>correcte</b>, n'est pas valid`,
	'error_messages.button.quiz.incorrect_redirect': `<b>Réponse:</b> La page de redirection, lorsqu'elle est <b>incorrecte</b>, n'est pas valid`,
	'error_messages.button.quiz.partial_redirect': `<b>Réponse:</b> La page de redirection, lorsqu'elle est <b>partiellement correcte</b>, n'est pas valid`,

	// Form
	'error_messages.gdpr_consent.text':
		"<b>Formulaire:</b> Le text de consentement RGPD n'est pas totalement customisé. Il reste des variables à retirer.",

	'error_messages.form.next_button': `<b>Formulaire:</b> La page de redirection suivante du formulaire n'est pas valide`,
	'error_messages.form.email_domain': `<b>Formulaire:</b> Domaine par défaut non valide dans le champ E-mail`,

	// Game
	'error_messages.game.wheel.url_empty': "<b>Jeu:</b> Aucune URL n'a été renseignée",

	// AMP Outlink buttons
	'error_messages.amp_attachments.outlink.link': `<b>Lien:</b> Une URL non valide a été fournie`,
	'error_messages.amp_attachments.outlink.title': `<b>Lien:</b> Le titre du bouton de lien ne doit pas être vide`,

	// AMP Interactions
	'error_messages.amp_interactions.title': `<b>Interaction:</b> Le titre de l'option ne peut pas être vide`,
	'error_messages.amp_interactions.image': `<b>Interaction:</b> L'image de l'option ne peut pas être vide`,
	'error_messages.amp_interactions.image_title': `<b>Interaction:</b> Le titre alternatif de l'option ne peut pas être vide`,

	// --------------------- Recommendation messages ----------------------
	'error_messages.page.name': '<b>Page:</b> Changer le nom de la page pour optimiser la collecte de données',
	'error_messages.page.background': `<b>Arrière-plan:</b> L'arrière-plan peut être modifié en double-cliquant sur Story ou en cliquant sur l'arrière-plan dans la barre de widgets`,
	'error_messages.page.answers_count':
		'<b>Buttons:</b> Le nombre de boutons sur la page doit être limité pour une expérience utilisateur optimale',
	'error_messages.page.fields_count':
		'<b>Formulaire:</b> Le nombre de champs dans une formulaire doit être limité pour une expérience utilisateur optimale',
	'error_messages.page.text_count': '<b>Page:</b> Trop de texte sur une page',

	// Widget Editor
	'error_messages.widget_editor.errors_count': `{count} { count, plural, one { erreur } other { erreurs }}`,
	'error_messages.widget_editor.intructions': `Veuillez résoudre { count, plural, one { l'erreur } other { les erreurs }} ci-dessous`,
	'error_messages.widget_editor.placement': 'Placement incorrect.',
	'error_messages.widget_editor.appearance': `Paramètres d'apparence invalides.`,
	'error_messages.widget_editor.list':
		'Problème de liste blanche/liste noire. Revoyez les domaines autorisés/bloqués.',

	// Page Disabled
	'edition_menu.page.disabled.title': 'Visibilité de la page',
	'edition_menu.page.disabled.desctiption':
		'Désactivez la page, tout en préservant ses données et son historique. cliquez pour changer de statut. Impossible de désactiver la première page',
	'edition_menu.page.disabled.status_visible': 'Statut : <b>Visible</b>',
	'edition_menu.page.disabled.status_disabled': 'Statut : <b>Désactivé</b>',
	'edition_menu.page.disabled.icon': 'Page désactivée',

	// Carousel
	'error_messages.carousel.api_template.name':
		'<b>API Template:</b> Les éléments modifiables dans une même page ne peuvent pas avoir le même nom',
	'error_messages.carousel.image': "<b>Carousel:</b> Aucune image n'a été sélectionnée",
	'error_messages.carousel.external_link.url.not_valid': '<b>Carousel:</b> Veuillez fournir une URL valide',
	'error_messages.carousel.external_link.url.unsecure': '<b>Carousel:</b> Veuillez fournir une URL HTTPS',
	'error_messages.carousel.internal.nonexistent': '<b>Carousel:</b> Veuillez fournir une page existante',
	'error_messages.carousel.attachment': '<b>Carousel:</b> Veuillez fournir une pièce jointe',
	'error_messages.carousel.download': '<b>Carousel:</b> Veuillez fournir un fichier à télécharger',

	// ButtonList
	'error_messages.buttonList.api_template.name':
		"<b>Modèle d'API :</b> Les éléments modifiables sur la même page ne peuvent pas avoir le même nom",
	'error_messages.buttonList.external_link.url.not_valid':
		'<b>Liste de boutons :</b> Veuillez fournir une URL valide',
	'error_messages.buttonList.external_link.url.unsecure': '<b>Liste de boutons :</b> Veuillez fournir une URL HTTPS',
	'error_messages.buttonList.mailto.not_valid': '<b>Liste de boutons :</b> Veuillez fournir un e-mail valide',
	'error_messages.buttonList.internal.nonexistent': '<b>Liste de boutons :</b> Veuillez fournir une page existante',
	'error_messages.buttonList.attachment': '<b>Liste de boutons :</b> Veuillez fournir une pièce jointe',
	'error_messages.buttonList.download': '<b>Liste de boutons :</b> Veuillez fournir un fichier à télécharger',
	'error_messages.buttonList.phoneNumber': '<b>Liste de boutons :</b> Veuillez fournir un numéro de téléphone valide',
}
