import { FormattedMessage } from 'react-intl'
import { snackeet_blue, snackeet_light_blue, snackeet_light_yellow, snackeet_red, snackeet_teal } from 'styles/colors'

/* eslint-disable react/display-name */
export * from './BannerConstants'
// Translation Text
export * from './AmpInteractionsList'
export * from './BlockBorderPreset'
export * from './ButtonPreset'
export * from './CalendarBrands'
export * from './ConditionsHelper'
export * from './Elements'
export * from './FontFamiliesList'
export * from './GdprConsentTranslation'
export * from './GradientPreset'
export * from './IntegrationPreset'
export * from './IntegrationsList'
export * from './MediaSelectorList'
export * from './MessengerPlatforms'
export * from './PageList'
export * from './RatingList'
export * from './TextEffectList'
export * from './TextTemplates'
export * from './UseWhatFor'
export * from './VideoHostings'

export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const SHORT_SNACKEET_URL = process.env.NEXT_PUBLIC_SHORT_SNACKEET_URL
export const DEFAULT_BUCKET = process.env.NEXT_PUBLIC_DEFAULT_BUCKET
export const PREVIEW_LEADERBOARD_URL = process.env.NEXT_PUBLIC_LEADERBOARD_PREVIEW_URL

export const SITE_NAME = 'Snackeet App'
export const SITE_TITLE = 'Snackeet App'
export const SITE_DESCRIPTION = 'Snackeet - Create delicious snackable content'
export const SITE_IMAGE = '/static/logo/logo-horizontal-black.svg'
export const SITE_AUTHOR = 'Snackeet'
export const SMALL_LOGO_URL = 'https://static.snackeet.com/snackeet/static/logo/logo.svg'
export const DEFAULT_ORGANIZATION_LOGO =
	'https://static.snackeet.com/snackeet/static/editor_assets/default_organization_logo.png'
export const DEFAULT_WORKSPACE_AVATAR = 'https://static.snackeet.com/snackeet/static/logo/logo.svg'
export const SUPPORT_EMAIL = 'hello@snackeet.com'

export const DEFAULT_APP_DELAY = 800
export const AUTO_SAVE_DEBOUNCE_TIME = 2000
export const DEFAULT_APP_LOCALE = 'en'
export const APP_LOCALES = ['fr', 'en']
export const LOCALE_OPTIONS = [
	{
		key: 'fr',
		text: '🇫🇷',
		value: 'fr',
	},
	{
		key: 'en',
		text: '🇺🇸',
		value: 'en',
	},
]

export const APP_HEADER_HEIGHT = 52
export const SIDEBAR_WIDTH = 90
export const EDITOR_PROPERTIES_WIDTH = 350
export const EDITOR_LEFT_PANEL_WIDTH = 160
export const PREVIEW_HEIGHT = 630
export const PREVIEW_WIDTH = 359
export const SUBTITLES_DISPLAY_DELAY_MS = 400
export const EDITOR_DRAWER_WIDTH = 430
export const SLECTOR_DRAWER_WIDTH = 720

export const TRADEMARK_FOOTER_URL = 'https://www.snackeet.com'
export const TRADEMARK_FOOTER_MESSAGE = 'Powered by'
export const TRADEMARK_FOOTER_IMAGE = 'https://storage.googleapis.com/snackeet/static/logo/logo-horizontal-white.svg'
export const TRADEMARK_FOOTER_HEIGHT = 30

export const DEFAULT_FAVICON_URL = 'https://static.snackeet.com/snackeet/static/favicon.ico'
export const FACEBOOK_GRAPH_API_URL = 'https://graph.facebook.com/v18.0'

// Wheel of Fortune
export const WHEEL_POINTER_TRANSPARENT = 'https://static.snackeet.com/snackeet_assets/wheel/transparent_png.png'
export const WHEEL_POINTER_SHAPE = 'https://static.snackeet.com/snackeet_assets/wheel/wheel_pin.png'

export const formattedMessageDefaultValues = {
	br: () => <br />,
	p: (chunks) => <p>{chunks}</p>,
	b: (chunks) => <b>{chunks}</b>,
	i: (chunks) => <i>{chunks}</i>,
	u: (chunks) => <u>{chunks}</u>,
	div: (chunks) => <div>{chunks}</div>,
	ul: (chunks) => <ul>{chunks}</ul>,
	li: (chunks) => <li>{chunks}</li>,
}

export const STORIES_COLORS = {
	classic: snackeet_blue,
	single_page: snackeet_blue,
	amp: '#C16CF1',
}

export const DEFAULT_COLORS = [
	'#FF3131',
	'#FF5757',
	'#FF66C4',
	'#CB6CE6',
	'#8C52FF',
	'#5E17EB',
	'#0097B2',
	'#0CC0DF',
	'#5CE1E6',
	'#38B6FF',
	'#5271FF',
	'#004AAD',
	'#00BF63',
	'#7ED957',
	'#C1FF72',
	'#FFDE59',
	'#FFBD59',
	'#FF914D',
	'#000000',
	'#545454',
	'#737373',
	'#A6A6A6',
	'#D9D9D9',
	'#FFFFFF',
]

export const VARIABLES_TYPES_COLORS = {
	text: snackeet_red,
	number: snackeet_teal,
	date: snackeet_light_blue,
	boolean: snackeet_light_yellow,
}

export const LEADERBOARD_VARIABLES = ['_username', '_level']

export const TRANSPARENT_IMG = '/static/images/transparent.jpg'

export const AUTO_TRANSCRIPTION_LANGUAGE = [
	{ code: 'fr-FR', flag: '🇫🇷' },
	{ code: 'en-US', flag: '🇺🇸' },
	{ code: 'en-GB', flag: '🇬🇧' },
	{ code: 'de-DE', flag: '🇩🇪' },
]

export const DEFAULT_VOLUME = 0.8

export const WIDGET_PLATFORMS = ['desktop', 'mobile']
export const PLATFORMS = ['all', 'desktop', 'mobile', 'tablet', 'other']

export const INTERACTION_TYPES = [
	'form',
	'rating',
	'media_answer',
	'answers',
	'game',
	'story_results',
	'system_buttons',
	'interaction_layer',
]

export const WIDGET_PREVIEW_SIZES = {
	desktop: {
		scale: 0.4,
		fullWidth: 750,
		fullHeight: 426,
	},
	mobile: {
		scale: 0.21,
		fullWidth: 375,
		fullHeight: 796,
	},
}

export const WIDGET_PREVIEW_SIZES_BIG = {
	desktop: {
		scale: 0.7,
		fullWidth: 750,
		fullHeight: 426,
	},
	mobile: {
		scale: 0.375,
		fullWidth: 375,
		fullHeight: 796,
	},
}

export const ANIMATIONS_LIST = [
	'',
	'bounce',
	'swing',
	'fadeIn',
	'fadeInDown',
	'fadeInLeft',
	'fadeInRight',
	'fadeInUp',
	'flipInX',
	'zoomIn',
	'slideInDown',
	'slideInUp',
	'slideInRight',
	'slideInLeft',
]

export const AMP_ANIMATIONS_LIST = [
	'',
	'fadeIn',
	'fadeInDown',
	'fadeInLeft',
	'fadeInRight',
	'fadeInUp',
	'slideInDown',
	'slideInUp',
	'slideInRight',
	'slideInLeft',
]

export const AMP_BACKGROUND_ANIMATIONS_LIST = ['', 'backgroundZoomIn', 'backgroundZoomOut']

export const ANSWERS_STATES = ['default', 'selected', 'correct', 'incorrect']

export const DEFAULT_TIMER_VALUE = 10
export const MAX_TIMER_VALUE = 300
export const MIN_TIMER_VALUE = 0

export const MAX_ZOOM_PERCENTAGE = 500
export const MIN_ZOOM_PERCENTAGE = 10

export const ACTION_BUTTON_OPTIONS = [
	{
		text: <FormattedMessage id='edition_menu.navigation.button_type.link' />,
		value: 'link',
	},
	{
		text: <FormattedMessage id='edition_menu.navigation.button_type.file_uploader' />,
		value: 'file_uploader',
	},
	{
		text: <FormattedMessage id='edition_menu.navigation.button_type.file_downloader' />,
		value: 'file_downloader',
	},
]

export const VARIABLE_TYPE_OPTIONS = [
	{
		text: <FormattedMessage id='edition_menu.form.field_type.text' />,
		value: 'text',
	},
	{
		text: <FormattedMessage id='edition_menu.form.field_type.number' />,
		value: 'number',
	},
	{
		text: <FormattedMessage id='edition_menu.form.field_type.date' />,
		value: 'date',
	},
	{
		text: <FormattedMessage id='edition_menu.form.field_type.boolean' />,
		value: 'boolean',
	},
]

export const WIDGET_POSITION_OPTIONS = [
	{
		text: <FormattedMessage id='widget_editor.placement.position.before' />,
		value: 'before',
	},
	{
		text: <FormattedMessage id='widget_editor.placement.position.inside' />,
		value: 'inside',
	},
	{
		text: <FormattedMessage id='widget_editor.placement.position.bottom' />,
		value: 'bottom',
	},
	{
		text: <FormattedMessage id='widget_editor.placement.position.after' />,
		value: 'after',
	},
	{
		text: <FormattedMessage id='widget_editor.placement.position.replace' />,
		value: 'replace',
	},
]

export const FILE_TYPE_LIST = [
	{
		text: 'PDF',
		value: 'pdf',
	},
	{
		text: 'Image',
		value: 'image',
	},
	{
		text: 'Video',
		value: 'video',
	},
	{
		text: 'Audio',
		value: 'audio',
	},
	{
		text: 'Words',
		value: 'words',
	},
	{
		text: 'PowerPoint',
		value: 'powerpoint',
	},
	{
		text: 'CSV',
		value: 'csv',
	},
	{
		text: 'Excel',
		value: 'excel',
	},
	{
		text: 'Pages',
		value: 'pages',
	},
]

export const TAGS_TEMPLATE = [
	'education_faq',
	'interactive_game',
	'engagement_review',
	'marketing_lead',
	'product_showcase',
	'corporate_job',
	'engagement',
	'lead_generation',
	'feedback',
	'event',
	'e_commerce',
	'education',
	'recruiting',
	'game',
	'promotion',
	'product_and_unboxing',
	'life_style_fashion',
	'media_news',
	'giveaways_and_raffles',
	'surveys',
	'quizs',
	'sport',
	'news_and_entertainment',
	'reviews',
	'promotion_sales',
	'other',
]

export const NEW_TAGS = [
	// Landing Page
	'bio_link',
	'micro_site',

	// Display
	'howTo_unboxing',
	'produit_routines',
	'benefits',
	'review',
	'faq',

	// Acquisition
	'ask_for_review',
	'communication_article',
	'job_offers',

	// Engagement
	'games_quiz',
	'vote_survey',
]

export const filesPerPageOptions = [
	{
		text: 10,
		value: 10,
	},
	{
		text: 25,
		value: 25,
	},
	{
		text: 50,
		value: 50,
	},
]

export const ROLES_OPTIONS = ['admin', 'agent'].map((value) => ({
	text: <FormattedMessage id={`organization.role.${value}`} />,
	value,
	key: value,
}))

export const DEFAULT_REWARDS_CONFIG = {
	elementCount: 10,
	elementSize: 45,
	lifetime: 80,
	spread: 30,
	startVelocity: 45,
	decay: 0.9,
}

export const videoPlayerConfig = {
	youtube: {
		playerVars: {
			rel: 0,
		},
	},
}

export const SHAPE_HAS_BORDER_RADIUS = ['classic-square']

export const quizTextStatusSvg = {
	correct: `'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="%235BBA74"><path fill="none" d="M0 0h24v24H0z"/><path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>'`,
	incorrect: `'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="%23F34E4E"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>'`,
}

export const quizImageStatusSvg = {
	correct: `'data:image/svg+xml;charset=utf-8,<svg width="12" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M3.584 7.497 1.362 5.276a.641.641 0 1 0-.907.907l3.129 3.129 7.241-7.242a.64.64 0 0 0-.904-.904L3.584 7.497z" fill="%23fff"/></svg>'`,
	incorrect: `'data:image/svg+xml;charset=utf-8,<svg width="12" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M11.396 1.813A.854.854 0 1 0 10.187.604L6 4.791 1.813.604A.855.855 0 0 0 .604 1.813L4.791 6 .604 10.187a.855.855 0 0 0 1.209 1.209L6 7.209l4.187 4.187a.855.855 0 1 0 1.209-1.209L7.209 6l4.187-4.187z" fill="%23fff"/></svg>'`,
}

export const SNACKEET_STORY_FEATURES = [
	{
		name: 'interactive_video',
		color: '#004AAD',
	},
	{
		name: 'bio_link',
		color: '#00BF63',
	},
	{
		name: 'contests',
		color: '#FFDE59',
	},
	{
		name: 'quiz_survey',
		color: '#FF914D',
	},
	{
		name: 'acquisition_form',
		color: '#5E17EB',
	},
	{
		name: 'review_collection',
		color: '',
	},
]

export const AMP_STORY_FEATURES = [
	{
		name: 'video_display',
		color: '',
	},
	{
		name: 'product_demo',
		color: '',
	},
	{
		name: 'articles',
		color: '',
	},
	{
		name: 'display_content',
		color: '',
	},
	{
		name: 'simple_engagement',
		color: '',
	},
]

export const FORBIDDEN_SLUGS = ['404', 'sitemap.xml']
