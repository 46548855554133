export const fr = {
	'edition_menu.select_element': `Veuillez sélectionner un élément pour l'éditer`,

	'edition_menu.timer.checkbox_label_display': 'Visible',
	'edition_menu.timer.checkbox_label_active': 'Actif',
	'edition_menu.timer.title': 'Édition du timer',

	'edition_menu.navigation.next_page': 'Page suivante ou par logique',
	'edition_menu.navigation.none': 'AUCUNE REDIRECTION',
	'edition_menu.navigation.go_back': 'Retour page précédente',
	'edition_menu.navigation.goto': 'Aller à',
	'edition_menu.navigation.your_pages': 'Vos pages',
	'edition_menu.navigation.goto_on_confirm': 'Après confirmation, rediriger vers',
	'edition_menu.navigation.button_type.text': 'Réponse Texte',
	'edition_menu.navigation.button_type.link': 'Lien',
	'edition_menu.navigation.button_type.file_uploader': 'Importer',
	'edition_menu.navigation.button_type.file_downloader': 'Télécharger',
	'edition_menu.navigation.button_type.image': 'Réponse Image',
	'edition_menu.navigation.button_correct': 'Bonne Réponse',
	'edition_menu.navigation.button_wrong': 'Mauvaise Réponse',

	'edition_menu.proportion.title': 'Déplacer votre {media}',
	'edition_menu.proportion.none': 'Original',
	'edition_menu.proportion.contain': 'Contenir',
	'edition_menu.proportion.cover': 'Couvrir',

	'edition_menu.settings.position': 'Position',
	'edition_menu.settings.size': 'Taille',
	'edition_menu.settings.reframe': 'Recadrer',
	'edition_menu.settings.alignment': 'Alignement',
	'edition_menu.settings.align_to_page': 'Aligner selon page',
	'edition_menu.settings.align_elements': 'Aligner éléments',
	'edition_menu.slider.rotation': 'Rotation',
	'edition_menu.slider.skewX': 'Inclinaison horizontale',
	'edition_menu.slider.skewY': 'Inclinaison verticale',
	'edition_menu.slider.blur': 'Flou',
	'edition_menu.slider.opacity': 'Transp.',
	'edition_menu.slider.borderRadius': 'Arrondis',
	'edition_menu.slider.borderWidth': 'Largeur bordure',
	'edition_menu.slider.border': 'Bordure',
	'edition_menu.slider.iconSize': 'Taille',
	'edition_menu.icon.title': 'Icône',

	'edition_menu.slider.width': 'Largeur',
	'edition_menu.slider.height': 'Hauteur',
	'edition_menu.slider.position.horizontal': 'Horizontale',
	'edition_menu.slider.position.vertical': 'Verticale',

	'edition_menu.start_btn.rewards': 'Ajouter emoji au clic',

	'edition_menu.rounded_button.title': 'Bouton arrondi',
	'edition_menu.rounded_button.size': 'Taille',
	'edition_menu.rounded_button.fontSize': 'Taille de police',

	'edition_menu.block_default.value': 'Ajoutez du texte ici...',

	// Properties tabs
	'edition_menu.tabs.select_file': 'Choisir un fichier',
	'edition_menu.tabs.properties': 'Paramètres',
	'edition_menu.tabs.animation': 'Animation',
	'edition_menu.tabs.cta': `Appel à l'action`,
	'edition_menu.tabs.text_settings': 'Style du Texte',
	'edition_menu.tabs.location': 'Localisation',
	'edition_menu.tabs.countdown': 'Compte à rebours',
	'edition_menu.tabs.style': 'Style',
	'edition_menu.tabs.share': 'Partage',
	'edition_menu.tabs.social_network': 'Réseaux sociaux',
	'edition_menu.tabs.display_delay': `Délai d'appartition`,

	// Video/Image tabs
	'edition_menu.tabs.gallery': 'Imports',
	'edition_menu.tabs.pexels': 'Pexels',
	'edition_menu.tabs.stickers': 'Sticker',
	'edition_menu.tabs.emojis': 'Emoji',
	'edition_menu.tabs.gif': 'GIF',
	'edition_menu.tabs.link': 'Lien',

	// Block Accordion
	'edition_menu.accordion.block_position': 'Position',
	'edition_menu.accordion.block_container': 'Bloc',
	'edition_menu.accordion.block_border': 'Bordure',
	'edition_menu.accordion.text_styles': 'Texte',
	'edition_menu.accordion.shape_styles': 'Shape',
	'edition_menu.accordion.icon_styles': 'Icône',
	'edition_menu.accordion.countdown_appearance': 'Appearance',
	'edition_menu.accordion.countdown_title': 'Titre',
	'edition_menu.accordion.countdown_clock': 'Horloge',
	'edition_menu.accordion.countdown_subtitles': 'Sous-titre',
	'edition_menu.accordion.countdown_days': 'Jours',
	'edition_menu.accordion.countdown_hours': 'Heures',
	'edition_menu.accordion.countdown_minutes': 'Minutes',
	'edition_menu.accordion.countdown_seconds': 'Secondes',

	// Cta options
	'edition_menu.cta_options.header': `Appel à l'action`,
	'edition_menu.cta_options.enabled': 'Activé',
	'edition_menu.cta_options.enabled.description': `Rendre cet élément cliquable et rediriger vers un lien externe, une page interne, envoyer un e-mail ou ouvrir d'un pop-up`,
	'edition_menu.cta_options.selection': 'Sélectionnez un type de CTA',
	'edition_menu.cta_options.tooltip_enabled': 'Info-bulle activée',
	'edition_menu.cta_options.openInNewTab': 'Ouvrir dans un nouvel onglet',
	'edition_menu.cta_options.automatic_redirect': 'Rediriger automatiquement',
	'edition_menu.cta_options.automatic_redirect_delay': 'Rediriger après',
	'edition_menu.cta_options.redirect_directly': 'Rediriger directement',
	'edition_menu.cta_options.redirection_type.none': 'Aucun',
	'edition_menu.cta_options.redirection_type.external': 'Lien externe',
	'edition_menu.cta_options.redirection_type.internal': 'Aller à la page',
	'edition_menu.cta_options.redirection_type.mailto': 'Envoyer un e-mail',
	'edition_menu.cta_options.redirection_type.phoneTo': 'Appel téléphonique',
	'edition_menu.cta_options.redirection_type.messageTo': 'Envoyer un SMS',
	'edition_menu.cta_options.redirection_type.attachment': 'Ouvrir pop-up',
	'edition_menu.cta_options.redirection_type.copyToClipboard': 'Copier dans le presse-papier',
	'edition_menu.cta_options.redirection_type.download': 'Télécharger un fichier',
	'edition_menu.cta_options.link_external': 'Lien',
	'edition_menu.cta_options.link_internal': 'Aller à la page',
	'edition_menu.cta_options.mail_mailto': 'E-mail',
	'edition_menu.cta_options.phoneTo': 'Numéro de téléphone',
	'edition_menu.cta_options.phoneTo.description': 'Appeler le numéro de téléphone ci-dessous en cliquant sur',
	'edition_menu.cta_options.messageTo.description':
		'Envoyer un message au numéro de téléphone ci-dessous en cliquant',
	'edition_menu.cta_options.default_message': 'Message par défaut (facultatif)',
	'edition_menu.cta_options.tooltip': 'Message',
	'edition_menu.cta_options.copyToClipboard.value': 'Texte à copier',
	'edition_menu.cta_options.copyToClipboard.placeholder': 'Saisissez le texte à copier',
	'edition_menu.cta_options.icon': 'Icone',
	'edition_menu.cta_options.icon.description': 'Le favicon du domaine du lien sera choisi comme icône par défaut.',
	'edition_menu.cta_options.mail_mailto.placeholder': 'Entrez un e-mail',
	'edition_menu.cta_options.messageTo.placeholder': 'Entrez un message par défaut',
	'edition_menu.cta_options.element_name': `Nommer l'élément`,

	'edition_menu.cta_options.error.link': `L'URL ne peut pas être vide`,
	'edition_menu.cta_options.empty.mailto': `L'e-mail ne peut pas être vide`,
	'edition_menu.cta_options.empty.phoneNumber': 'Numéro de téléphone cannot be empty',
	'edition_menu.cta_options.error.mailto': `Veuillez entrer un email valide`,
	'edition_menu.cta_options.error.tooltip': `L'info-bulle ne peut pas être vide`,
	'edition_menu.cta_options.redirect.warning': `L'élément doit être en bas de la Story sinon la redirection directe ne fonctionnera pas.`,

	// Interactions
	'edition_menu.interactions.remove_interaction.title': `Suppression d'une interaction`,
	'edition_menu.interactions.remove_interaction.description': `En supprimant cette interaction, vous perdrez les données recueillies depuis cette page.<br></br><br></br>Êtes-vous sûr(e) de vouloir continuer ?`,

	// Attachment As Template
	'edition_menu.amp_attachment.save_as_template.title': `Enregistrez l'attachment en tant que modèle`,
	'edition_menu.amp_attachment.save_as_template.description': `Enregistrez l'attachment actuelle en tant que modèle pour la réutiliser ultérieurement dans les storys sur le lieu de travail.`,

	// Form
	'edition_menu.form.title': 'Édition formulaire',
	'edition_menu.form_properties.title': 'Paramètres du formulaire',
	'edition_menu.fields.title': 'Paramètres des champs',
	'edition_menu.field.title': 'Edition du Champs',
	'edition_menu.form.mandatory': 'Obligatoire*',
	'edition_menu.form.add_field': 'Ajouter un champ',
	'edition_menu.form.add_option': 'Ajouter une option',
	'edition_menu.form.open_settings': 'Moddifier des options',
	'edition_menu.form.indicator': 'Indicateur',
	'edition_menu.form.style': 'Champs: Apparence',
	'edition_menu.form.border': 'Champs: Bordures',
	'edition_menu.label.style': 'Libellés: Apparence',
	'edition_menu.gdpr.link.style': 'GRPD: Message',
	'edition_menu.gdpr.modal.style': 'GRPD: Popup',
	'edition_menu.gdpr.settings.description': `Consentement GDPR affiché dans chaque formulaire à des fins de modification. Dans une story, une fois coché et soumis, il ne sera plus affiché.`,

	'edition_menu.form.email_verification': `Vérification de l'E-mail`,
	'edition_menu.form.email_verification_instruction': `Vérifiez la validité de l'e-mail en envoyant un code de vérification lors de la soumission du formulaire.<br></br>Un champ <b>email</b> est obligatoire.`,
	'edition_menu.form.email_captcha': 'Activer reCAPTCHA',
	'edition_menu.form.email_captcha_instruction':
		'Protège la soumission de votre formulaire contre la fraude et les abus sans créer de friction',
	'edition_menu.form.email_disposable': 'Bloquer e-mails temporaires',
	'edition_menu.form.email_disposable_instruction':
		'Empêche la fraudes et les abus en bloquant l’usage des emails temporaires déjà connus.<br></br><br></br>⚠️ Ne bloque pas les nouveaux emails temporaires valides créés quotidiennement ⚠️',
	'edition_menu.form.unique_email': 'E-mail unique',
	'edition_menu.form.unique_email_instruction': `L'adresse e-mail doit être <b>unique</b> parmi tous les utilisateurs.<br></br>La variable nommée <b>email</b> doit être attribuée à un champ email.`,
	'edition_menu.form.email_required': `Afin de l'utiliser, vous devez ajouter un champ de type email et lier à la variable <b>email</b>`,
	'edition_menu.form.custom_error_messages': `Messages d'erreur`,
	'edition_menu.form.custom_error_messages.instruction': `Permet de personnaliser le texte affiché aux utilisateurs lorsqu'un champ de formulaire n'est pas correctement rempli.`,

	'edition_menu.form.consent.title': 'Consentement RGPD',
	'edition_menu.form.consent.toggle': 'Demander le consentement',
	'edition_menu.form.consent.text': 'Personnaliser le texte de consentement',
	'edition_menu.form.consent.text.placeholder': 'Entrez votre texte ici',
	'edition_menu.form.consent.text.error': 'Customiser le text de consentement',
	'edition_menu.form.consent.message_color': 'Couleur du message',
	'edition_menu.form.consent.customize_message': 'Personnaliser le message de consentement',
	'edition_menu.form.consent.customize_message.placeholder': 'Entrer votre message ici',
	'edition_menu.form.consent.customize_message.instruction': `*Intégrer dans le consentement votre lien avec balise HTML. Ex: <a href='https://www.site.com/' target='_blank'> Lien </a>`,
	'edition_menu.form.consent.url': 'Lien vers votre politique de confidentialité',
	'edition_menu.form.consent.url.placeholder': 'Entrez votre URL...',
	'edition_menu.form.consent.button_hyperlien': 'Bouton hyperlien',
	'edition_menu.form.consent.button_hyperlien.placeholder': 'Texte visible sur le bouton (ex: cliquez ici)',
	'edition_menu.form.consent.button_validation': 'Bouton de validation',
	'edition_menu.form.consent.button_validation.placeholder': 'Accepter',
	'edition_menu.form.label.title': 'Libellé (Facultatif) :',

	'edition_menu.form.variable.tooltip.title': `Assigner une variable permet de :`,
	'edition_menu.form.variable.tooltip.subtitle': `<ul><li>La stocker pour une utilisation ultérieure (ex. dans vos blocs de texte)</li><li>Effectuer des calculs en fonction de la réponse de l’utilisateur</li><li>L’utiliser dans une logique conditionnelle</li></ul>`,
	'edition_menu.form.variable.title': 'Insérer des variables',

	'edition_menu.form.field_type.firstname': 'Prénom',
	'edition_menu.form.field_type.lastname': 'Nom',
	'edition_menu.form.field_type.text': 'Texte',
	'edition_menu.form.field_type.email': 'E-mail',
	'edition_menu.form.field_type.phone': 'Téléphone',
	'edition_menu.form.field_type.number': 'Nombre',
	'edition_menu.form.field_type.select': 'Liste Déroulante',
	'edition_menu.form.field_type.date': 'Date',
	'edition_menu.form.field_type.boolean': 'Booléen',
	'edition_menu.form.field_type.website': 'Site Web',
	'edition_menu.form.field_type.consent': 'Consentement',
	'edition_menu.form.field_type.checkbox': 'Case à cocher',
	'edition_menu.form.field_type.title': 'Titre',
	'edition_menu.form.field_type.paragraph': 'Paragraphe',
	'edition_menu.form.field_type.image': 'Image',
	'edition_menu.form.field_type.button': 'Bouton',
	'edition_menu.form.field_type.disclamer': 'Clause de non-responsabilité',
	'edition_menu.form.field_type.iframe': 'Iframe',
	'edition_menu.form.field_type.username': 'Leaderboard Username',

	'edition_menu.form.field_description.defaultDomain': 'Domaine par défaut :',
	'edition_menu.form.field_description.advanced_settings': 'Réglages avancés',
	'edition_menu.form.field_description.advanced_settings.number': 'Options de nombre et restrictions',
	'edition_menu.form.field_description.advanced_settings.text': 'Options de texte',
	'edition_menu.form.field_description.advanced_settings.date': 'Options de date',
	'edition_menu.form.field_description.advanced_settings.select': 'Ajouter éléments dans la liste',
	'edition_menu.form.field_description.advanced_settings.email': "Options d'email",
	'edition_menu.form.field_description.advanced_settings.gdprConsent': 'Description du consentement et lien',
	'edition_menu.form.field_description.options_settings': `Paramètres d'options`,
	'edition_menu.form.field_description.before': 'Avant:',
	'edition_menu.form.field_description.after': 'Après:',
	'edition_menu.form.field_description.multiple_selections': 'Sélection multiple',
	'edition_menu.form.field_description.limit_selections': 'Limiter les choix:',
	'edition_menu.form.field_description.required': 'Obligatoire',
	'edition_menu.form.field_description.tooltip.required':
		'L’utilisateur ne pourra aller à la page suivante page sans donner de réponse.',
	'edition_menu.form.field_description.fields_settings': 'Paramètres des champs',
	'edition_menu.form.field.default_label.checkbox': `Abonnez-vous à notre newsletter`,
	'edition_menu.form.field.default_anchor.title': 'Politique de confidentialité',
	'edition_menu.form.field.gdpr_tooltip': `Cette case de consentement restera la même pendant toute la Story. Si vous avez besoin de cases à cocher qui ne sont disponibles que sur cette page de formulaire, veuillez utiliser les cases à cocher standard.`,

	'edition_menu.form.field.default_domain.add': 'Ajouter un nouveau domaine',
	'edition_menu.form.field.text_limit.detail': 'Nombre de caractères : ',
	'edition_menu.form.field.number_limit.detail': 'Nombre de chiffres : ',

	'edition_menu.form.modal_title.text': `Paramètres de champ texte`,
	'edition_menu.form.modal_title.email': `Paramètres de champ d'e-mail`,
	'edition_menu.form.modal_title.number': 'Paramètres du champ numérique',
	'edition_menu.form.modal_title.date': 'Paramètres de champ de date',

	'edition_menu.form.field_settings.multiple': 'Sélection multiple',
	'edition_menu.form.field_settings.multiple.message': `Autoriser l'utilisateur à choisir plusieurs réponses`,
	'edition_menu.form.field_settings.limit_choices': 'Limiter les choix',
	'edition_menu.form.field_settings.limit_choices.message': 'Limiter le nombre maximum de choix pour ce champs',
	'edition_menu.form.field_settings.options': 'Liste des options',
	'edition_menu.form.field_settings.new_option': 'Nouvelle Option',
	'edition_menu.form.field_settings.default_country': 'Pays par défaut',
	'edition_menu.form.customize_errors': 'Personnaliser Messages Erreurs',
	'edition_menu.form.field_settings.activeMinMax': 'Limite Min-Max',
	'edition_menu.form.field_settings.activeMinMax.message': `Permet aux utilisateurs de saisir une valeur numérique dans une plage spécifiée, ce qui permet de s'assurer que seules des données valides sont saisies`,
	'edition_menu.form.field_settings.date_format.eu': 'jj/mm/yyyy',
	'edition_menu.form.field_settings.date_format.us': 'mm/jj/yyyy',
	'edition_menu.form.field_settings.date_format.title': 'Format',
	'edition_menu.form.field_settings.activeDateRange': 'Plage de dates',
	'edition_menu.form.field_settings.hasDefaultDomain': 'Domaine par défaut',
	'edition_menu.form.field_settings.hasDefaultDomain.message': `Limitez à un domaine par défaut à la fin d’un champ de formulaire email`,
	'edition_menu.form.field_settings.defaultDomain': 'Domaine',
	'edition_menu.form.field_settings.defaultDomainPlaceholder': 'Exemple: @snackeet.com',
	'edition_menu.form.field_settings.defaultDomainEmpty': 'Le domaine ne peut pas être vide',
	'edition_menu.form.field_settings.defaultDomainInvalid': 'Domaine invalide',
	'edition_menu.form.field_settings.limit_number': 'Limiter le nombre de chiffres',
	'edition_menu.form.field_settings.limit_number.message':
		'Permet de restreindre le nombre de chiffres que l’utilisateur peut saisir dans un champ de nombres',
	'edition_menu.form.field_settings.limit_text': 'Limiter le nombre de caractères',
	'edition_menu.form.field_settings.limit_text.message':
		'Permet de restreindre le nombre de caractères que l’utilisateur peut saisir dans un champ de texte',

	'edition_menu.form.field.default_placeholder.firstname': 'Entrez votre prénom',
	'edition_menu.form.field.default_placeholder.lastname': 'Entrez votre nome',
	'edition_menu.form.field.default_placeholder.text': 'Tapez quelque chose...',
	'edition_menu.form.field.default_placeholder.website': 'https://',
	'edition_menu.form.field.default_placeholder.email': 'Entrez votre Email',
	'edition_menu.form.field.default_placeholder.phone': 'Entrez votre téléphone',
	'edition_menu.form.field.default_placeholder.number': 'Entrez un numéro',
	'edition_menu.form.field.default_placeholder.select': 'Choisissez une option',
	'edition_menu.form.field.default_placeholder.date': 'Entrez votre date',

	'edition_menu.form.field.delete_error': 'Impossible de supprimer le seul champ, ajoutez-en un nouveau à supprimer',

	'edition_menu.form.gdpr.activate': 'Activer le consentement GDPR',
	'edition_menu.form.gdpr.gdpr_activated': 'Consentement GDPR activé',

	'edition_menu.form.label.placeholder': 'Entrez votre libellé',
	'edition_menu.amp_form.url.placeholder': 'Entrez votre URL',

	'edition_menu.amp_form.modal.form_tab': 'Formulaire',
	'edition_menu.amp_form.modal.success_tab': 'Post-soumission',

	'edition_menu.form.button.add': 'Ajouter un nouveau champ',
	'edition_menu.form.add_field.description': 'Ajouter un champ',
	'edition_menu.form.add_element.description': 'Ajouter un élement',
	'edition_menu.form.edit_styles.description': 'Styles de formulaire',
	'edition_menu.form.edit_form.description': 'Contenu du formulaire',
	'edition_menu.form.submit_button.description': 'Bouton de soumission',
	'edition_menu.form.helper.mandatory': `* Lorsque le champ obligatoire est activé, la navigation vers la page suivante est désactivée. L'utilisateur doit remplir le champ pour pouvoir soumettre le formulaire et passer à la page suivante.`,

	'edition_menu.amp_form.cta_text.title': `Texte de l'incitation à l'action`,
	'edition_menu.amp_form.edit': 'Modifier le formulaire',
	'edition_menu.amp_attachment.edit': `Modifier la pop-up`,
	'edition_menu.amp_attachment.create_new': 'Ajouter une pop-up',
	'edition_menu.amp_attachment.iframe_warning': `En utilisant cet élément, la Story peut ne pas apparaître dans les résultats de recherche Google.`,

	// Attachment
	'edition_menu.attachement.edit_content.description': `Contenu de la Pop-up`,

	// Outlink
	'edition_menu.attachment.outlink_header': 'Éditeur de lien',
	'edition_menu.attachment.outlink_link': 'Lien',
	'edition_menu.attachment.outlink_link_placeholder': 'Entrez un lien',
	'edition_menu.attachment.outlink_link_error': 'Veuillez entrer une URL valide. Par exemple: https://exemple.com',
	'edition_menu.attachment.outlink_dark_theme': 'Thème sombre',
	'edition_menu.attachment.outlink_title': 'Titre',
	'edition_menu.attachment.outlink_title_placeholder': 'Entrez un titre',
	'edition_menu.attachment.outlink_title_error': `Le titre du bouton ne doit pas être vide`,
	'edition_menu.attachment.outlink_has_icon': 'Icône activée',
	'edition_menu.attachment.outlink_icon_image': `Image d'icône`,

	'edition_menu.attachment.outlink_default': `Balayez vers le haut`,

	// AMP Form
	'edition_menu.attachment.form_header': 'Formulaire',

	// AMP Attachment
	'edition_menu.attachment.attachment_header': 'Pop-up',

	// AMP Interactions
	'edition_menu.amp_interactions.list_header': 'Liste des interaction',
	'edition_menu.amp_interactions.amp_poll.title': 'Sondage',
	'edition_menu.amp_interactions.amp_binary_poll.title': 'Sondage binaire',
	'edition_menu.amp_interactions.amp_image_poll.title': `Sondage d'images`,
	'edition_menu.amp_interactions.amp_quiz.title': 'Quiz',
	'edition_menu.amp_interactions.amp_image_quiz.title': `Quiz d'images`,
	'edition_menu.amp_interactions.amp_slider.title': 'Slider',

	'edition_menu.amp_interactions.options.header': 'Options',
	'edition_menu.amp_interactions.amp_poll.header': `Sondage`,
	'edition_menu.amp_interactions.amp_quiz.header': 'Quiz',
	'edition_menu.amp_interactions.amp_binary_poll.header': 'Sondage binaire',
	'edition_menu.amp_interactions.amp_image_poll.header': `Sondage d'images`,
	'edition_menu.amp_interactions.amp_image_quiz.header': `Quiz d'images`,
	'edition_menu.amp_interactions.amp_slider.header': 'Slider',

	'edition_menu.amp_interactions.style.question': 'Question',
	'edition_menu.amp_interactions.style.question_placeholder': 'Entrer une question',
	'edition_menu.amp_interactions.style.size': 'Taille',
	'edition_menu.amp_interactions.style.color': 'Couleur',
	'edition_menu.amp_interactions.style.backgroundColor': 'Couleur du fond',
	'edition_menu.amp_interactions.style.alignment': 'Aligner le texte',
	'edition_menu.amp_interactions.style.theme': 'Thème',
	'edition_menu.amp_interactions.style.option_style': `Style options`,
	'edition_menu.amp_interactions.style.option_color': 'Couleur options',
	'edition_menu.amp_interactions.style.slider_color': 'Couleur',
	'edition_menu.amp_interactions.style.slider_icon': 'Icone',

	'edition_menu.amp_interactions.options.title_placeholder': 'Entrez une option',
	'edition_menu.amp_interactions.options.alt_placeholder': `Texte alternatif de l'image`,
	'edition_menu.amp_interactions.options.add_option': 'Ajouter une nouvelle option',
	'edition_menu.amp_interactions.options.warning': `Le nombre d'options doit être compris entre 2 et 4`,
	'edition_menu.amp_interactions.options.isCorrect': 'Bonne Réponse',

	'edition_menu.amp_interaction.title_error': `Le titre de l'option ne peut pas être vide`,
	'edition_menu.amp_interaction.image_error': `L'image de l'option ne peut pas être vide`,
	'edition_menu.amp_interaction.image_title_error': `Option's alternative title cannot be empty`,

	'edition_menu.amp_interaction.tooltip.confetti': 'Cet emoji apparaît quand ce bouton est cliqué',
	'edition_menu.amp_interaction.tooltip.delete': `Supprimer l'option`,
	'edition_menu.amp_interaction.tooltip.correct': `AMP Interaction ne prend en charge qu’une seule réponse correcte à la fois`,

	// Start Button
	'edition_menu.results.display_start_button': 'Afficher Bouton de Démarrage',
	'edition_menu.results.undisplay_start_button.title': 'Masquer le bouton de démarrage',
	'edition_menu.results.undisplay_start_button.warning': `Vous êtes sur le point de <b>masquer</b> le <b>bouton Démarrer</b>. <b>Une Timer</b> sera <b>requise</b> une fois le <b>bouton de démarrage</b> masqué.`,

	// Rating
	'edition_menu.rating.title': 'Édition NPS/Notation',
	'edition_menu.rating.list': 'Liste de notation',
	'edition_menu.rating.redirection_description': `Use <b>Advanced Logic { icon }</b> to redirect according to the rating`,

	'edition_menu.rating.hasForm': 'Afficher le formulaire de feedback',
	'edition_menu.rating.hasForm.description': 'Afficher un formulaire de feedback après la soumission de la note',
	'edition_menu.rating.feedback_form_btn': 'Customiser formulaire feedback',
	'edition_menu.rating.feedback_display_conditions':
		'Afficher le formulaire de feedback lorsque la valeur de la note est :',
	'edition_menu.rating.has_percentage': 'Afficher le pourcentage',

	'edition_menu.rating.modal.title': 'Paramètres du formulaire de feedback',
	'edition_menu.rating.modal.form_title': 'Titre du formulaire',
	'edition_menu.rating.modal.form_messenger_placeholder': 'Placeholder du message du formulaire',
	'edition_menu.rating.modal.form_submit_btn': 'Bouton de soumission',

	// Sharing
	'edition_menu.sharing.title': 'Édition partage',
	'edition_menu.sharing.settings': 'Paramètres de partage',
	'edition_menu.sharing.active_networks': 'Réseaux actifs',

	// Social networks
	'edition_menu.social_networks.title': 'Édition lien social',
	'edition_menu.social_networks.active_networks': 'Lien social actifs',
	'edition_menu.networks.title': 'Édition Réseaux',
	'edition_menu.networks.active_platforms': 'Plateformes de réseaux actives',

	// Results
	'edition_menu.results.display_results': 'Résultats',
	'edition_menu.results.display_results.tooltip': `Afficher les résultats de l'utilisateur`,
	'edition_menu.results.display_all_results': 'Afficher tous les résultats',
	'edition_menu.results.display_all_results.tooltip':
		'Afficher tous les résultats des questions de la story au lieu des seules questions répondues',

	// Game
	'edition_menu.game.title': 'Édition Jeu',
	'edition_menu.game.button.title': 'Tourner la roue',
	'edition_menu.game.button_next.title': 'Suivant',
	'edition_menu.game.field.font_size': 'Taille du texte',

	'edition_menu.game.component.title.wheel': 'Options de la roue',
	'edition_menu.game.component.title.form': 'Formulaire',
	'edition_menu.game.component.title.winning': 'Pop-up gagnante',
	'edition_menu.game.component.title.losing': 'Pop-up perdante',

	'edition_menu.game.wheel.select.helper': `Sélectionnez l'élément à configurer`,
	'edition_menu.game.wheel.button.title': 'Tourner la roue',
	'edition_menu.game.wheel.button.header': 'Boutons',
	'edition_menu.game.wheel.button_next.header': 'Bouton Suivant',
	'edition_menu.game.wheel.button_submit.header': 'Bouton Soumettre',
	'edition_menu.game.wheel.button_link.header': 'Bouton Lien',
	'edition_menu.game.wheel.field.text_colors': 'Couleur de texte',
	'edition_menu.game.wheel.field.background_colors': 'Couleur de fond',
	'edition_menu.game.wheel.field.wheel.header': 'Roue',
	'edition_menu.game.wheel.pointer_color': 'Couleur pointeur',
	'edition_menu.game.wheel.field.wheel_border.size': 'Taille bordure',
	'edition_menu.game.wheel.field.wheel_border.color': 'Couleur bordure',
	'edition_menu.game.wheel.field.items.header': 'Options',
	'edition_menu.game.wheel.field.items_border.size': 'Taille bordure',
	'edition_menu.game.wheel.field.items_border.color': 'Couleur bordure',
	'edition_menu.game.wheel.field.wheel_pointer.header': 'Pointeur de roue',
	'edition_menu.game.wheel.field.center_circle.header': 'Centre',
	'edition_menu.game.wheel.field.center_circle.size': 'Taille',
	'edition_menu.game.wheel.field.center_circle.border_size': 'Taille  bordure',
	'edition_menu.game.wheel.field.center_circle.color': 'Couleur bordure',
	'edition_menu.game.wheel.field.perpendicular_text': 'Texte perpendiculaire',
	'edition_menu.game.wheel.field.button_play': 'Bouton Jouer',
	'edition_menu.game.wheel.prizes.title': 'Prix',
	'edition_menu.game.wheel.prizes.field.chances': 'Chances',
	'edition_menu.game.wheel.prizes.field.prize_label': 'Nom du prix',
	'edition_menu.game.wheel.prizes.field.code': 'Code (optionnel)',
	'edition_menu.game.wheel.prizes.field.weight': 'Poids',
	'edition_menu.game.wheel.prizes.field.losing_option': 'Popup de perte*',
	'edition_menu.game.wheel.modal.winning.header': 'Gagné',
	'edition_menu.game.wheel.modal.winning.description': 'Félicitations! Vous avez remporté {{prize}}',
	'edition_menu.game.wheel.modal.losing.header': 'Perdu',
	'edition_menu.game.wheel.modal.losing.description': `Dommage. Vous aurez plus de chance la prochaines fois !`,
	'edition_menu.game.wheel.modal.form.header': `Merci d'avoir joué`,
	'edition_menu.game.wheel.modal.form.description': `Entrez votre email pour réclamer votre prix.`,
	'edition_menu.game.wheel.modal.form.placeholder': `Entrez votre email`,
	'edition_menu.game.wheel.modal.form_enable': 'Activer le formulaire',
	'edition_menu.game.wheel.modal.form_description':
		'Afficher un formulaire après avoir fait tourner la roue, soumettre le formulaire pour réclamer le prix',
	'edition_menu.game.wheel.modal.redirection_button': 'Bouton de redirection',
	'edition_menu.game.wheel.modal.code': 'Affichage du code',
	'edition_menu.game.wheel.add_prizes': 'Ajouter nouvelle option',

	'edition_menu.game.wheel.prize_variable': 'Prix variable',
	'edition_menu.game.wheel.prize_variable_description': `<b>Attribuez</b> le <b>résultat du prix</b> de la Roue à une <b>variable</b> pour le réutiliser plus tard dans la story (Affichage, Conditions,...) ou pour des intégrations . <br></br><br></br>Si l'option <b>contient un code</b>, le résultat du prix sera [option] - [code]. <br></br>Ex. Remise 50% - REMISE50`,
	'edition_menu.game.wheel.email_variable': 'Email Variable',
	'edition_menu.game.wheel.email_variable_description': `<b>Attribuez un e-mail</b> à une <b>variable</b> pour le réutiliser plus tard dans la Story pour l'affichage, les conditions, la réponse automatique, les intégrations,...`,

	// Messengers
	'edition_menu.messenger.title': 'Édition Messenger',
	'edition_menu.messenger.active_platforms': 'Plateformes de messagerie actives',
	'edition_menu.messenger.phonecall': 'Appel téléphonique',
	'edition_menu.messenger.username': `Nom d'utilisateur`,
	'edition_menu.messenger.number': 'Numéro',
	'edition_menu.messenger.message': 'Message',

	// Answers Menu
	'edition_menu.answer.title': 'Édition bouton/choix',
	'edition_menu.answer.correct': 'Correcte',
	'edition_menu.answer.multiple_selection': 'Sélections multiples',
	'edition_menu.answer.required_answer': 'Réponse obligatoire',
	'edition_menu.answer.other_answer': 'Champs Texte',
	'edition_menu.answer.count_in_results': 'Activer le mode quiz (❓)',
	'edition_menu.answer.display_results.quiz': 'Afficher résultats du quiz (✅ or ❌)',
	'edition_menu.answer.count_response_percentage': 'Afficher les participants en temps réel',
	'edition_menu.answer.display_choice_letters': 'Afficher préfixe des choix (A, B, C...)',
	'edition_menu.answer.add_button.answer': 'Ajouter un bouton/choix',
	'edition_menu.answer.add_button.link': '+ bouton lien',
	'edition_menu.answer.add_button.file_uploader': '+ bouton importer',
	'edition_menu.answer.add_button.file_downloader': '+ bouton télécharger',
	'edition_menu.answer.text_actions': 'Texte et actions',
	'edition_menu.answer.up': 'Monter',
	'edition_menu.answer.down': 'Descendre',

	'edition_menu.modal.nextPage.title': 'Appliquer la redirection',
	'edition_menu.modal.nextPage.description': 'Appliquer cette redirection à tous les boutons ?',
	'edition_menu.modal.nextPage.to_all': 'Appliquer à tous les boutons',
	'edition_menu.modal.nextPage.btn_only': 'Seulement ce bouton',

	'edition_menu.answer.required_answer.description': `L’utilisateur ne pourra aller à la page suivante page sans donner de réponse.`,
	'edition_menu.answer.multiple_selection.description': `L’utilisateur pourra sélectionner plusieurs choix avant de valider.`,
	'edition_menu.answer.other_answer.description': `L’utilisateur pourra écrire sa réponse dans un champ supplémentaire.`,
	'edition_menu.answer.variable.description': `Attribuez une variable pour pouvoir la réutiliser (ex. afficher dans un bloc de texte) ou identifier facilement la réponse lors d’un export`,
	'edition_menu.answer.actions.required_answer': 'Toutes les réponses requises',
	'edition_menu.answer.actions.partial_required_answer': 'Réponse requise',
	'edition_menu.answer.actions.required_answer.description':
		'Les utilisateurs doivent télécharger tous les fichiers pour passer à la page suivante.',
	'edition_menu.answer.actions.partial_required_answer.description':
		'Les utilisateurs ne pourront pas passer à la page suivante sans donner au moins une réponse.',

	'edition_menu.answer_config.button_type.title': 'Type de bouton',
	'edition_menu.answer_config.button_type.type.text': 'Texte',
	'edition_menu.answer_config.button_type.type.image': 'Image',

	'edition_menu.answer_config.header.title': 'Titre',
	'edition_menu.answer_config.header.emoticone': 'Emoji',
	'edition_menu.answer_config.header.emoticone.tooltip': 'Afficher les emoji en cliquant sur le bouton',
	'edition_menu.answer_config.header.emoticone.tooltip_multipleSelection': `Emoji configuré par le bouton Confirmer`,
	'edition_menu.answer_config.header.redirect_to': 'Rediriger vers',
	'edition_menu.answer_config.header.limit_choices': 'Limiter les choix',
	'edition_menu.answer_config.header.confirm_emoji': 'Emoji du bouton de confirmation',
	'edition_menu.answer_config.header.type': 'Type',
	'edition_menu.answer_config.header.settings': 'configuration',
	'edition_menu.answer_config.header.multiple_redirect': 'Rediriger via Sélections multiples',

	'edition_menu.answer.upload_file.button': 'Ajouter un fichier',
	'edition_menu.answer.settings_modal.buttons': 'Paramètres des boutons',

	'edition_menu.answer.title.error': 'Le titre ne peut pas être vide',
	'edition_menu.answer.image_url.error': `L'image ne peut pas être vide`,
	'edition_menu.answer.web_url.error': 'URL ne peut pas être vide',
	'edition_menu.answer.file_download.error': 'Le fichier ne peut pas être vide',

	'edition_menu.answer.default.title': 'Réponse',
	'edition_menu.answer.other_answer.title': 'Autre',
	'edition_menu.answer.link.title': 'Lien ici',
	'edition_menu.answer.file_uploader.title': 'Importer ici',
	'edition_menu.answer.file_downloader.title': 'Télécharger ici',

	'edition_menu.answer.link.redirection_timer_end.tooltip': `Lorsque cette option est activée, à la fin du timer, un nouvel onglet s'ouvre avec le lien indiqué. <br></br><br></br> ⚠️ Activable seulement s'il y a un seul bouton lien.`,

	'edition_menu.story_results': 'Résultats',

	'bottom_toolbar.allow_restart.tooltip': 'Ajouter un bouton recommencer',
	'bottom_toolbar.url_button.tooltip': 'Ajouter un bouton URL',
	'bottom_toolbar.url_button.label': 'URL',

	'edition_menu.answer.state.title': 'État des boutons/choix',
	'edition_menu.answer.state.tooltip': `Vous pouvez personnaliser l'apparence des boutons`,
	'edition_menu.answer.state.default': 'Non sélectionné',
	'edition_menu.answer.state.selected': 'Sélectionné',
	'edition_menu.answer.state.correct': 'Correct',
	'edition_menu.answer.state.incorrect': 'Incorrect',
	'edition_menu.answer.confirm_button': 'Bouton de confirmation',
	'edition_menu.answer.isHidden': 'Afficher le bouton',
	'edition_menu.answer.isVisible': 'Masquer le bouton',
	'edition_menu.answer.limit_answers': 'Limiter nombre de choix',
	'edition_menu.answer.partial_correct': 'Autoriser les réponses partielles',
	'edition_menu.answer.url_preview': 'Rediriger directement',
	'edition_menu.answer.url_preview.hotspot': `Lorsque l'utilisateur clique sur le bouton, redirigez vers le site Web au lieu de l'ouvrir dans un nouvel onglet.`,
	'edition_menu.answer.redirect_timer_end': 'Rediriger à la fin du timer',
	'edition_menu.answer.next_button': 'Ajouter un bouton de redirection',
	'edition_menu.answer.rewards_multiple': 'Ajouter emoji au clic du bouton confirmé',
	'edition_menu.answer.multiple_active': `la <b>multiple selection</b> est <b>activée</b>, après la validation, l'utilsateur sera redirigé vers: <b>{page}</b>`,
	'edition_menu.answer.quiz_redirect_start': `Lors de la confirmation, si la page a la réponse`,
	'edition_menu.answer.quiz_redirect_start_next': `Si`,
	'edition_menu.answer.quiz_redirect_correct': ` correcte `,
	'edition_menu.answer.quiz_redirect_incorrect': ` incorrecte `,
	'edition_menu.answer.quiz_redirect_partial': ` partiellement correcte `,
	'edition_menu.answer.quiz_redirect_end': `aller à :`,
	'edition_menu.quizz.answers.partial_correct.title': 'Réponses partielles',
	'edition_menu.quizz.answers.partial_correct.message': `Par exemple, s'il y a 2 bonnes réponses et que l'utilisateur n'en choisit qu'une. Il se verra accorder 1 point sur 2, et la réponse sera étiquetée comme partiellement correcte.`,

	'edition_menu.quizz.answers.delete_error': `Impossible de supprimer le seul bouton, ajoutez-en un nouveau à supprimer`,

	'edition_menu.quiz_mode.title': 'Mode Quiz',
	'edition_menu.quiz_mode.description': `Le score de la story sera augmenté et les résultats seront affichés à la page de fin.`,

	'edition_menu.answer.praticipants.title': 'Afficher en',
	'edition_menu.answer.praticipants.percentage': 'Pourcentage',
	'edition_menu.answer.praticipants.number': 'Nombre',
	'edition_menu.answer.praticipants.number_and_icon': 'Votes (Nombre et ♥)',

	// Video
	'edition_menu.video.title': 'Édition vidéo',
	'edition_menu.video.tooltip.capture': `Enregistrer une vidéo de la caméra ou de l'écran`,
	'edition_menu.video.tooltip.camera_capture': 'Capturer depuis la caméra',
	'edition_menu.video.tooltip.screen_capture': `Capturer depuis l'écran`,
	'edition_menu.video.record': 'Filmer',
	'edition_menu.video.camera': 'Enregistrer avec la webcam',
	'edition_menu.video.screen': `Enregistrer l'écran`,
	'edition_menu.video.camera.confirm': 'Valider',
	'edition_menu.video.camera.use_another': 'Enregistrer à nouveau',
	'edition_menu.video.unusable': 'Vidéo non utilisable',
	'edition_menu.video.audio_off': 'Microphone désactivé',
	'edition_menu.video.audio_on': 'Microphone activé',
	'edition_menu.video.uploading': `Votre vidéo se télécharge...`,
	'edition_menu.video.message': `Sélectionnez une vidéo`,
	'edition_menu.video.add_video': 'Ajouter une video',
	'edition_menu.video.change_video': `Cliquez pour modifier`,
	'edition_menu.video.empty_list': `Aucune vidéo trouvée`,

	// Subtitle
	'edition_subtitle.noSubtitle': "Cette vidéo n'a pas de sous-titre",
	'edition_subtitle.processing': 'Vos sous-titres sont en cours de traitement',
	'edition_subtitle.activate': 'Activer',
	'edition_subtitle.language.instruction': 'Choisissez votre langue et positionnez vos sous-titres',
	'edition_subtitle.invalid': 'Utilisez une vidéo de votre galerie pour ajouter des sous-titres',
	'edition_subtitle.add': 'Ajouter un sous-titre',
	'edition_subtitle.edit': 'Modifier les sous-titres',
	'edition_subtitle.tooltip.auto': 'Transcription automatique de la vidéo',
	'edition_subtitle.tooltip.manual': 'Transcription manuelle de la vidéo',
	'edition_subtitle.save': 'Enregistrer sous-titre',
	'edition_subtitle.tooltip.position': 'Position verticale',
	'edition_subtitle.error.message': `Une erreur s'est produite avec le processus de sous-titre`,
	'edition_subtitle.button.try_again': 'Réessayer',
	'edition_subtitle.status': 'Statut des sous-titres',
	'edition_subtitle.active': 'Vos sous-titres sont <b>Activés</b>',
	'edition_subtitle.deactive': 'Vos sous-titres sont <b>Désactivés</b>',

	// Text
	'edition_menu.text.title': 'Édition texte',
	'edition_menu.text.font': 'Police',
	'edition_menu.text.halign.title': 'Alignement Horizontal',
	'edition_menu.text.valign.title': 'Alignement Vertical',
	'edition_menu.text.style.title': 'Style',
	'edition_menu.text.padding.top': 'Marge haut',
	'edition_menu.text.padding.bottom': 'Marge bas',
	'edition_menu.text.padding.right': 'Marge droite',
	'edition_menu.text.padding.left': 'Marge gauche',
	'edition_menu.variables.title': 'Insérer',
	'edition_menu.variables.insert': 'Insérer { count, plural, one { variable } other { variables }}',
	'edition_menu.variables.add': '⚠️ Attribuer { count, plural, one { variable } other { variables }}',
	'edition_menu.variables.search': 'Chercher { count, plural, one { variable } other { variables }}',

	'edition_menu.effect.title': 'Effet de texte',
	'edition_menu.effect.none': 'Aucun',
	'edition_menu.effect.shadow': 'Ombre',
	'edition_menu.effect.hollow': 'Creux',
	'edition_menu.effect.neon': 'Néon',
	'edition_menu.effect.echo': 'Écho',
	'edition_menu.effect.otto': 'Otto',
	'edition_menu.effect.relief': 'Relief',
	'edition_menu.effect.printer': 'Printer',
	'edition_menu.effect.glow': 'Briller',
	'edition_menu.effect_calibre.blur': 'Brouiller',
	'edition_menu.effect_calibre.shadow_color': `Couleur de l'ombre`,
	'edition_menu.effect_calibre.fill_color': 'Couleur de remplissage',
	'edition_menu.effect_calibre.thickness': 'Épaisseur',
	'edition_menu.effect_calibre.stroke_color': 'Couleur du trait',
	'edition_menu.icon.change': 'Modifier icône',
	'edition_menu.rating.change': 'Modifier la notation',
	'edition_menu.buttons.settings': 'Bouton',

	'edition_menu.font_family.group_brand': 'Marque',
	'edition_menu.font_family.group_custom': 'Importées',
	'edition_menu.font_family.group_default': 'Défaut',
	'edition_menu.font_family.group_recent': 'Récemment',
	'edition_menu.font_family.add_new': '+ Nouvelle Police',

	'edition_menu.text.tooltip.bold': 'Gras',
	'edition_menu.text.tooltip.italic': 'Italique',
	'edition_menu.text.tooltip.underline': 'Souligner',
	'edition_menu.text.tooltip.strikethrough': 'Barrer',

	'edition_menu.text.tooltip.halign.center': 'Alignement horizontal central',
	'edition_menu.text.tooltip.halign.left': 'Alignement horizontal gauche',
	'edition_menu.text.tooltip.halign.right': 'Alignement horizontal droit',
	'edition_menu.text.tooltip.halign.flex-start': 'Alignement horizontal gauche',
	'edition_menu.text.tooltip.halign.flex-end': 'Alignement horizontal droit',
	'edition_menu.text.tooltip.valign.flex-start': 'Alignement vertical du haut',
	'edition_menu.text.tooltip.valign.center': 'Alignement vertical central',
	'edition_menu.text.tooltip.valign.flex-end': 'Alignement vertical du bas',

	'edition_menu.block.position.top': 'Haut',
	'edition_menu.block.position.left': 'Gauche',

	// Align Block
	'edition_menu.text.tooltip.align.left': 'Alignement gauche',
	'edition_menu.text.tooltip.align.top': 'Alignement du haut',
	'edition_menu.text.tooltip.align.right': 'Alignement droit',
	'edition_menu.text.tooltip.align.bottom': 'Alignement du bas',
	'edition_menu.text.tooltip.align.center': 'Alignement central',
	'edition_menu.text.tooltip.align.vertical_center': 'Alignement vertical central',

	// Image
	'edition_menu.image.title': 'Édition image',
	'edition_menu.image.message': 'Sélectionnez une image',
	'edition_menu.pexel_placeholder.videos': 'Chercher des vidéos sur Pexels...',
	'edition_menu.pexel_placeholder.photos': 'Chercher des photos sur Pexels...',
	'edition_menu.pixabay_placeholder.video': 'Chercher des vidéos sur Pixabay...',
	'edition_menu.pixabay_placeholder.photos': 'Chercher des photos sur Pixabay...',
	'edition_menu.placeholder.images': 'Chercher des images...',
	'edition_menu.image.add_image': 'Ajouter une image',
	'edition_menu.image.empty_list': `Aucune image trouvée`,

	'edition_menu.media.resize.title': `Réajuster`,
	'edition_menu.media.frame.title': `Masque`,
	'edition_menu.media.current_frame.title': `Masque actuel`,
	'edition_menu.media.resize.tooltip': `Redimensionner au rapport dimension d'origine`,

	// Sticker
	'edition_menu.sticker.title': 'Édition sticker animé',
	'edition_menu.sticker.message': 'Sélectionnez un sticker',

	// Calendar
	'edition_menu.calendar.title': 'Édition calendrier',
	'edition_menu.calendar.header': 'Calendrier',
	'edition_menu.calendar.works_with': 'Fonctionne avec',
	'edition_menu.calendar.instructions': 'Ajoutez le lien de votre calendrier',
	'edition_menu.calendar.input_placeholder': 'e.g. https://meetings.hubspot.com/exemple',
	'edition_menu.calendar.invalid_source': 'Entrez un lien de votre calendrier valide',
	'edition_menu.calendar.required': 'Action requise',

	// Location
	'edition_menu.location.title': 'Édition location',
	'edition_menu.location.header': 'Location',
	'edition_menu.location.instructions': 'Ajoutez votre lien intégré de Google Maps',
	'edition_menu.location.input_placeholder': `e.g. <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10500.899077972286!2d2.2913514898040646!3d48.853924137380666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6701f7e8337b5%3A0xa2cb58dd28914524!2sEiffel%20Tower%2C%20Paris!5e0!3m2!1sen!2sfr!4v1638980136415!5m2!1sen!2sfr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
	'edition_menu.location.invalid_source': `Entrez un lien iFrame de carte d'intégration valide`,

	// Countdown
	'edition_menu.countdown.editor': 'Édition Countdown',
	'edition_menu.countdown.deadline': 'Dernière date',
	'edition_menu.countdown.size': 'Taille',
	'edition_menu.countdown.display_title': 'Afficher le titre',
	'edition_menu.countdown.title': 'Titre',
	'edition_menu.countdown.display_seconds': 'Afficher les secondes',
	'edition_menu.countdown.font_family': 'Police',
	'edition_menu.countdown.header_title': 'Titre',
	'edition_menu.countdown.header_clock': 'Horloge',
	'edition_menu.countdown.size_small': 'petit',
	'edition_menu.countdown.size_medium': 'moyen',
	'edition_menu.countdown.size_large': 'grand',

	// Iframe
	'edition_menu.iframe.title': 'Édition Iframe',
	'edition_menu.leaderboard.title': 'Édition Leaderboard',
	'edition_menu.iframe.instruction': `Collez votre iframe html dans l'entrée ci-dessous`,
	'edition_menu.iframe.invalid_source': 'Veuillez entrer un html iframe valide',

	// Pexel and Gif instructions
	'edition_menu.video_selection_instructions': `Cliquez sur une vidéo pour l'ajouter`,
	'edition_menu.image_selection_instructions': `Cliquez sur une image pour l'ajouter`,
	'edition_menu.gif_selection_instructions': `Cliquez sur un GIF pour l'ajouter`,
	'edition_menu.emoji_selection_instructions': `Cliquez sur un emoji pour l'ajouter`,
	'edition_menu.gif_instructions': `Faites une recherche ou sélectionnez une catégorie`,

	// Shape
	'edition_menu.shape.title': 'Édition forme',
	'edition_menu.icon.title': 'Édition icône',
	'edition_menu.shape.change': 'Changer de forme',
	'editor.menu_shape.see_all': 'Voir tout',
	'editor.menu_shape.section.preview': 'Choix de forme/icône',
	'editor.menu_shape.section.placeholder': 'Chercher une forme',
	'editor.menu_shape.section.classic': 'Classique',
	'editor.menu_shape.section.essential': 'Essentiel',
	'editor.menu_shape.section.arrows': 'Flèches',
	'editor.menu_shape.section.ribbons': 'Rubans',
	'editor.menu_shape.section.social_network': 'Réseau sociaux',
	'editor.menu_shape.section.survey': 'Enquête',
	'editor.menu_shape.section.information': 'Information',
	'editor.menu_shape.section.sport': 'Sport',
	'editor.menu_shape.section.education': 'Éducation',
	'editor.menu_shape.section.emoji': 'Emoji',
	'editor.menu_shape.section.recently_used': 'Utilisé récemment',

	// Animation
	'edition_menu.animation.InformationForPage': `*Vous pouvez avoir un aperçu en survolant l'animation`,
	'edition_menu.animation.title': 'Animation',
	'edition_menu.animation.name': `Nom de l'animation`,
	'edition_menu.animation.duration': 'Durée (secondes)',
	'edition_menu.animation.duration.tooltip': `<b>Durée de l'animation</b> se réfère à la durée pendant laquelle l'animation du bloc se terminera.`,
	'edition_menu.animation.delay': 'Retard (secondes)',
	'edition_menu.animation.delay.tooltip': `<b>Retard</b> se réfère à la durée qui s'écoule avant le début de l'animation du bloc une fois qu'il est affiché.`,
	'edition_menu.animation.display_delay': `Délai d'apparition de l'élément`,
	'edition_menu.animation.display_delay.tooltip': `<b>Délai d'apparition de l'élément</b> se réfère à la durée avant que le bloc ne devienne visible à l'écran.`,
	'edition_menu.animation.forever': `Boucle infinie`,
	'edition_menu.animation.clear': 'Réinitialiser',
	'edition_menu.animation.amp_cover_page': `Les animations ont été désactivées sur la page de couverture de votre story pour des raisons de performances par l'équipe AMP`,
	'edition_menu.animation.backgroundZoomIn': 'Zoom In',
	'edition_menu.animation.backgroundZoomOut': 'Zoom Out',

	// Buttons
	'edition_menu.button.title': 'Édition de bouton',
	'edition_menu.button.settings': 'Paramètres des boutons',
	'edition_menu.button.style': 'Boutons: Apparence',
	'edition_menu.button.border': 'Boutons: Bordures',
	'edition_menu.confirm_button.style': 'Bouton Valider et Suivant: Apparence',
	'edition_menu.confirm_button.border': 'Bouton Valider et Suivant: Bordures',
	'edition_menu.button.image.open': "Éditer l'image",
	'edition_menu.button.shape.title': 'Boutons: Forme',
	'edition_menu.button.shape.type_none': 'Pas de bordure',
	'edition_menu.button.shape.type_contained_square': 'Rempli Carré',
	'edition_menu.button.shape.type_contained_round': 'Rempli Rond',
	'edition_menu.button.shape.type_contained_circle': 'Rempli Cercle',
	'edition_menu.button.shape.type_outlined_square': 'Contour Carré',
	'edition_menu.button.shape.type_outlined_round': 'Contour Rond',
	'edition_menu.button.shape.type_outlined_circle': 'Contour Cercle',
	'edition_menu.button.link.url': 'Lien Valide',
	'edition_menu.button.link.no_url': 'Pas de lien',
	'edition_menu.button.link.file': 'Fichier valide',
	'edition_menu.button.link.no_file': 'Pas de fichier',
	'edition_menu.button.link.redirect': 'Redirige vers: {next_page} ({label})',
	'edition_menu.button.link.redirect.next': 'Page suivante ou par logique',
	'edition_menu.button.link.redirect.error': `la page de redirection n'existe plus`,
	'edition_menu.restart_button.title': 'Redémarrer Bouton',
	'edition_menu.link_button.title': 'URL Bouton',
	'edition_menu.background_to_image': 'Arrière-plan en image',
	'edition_menu.background_to_video': 'Arrière-plan en vidéo',

	// Tag
	'edition_menu.tag.title': 'Édition tag',
	'edition_menu.tag.tag_header': 'Paramètres de Tag',
	'edition_menu.tag.pulse_header': 'Effet de Pulse',
	'edition_menu.tag.titleTag': 'Titre',
	'edition_menu.tag.price': 'Prix',
	'edition_menu.tag.price_color': 'Couleur du prix',
	'edition_menu.tag_placeholder.titleTag': 'Indiquer le titre',
	'edition_menu.tag_placeholder.price': 'Indiquer le prix',
	'edition_menu.tag_placeholder.url': "Renseigner l'URL",

	'edition_menu.tag.add_tag': 'Ajouter une étiquette',
	'edition_menu.tag.description': `Ajoutez des points d'impact pour afficher le nom du produit, le coût et un lien direct vers les pages externes du produit. Rehaussez votre expérience utilisateur sans effort !`,

	// File Uploader
	'edition_menu.file_uploader.label': 'Fichiers acceptés',
	'edition_menu.file_uploader.file_type': 'Types de fichiers',

	// File Downloader
	'edition_menu.file_downloader.button': 'Importer',
	'editor_menu.file_downloader.tooltip': 'Ajoutez un fichier que vous souhaitez partager via la story',
	'editor_menu.file_downloader.message': 'Fichier',
	'editor_menu.file_downloader.error.type': 'Le format de fichier est invalide.',

	// Block
	'edition_menu.hovertools.animation': 'Animation',
	'edition_menu.hovertools.variables': 'Insérer Variables',
	'edition_menu.hovertools.duplicate': 'Dupliquer',
	'edition_menu.hovertools.delete': 'Supprimer',
	'edition_menu.hovertools.ratio.lock': 'Verrouiller Dimensions',
	'edition_menu.hovertools.ratio.unlock': 'Déverrouiller Dimensions',
	'edition_menu.hovertools.ratio.emoji': 'Ajouter Emoji',

	'edition_menu.block.flip.header': 'Inverser',
	'edition_menu.block.flip.vertical': 'Inverser Verticalement',
	'edition_menu.block.flip.horizontal': 'Inverser Horizontalement',

	// Interaction
	'edition_menu.interaction.changeType': 'Changer Type De Réponse',
	'edition_menu.interaction.delete': 'Supprimer Réponse',

	// Rating
	'edition_menu.rating.settings_header': 'Paramètres de NPS/Notation',
	'edition_menu.rating.styles': 'NPS/Notation: Styles',
	'edition_menu.rating.add_title': 'Saisissez un titre',
	'edition_menu.rating.add_subtitle': 'Saisissez un sous-titre',

	// Media Answer
	'edition_menu.media_answer.buttons_style': 'Boutons',
	'edition_menu.media_answer.header': 'Réponse multimédia',
	'edition_menu.media_answer.title': 'Title',
	'edition_menu.media_answer.video_title': 'Titre vidéo',
	'edition_menu.media_answer.selfie_title': 'Titre selfie',
	'edition_menu.media_answer.audio_title': 'Titre Audio',
	'edition_menu.media_answer.text_title': 'Titre Texte',
	'edition_menu.media_answer.hasVideo': 'Réponse vidéo',
	'edition_menu.media_answer.hasSelfie': 'Réponse selfie',
	'edition_menu.media_answer.hasAudio': 'Réponse audio',
	'edition_menu.media_answer.hasText': 'Réponse texte',
	'edition_menu.media_answer.duration': 'Durée vidéo/audio',
	'edition_menu.media_answer.required': 'Obligatoire',
	'edition_menu.media_answer.replayable': 'Rejouable',
	'edition_menu.media_answer.setting_styles': 'Paramètres',
	'edition_menu.media_answer.buttons': 'Boutons',
	'edition_menu.media_answer.styles': 'Styles',
	'edition_menu.media_answer.messages': 'Messages',

	'edition_menu.media_answer.start_button': 'Bouton Démarrer',
	'edition_menu.media_answer.restart_button': 'Bouton Redémarrer',
	'edition_menu.media_answer.confirm_button': 'Bouton Confirmer',
	'edition_menu.media_answer.text_placeholder': 'Texte Placeholder',
	'edition_menu.media_answer.modify_response': 'Modifier la réponse',
	'edition_menu.media_answer.continue_button': 'Bouton Continuer',

	// Advanced Logics
	'edition_menu.advanced_logic.page_header': 'Configuration logique de la page',
	'edition_menu.advanced_logic.header': 'Logique avancée',
	'edition_menu.advanced_logic.description': `Si vous devez rediriger ou exécuter une opération en fonction d'une condition, vous pouvez définir la logique ci-dessous.`,
	'edition_menu.advanced_logic.logic_set': 'Logique avancée définie',
	'edition_menu.advanced_logic.logic_unset': 'Logique avancée NON définie',
	'edition_menu.advanced_logic.logic_set.error': 'Logique erreur',
	'edition_menu.advanced_logic.button.config_logics': 'Configurer Logique Avancée',
	'edition_menu.advanced_logic.button.config_variables': 'Créer / Supprimer les variables',

	// Condition on_enter
	'edition_menu.condition.on_enter.header': `Condition d'affichage`,
	'edition_menu.condition.on_enter.description':
		'Si vous devez masquer cette page, vous pouvez définir une condition pour rediriger vers une autre page.',
	'edition_menu.condition.on_enter.if': 'Affiche la page si :',
	'edition_menu.condition.on_enter.enabled': `Activer`,
	'edition_menu.condition.on_enter.otherwise': 'Sinon',
	'edition_menu.condition.on_enter.button.config': 'Configurer Condition',
	'edition_menu.condition.on_enter.logic_unset': `Condition d'affichage NON définie`,

	// Background
	'edition_menu.background.recent_colors': 'Utilisé récemment',
	'edition_menu.gradient.enabled': 'Arrière-plan dégradé',
	'edition_menu.gradient.add_color': 'Ajouter une couleur dégradée',
	'edition_menu.gradient.colors_limit': '4 couleurs maximum',
	'edition_menu.gradient.type_linear': 'Linéaire',
	'edition_menu.gradient.type_radial': 'Radiale',
	'edition_menu.gradient.colors': 'Couleurs',

	'edition_menu.colors.recently_used': 'Utilisé récemment',
	'edition_menu.none.description':
		'Utilisez le menu déroulant ci-dessus pour sélectionner une image, une vidéo ou une couleur comme arrière-plan',

	// Ending page button
	'edition_menu.ending_page.button.url': 'Lien',
	'edition_menu.ending_page.button.download': 'Téléchargement',

	// Carousel
	'edition_menu.carousel.title': 'Éditeur de Carrousel',
	'edition_menu.carousel.tooltip.carousel': 'Carrousel',
	'edition_menu.carousel.tooltip.styles': 'Styles',

	'edition_menu.carousel.carousel_settings': 'Paramètres du Carrousel',
	'edition_menu.carousel.carousel_settings.item_type': 'Type',
	'edition_menu.carousel.carousel_settings.title': 'Titre',
	'edition_menu.carousel.carousel_settings.navigation_buttons': 'Boutons de navigation',

	'edition_menu.carousel.carousel_settings.item_type.card': 'Carte (Rectangle & Carré)',
	'edition_menu.carousel.carousel_settings.item_type.detailed': 'Détaillé (Rectangle & Carré)',
	'edition_menu.carousel.carousel_settings.item_type.rounded': 'Arrondi',
	'edition_menu.carousel.carousel_settings.item_type.product': 'Produit',

	'edition_menu.carousel.item_styles.image_shape.rectangle': 'Rectangle',
	'edition_menu.carousel.item_styles.image_shape.square': 'Carré',

	'edition_menu.carousel.carousel_items': 'Carrousel Cartes',
	'edition_menu.carousel.carousel_item.image': 'Image',
	'edition_menu.carousel.carousel_item.title': 'Titre',
	'edition_menu.carousel.carousel_item.click_action': 'Action de clic',
	'edition_menu.carousel.carousel_item.add_btn': 'Ajouter une nouvelle carte',
	'edition_menu.carousel.title_size': 'Taille du titre',

	'edition_menu.carousel.carousel_styles': 'Carrousel',

	'edition_menu.carousel.item_styles': 'Carte',
	'edition_menu.carousel.item_styles.image_size': 'Image taille',
	'edition_menu.carousel.item_styles.image_shape': 'Image form',
	'edition_menu.carousel.item_styles.overlay_color': 'Superposition',
	'edition_menu.carousel.item_styles.hasPlayBtn': 'Bouton de lecture',
	'edition_menu.carousel.item_styles.boxShadow': 'Ombre portée',
	'edition_menu.carousel.item_styles.shadowColor': 'Couleur',

	'editor_menu.carousel.carousel_styles.border': 'Bordure',
	'editor_menu.carousel.carousel_styles.borderWidth': 'Largeur ',
	'editor_menu.carousel.carousel_styles.borderRadius': 'Rayon',
	'editor_menu.carousel.carousel_styles.borderColor': 'Couleur',
	'editor_menu.carousel.item_styles.item_text': 'Texte de carte',
	'editor_menu.carousel.item_styles.item_border': 'Bordure',
	'editor_menu.carousel.item_styles.item_shadow': 'Ombre',
	'editor_menu.carousel.item_styles.inner_border': 'Bordure intérieure',
	'editor_menu.carousel.item_styles.button_styles': 'Styles de bouton',

	'edition_menu.carousel_drawer.image': 'Image',
	'edition_menu.carousel_drawer.title': 'Titre',
	'edition_menu.carousel_drawer.subtitle': 'Description ou Prix',
	'edition_menu.carousel_drawer.btn_title': 'Titre du bouton',
	'edition_menu.carousel_drawer.click_action': 'Action de clic',
	'edition_menu.carousel_drawer.link': 'Lien',
	'edition_menu.carousel_drawer.link_error': 'Veuillez entrer une URL https valide',
	'edition_menu.carousel_drawer.goto': 'Aller à la page',

	'edition_menu.carousel_drawer.get_product': "Obtenir données produit à partir de l'URL",
	'edition_menu.carousel_drawer.get_product_placeholder': "Entrez l'URL du produit",
	'edition_menu.carousel_drawer.get_product_btn': 'Obtenir',

	// Button List
	'edition_menu.buttonList.title': 'Éditeur liste de boutons',
	'edition_menu.buttonList.tooltip.settings': 'Liste de boutons',
	'edition_menu.buttonList.tooltip.styles': 'Styles',

	'edition_menu.buttonList.buttons': 'Boutons',
	'edition_menu.buttonList.appearance': 'Apparence',
	'edition_menu.buttonList.border': 'Bordure',
	'edition_menu.buttonList.add_button': 'Ajouter un nouveau bouton',

	'edition_menu.buttonList.image': 'Image',
	'edition_menu.buttonList.icon': 'Icône',
	'edition_menu.buttonList.add_icon': 'Choisir une icône',
	'edition_menu.buttonList.change_icon': "Changer l'icône actuelle",
	'edition_menu.buttonList.get_favicon': "Obtenir l'image depuis l'URL",

	// Share
	'edition_menu.share.Facebook': 'Facebook',
	'edition_menu.share.Twitter': 'Twitter',
	'edition_menu.share.Linkedin': 'LinkedIn',
	'edition_menu.share.Pinterest': 'Pinterest',
	'edition_menu.share.Whatsapp': 'WhatsApp',
	'edition_menu.share.Email': 'E-mail',
	'edition_menu.share.SMS': 'SMS',
	'edition_menu.share.Messenger': 'Messenger',
	'edition_menu.share.Telegram': 'Telegram',
	'edition_menu.share.Reddit': 'Reddit',
	'edition_menu.share.Tumblr': 'Tumblr',
	'edition_menu.share.VK': 'VK',
	'edition_menu.share.copyLinkToClipboard': 'Copier le lien dans le presse-papiers',

	// Shopify
	'edition_menu.shopify.no_connection': "Vous n'avez connecté aucune boutique Shopify",
	'edition_menu.shopify.connect': 'Connectez votre boutique Shopify',
	'edition_menu.shopify.connecting': 'Connexion en cours...',
	'edition_menu.shopify.add_new': 'Ajouter une nouvelle boutique Shopify',

	'edition_menu.shopify.asset_loading': 'Chargement',
	'edition_menu.shopify.asset_error': 'Une erreur est survenue',
	'edition_menu.shopify.asset_no_products': 'Aucun produit trouvé',
	'edition_menu.shopify.asset_add_one': 'Choisissez un produit',
	'edition_menu.shopify.asset_search': 'Rechercher des produits par titre',

	'edition_menu.shopify.product_out_of_stock': 'En rupture de stock',
	'edition_menu.shopify.product_added': 'Produit ajouté',
	'edition_menu.shopify.product_default_btn_title': 'Voir le produit',
	'edition_menu.shopify.go_to_shop': 'Ouvrir page du produit',

	'edition_menu.shopify.title': 'Éditeur Shopify',
	'edition_menu.shopify.tooltip.shopify': 'Shopify',
	'edition_menu.shopify.tooltip.styles': 'Styles',
	'edition_menu.shopify.tooltip.product': 'Produit',
	'edition_menu.shopify.menu_settings': 'Paramètres Shopify',

	'edition_menu.shopify.menu_shape': 'Forme',
	'edition_menu.shopify.menu_price': 'Prix',
	'edition_menu.shopify.menu_description': 'Description',
	'edition_menu.shopify.button_title': 'Titre du bouton',
	'edition_menu.shopify.button_icon': 'Icône du bouton',
	'edition_menu.shopify.products': 'Produits Shopify',
	'edition_menu.shopify.add_product': 'Ajouter un nouveau produit',
}
