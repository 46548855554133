export const en = {
	'editor.story_not_found.title': `The Story doesn't exist.`,
	'editor.story_not_found.button': 'Go to list of stories',

	'editor.top_bar.saving': 'Saving changes...',
	'editor.top_bar.saved': 'All changes saved',
	'editor.top_bar.unsaved': 'There are unsaved changes',
	'editor.top_bar.preview': 'Preview your story',
	'editor.top_bar.share': 'Share your story',
	'editor.top_bar.publish_change': 'Publish',
	'editor.top_bar.no_publish_change': 'Nothing to publish',
	'editor.top_bar.story_errors': '{ count, plural, one { ⚠️ # error } other { ⚠️ # errors }}',
	'editor.top_bar.story_recommendations': '{ count, plural, one { # suggestion } other { # suggestions }}',
	'editor.top_bar.activate': 'Enable story',
	'editor.top_bar.deactivate': 'Disable story',
	'editor.top_bar.story_share.title': 'Share your Story',
	'editor.top_bar.story_share.published_at': 'Last published at',
	'editor.top_bar.story_share.customize_url': 'Customize URL',
	'editor.top_bar.story_share.open_story': 'Open Story',
	'editor.top_bar.story_test.title': 'Test the Story',
	'editor.top_bar.story_test.link.description.1': 'Do not share this test link with your clients.',
	'editor.top_bar.story_test.link.description.2': 'No data will be recorded.',
	'editor.top_bar.story_test.qr_code.description.1': 'Do not share it with your clients.',
	'editor.top_bar.story_test.qr_code.description.2': 'Scan the QR code to launch the test.',

	'editor.import.button': 'Upload',
	'editor.tooltip.preview_page': 'Check how this page will be displayed',
	'editor.hotspot.timer.disabled.title': 'Auto Advance is disabled',
	'editor.hotspot.timer.disabled.message': `- to improve user experience<br></br>OR<br></br>- because at least one of your fields or buttons is required.`,
	'editor.hotspot.timer.enabled.title': 'Auto Advance',
	'editor.hotspot.timer.enabled.message': `Automatically go to the next page once the timer ends.`,
	'editor.hotspot.timer.amp.message': `The page duration equals the longest video's length.<br></br> If no videos are found, the duration is adjusted based on the number of words (minimum duration of 10 seconds).`,
	'editor.hotspot.widget_text.title': 'Widget collapse text',
	'editor.hotspot.widget_text.message': `This is the text that is displayed when we minimize the widget`,

	// Background
	'editor.button.background_edition': 'Background',
	'editor.button.background_edition.color': `Color`,
	'editor.button.background_edition.video': `Video`,
	'editor.button.background_edition.image': `Image, GIF, Sticker`,
	'editor.button.background_edition.empty': `No Background`,
	'editor.background.title.color': 'Background Color',
	'editor.background.title.video': 'Background Video',
	'editor.background.title.image': 'Background Image',
	'editor.background.title.gif': 'Background GIF',

	// Overlay
	'editor.background.overlay_title': 'Overlay Settings',
	'editor.background.overlay_opacity': 'Opacity',
	'editor.background.overlay_colors': 'Colors',

	// Text
	'editor.text_properties.header': 'Edit Text Properties', // Not used

	// ImageImport
	'editor.image_import.tooltip.upload': 'Upload an image',
	'editor.image_import.button.crop': 'Crop image',
	'editor.image_import.info': `You can paste below the link of image`,

	// VideoImport
	'editor.video_import.tooltip.upload': 'Upload a video',
	'editor.video.start': 'Start',
	'editor.video.end': 'End',
	'editor.video.speed': 'Playback speed',
	'editor.video.estimated_time': 'Estimated time',
	'editor.video.timeline_header': 'Video player options',
	'editor.video_import.info':
		'*Support URLs of videos from variety sources including YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion, Kaltura...',
	'editor.video.integration.error': 'Video url is not working',
	'editor.video.integration.error_wavevideo': 'Embed Wave Video not working, make sure your video is embedded',

	// Appearance
	'editor.appearance.page_info': `Configure elements style of your Stories`,
	'editor.appearance.reset_default': `Reset defaults`,
	'editor.appearance.reset_warning': `This will erase all your changes.`,

	// Configuration

	'editor.configuration.page_type.start_page': `First Page (Landing)`, // Not used
	'editor.configuration.page_type.ending_page': `End Page`, // Not used
	'editor.configuration.page_type.form': `Form`,
	'editor.configuration.page_type.blank': `No Answer`,
	'editor.configuration.page_type.multiple_choice': `Button/Choice`,
	'editor.configuration.page_type.rating': `NPS/Rating`,
	'editor.configuration.page_type.game': 'Game',
	'editor.configuration.page_type.media_answer': 'Media Answer',

	'editor.configuration.block_type.text': `Text`,
	'editor.configuration.block_type.text.heading_1': `Heading 1`,
	'editor.configuration.block_type.text.heading_2': `Heading 2`,
	'editor.configuration.block_type.text.heading_3': `Heading 3`,
	'editor.configuration.block_type.text.paragraph': `Paragraph`,
	'editor.configuration.block_type.image': `Image, GIF, Sticker`,
	'editor.configuration.block_type.sticker': `Animated Sticker`,
	'editor.configuration.block_type.video': `Video`,
	'editor.configuration.block_type.calendar': `Calendar`,
	'editor.configuration.block_type.shape': `Shape`,
	'editor.configuration.block_type.social_network': `Network`,
	'editor.configuration.block_type.networks': `Socials & Messengers`,
	'editor.configuration.block_type.social-network': `Network`,
	'editor.configuration.block_type.tag': `Tag`,
	'editor.configuration.block_type.carousel': `Carousel`,
	'editor.configuration.block_type.location': `Location`,
	'editor.configuration.block_type.messenger': `Messenger`,
	'editor.configuration.block_type.iframe': `Iframe`,
	'editor.configuration.block_type.countdown': `Countdown`,
	'editor.configuration.block_type.leaderboard': `Leaderboard`,
	'editor.configuration.block_type.share': `Share`,
	'editor.configuration.block_type.icon': 'Icon',
	'editor.configuration.block_type.button': 'CTA: Element',
	'editor.configuration.block_type.button_amp': 'CTA Button',
	'editor.configuration.block_type.button_invisible': 'Invisible Button',
	'editor.configuration.block_type.communication': 'Communication',
	'editor.configuration.block_type.other': 'Other',
	'editor.configuration.block_type.popup': 'Popup',
	'editor.configuration.block_type.button_description': 'URL, download, contact...',
	'editor.configuration.block_type.button_amp_description': 'URL, send mail',
	'editor.configuration.block_type.buttonList': 'CTA: Buttons List',
	'editor.configuration.block_type.shopping': 'Shopify',

	'editor.configuration.amp_interaction': 'Interaction',
	'editor.configuration.amp_attachment.outlink': 'Link',
	'editor.configuration.amp_attachment.form': 'Form',
	'editor.configuration.amp_attachment.content': 'Pop Up / Iframe',
	'editor.configuration.amp_attachment.amp_form': 'Form',
	'editor.configuration.amp_attachment.amp_attachment': 'Pop Up',

	'editor.configuration.interaction_type.form': 'Form',
	'editor.configuration.interaction_type.rating': 'NPS/Rating',
	'editor.configuration.interaction_type.answer': 'Answers',
	'editor.configuration.interaction_type.action': 'Link/File',
	'editor.configuration.interaction_type.upload_file': 'Upload File',
	'editor.configuration.interaction_type.multiple_choice': 'Quiz/Survey/Vote',
	'editor.configuration.interaction_type.answers': 'Answer Buttons',
	'editor.configuration.interaction_type.media_answer': 'Answer by video/photo/audio',
	'editor.configuration.interaction_type.game': 'Wheel of fortune',
	'editor.configuration.interaction_type.story_results': 'Story Results',

	'editor.configuration.interaction_type.amp_interaction': 'Interaction',
	'editor.configuration.interaction_type.amp_attachment': 'Attachment',
	'editor.configuration.interaction_type.amp_outlink': 'Outlink',

	'editor.selector.instruction': 'Click on element to add to story',

	'editor.configuration.empty_answers': 'Add one or more buttons',
	'editor.configuration.empty_form': 'Add one or more form fields',

	'editor.configuration.rating_default_text': 'Your title',
	'editor.configuration.media_answer_default_text': 'How would you like to answer?',
	'editor.configuration.background.dimmer': 'Darkness',

	'editor.configuration.page_settings.logic': 'Advanced Logic',
	'editor.configuration.page_settings.animation': 'Page Animation',
	'editor.configuration.left_tab.pages': 'Pages',
	'editor.configuration.left_tab.elements': 'Layers Editor',
	'editor.configuration.editor_button.layers': 'Layers',
	'editor.configuration.left_tab.pageType': 'Response type',
	'editor.configuration.left_tab.pageTemplates': 'Use an existing template',
	'editor.configuration.editor_button.pageTemplates': 'Response type and template',
	'editor.pages_nav.create_ending_page.disable':
		'Adding multiple end pages is not available at this time.<br></br>If you want to use this feature please contact us.',
	// 'editor.pages_nav.create_ending_page.disable': 'You have touched the limit number of end pages.<br></br>If you want to use this feature please upgrade your plan.',
	'editor.pages_nav.create_page.disable':
		'You have reached the limit number of pages per story.<br></br>If you want to use this feature please upgrade your plan.',
	'editor.pages_nav.tooltip.add_page': 'Add page',

	'editor.configuration.share_settings.title': 'Share Settings',
	'editor.configuration.share_settings.description_field': 'Customize your description story',
	'editor.configuration.share_settings.active_social_networks': 'Active social networks',
	'editor.configuration.share_settings.enable_widget': 'Enable share widget',
	'editor.configuration.share_settings.modal_title': 'Window Title',
	'editor.configuration.share_settings.modal_title.initial_value': 'Share',

	// Preview Tooltips
	'editor.button.tooltip.background_edition': 'Change the background of the page with either a color, image or video',
	'editor.button.tooltip.settings':
		'Change settings of page or add an animation to the page to make it more dynamic!',
	'editor.button.tooltip.layers': 'Rearrange and manage the different layers of the page',
	'editor.button.tooltip.pageType': 'Click to change response type',
	'editor.button.tooltip.share_settings':
		'Configure the sharing widget so your users can share the story from any page on their network.<br></br><br></br> ⚠️ Disabled in test mode.',
	'editor.button.tooltip.share_settings_amp':
		'Configure the sharing widget so your users can share the story from any page on their network.',

	// Element Selector
	'editor.element_selector.item_disabled_ending_page': 'There can only be one of this widget per page.',
	'editor.element_selector.form_disabled':
		'There can only be one form section on the page.<br></br><br></br>For editing options, click on the form.',
	'editor.element_selector.multiple_choices_disabled':
		'There can only be one button/choice section on the page.<br></br><br></br>For editing options, click on the button/choice.',
	'editor.element_selector.item_disabled':
		'This element is not available on the WELCOME PAGE, please create a new page.',
	'editor.element_selector.rating_disabled': 'There can only be one NPS/rating section on the page.',
	'editor.element_selector.calendar_disabled': 'There can only be one calendar on the page.',
	'editor.element_selector.leaderboard_disabled':
		'<b>Leaderboard</b> must be <b>activated</b> in the <b>set up</b> to be added to the page.',
	'editor.element_selector.location_disabled': 'There can only be one location on the page.',
	'editor.element_selector.outlink_disabled':
		'There can only be one <b>link button</b> or a <b>pop up</b> on the page.',
	'editor.element_selector.amp_interaction_disabled':
		'There can only be one <b>interaction</b> or <b>pop up</b> or <b>form</b> on the page.',
	'editor.element_selector.sharing_disabled': 'There can only be one sharing block on the page.',
	'editor.element_selector.social_network_disabled': 'There can only be one Social networks block on the page.',
	'editor.element_selector.share_disabled': 'There can only be one Share block on the page.',
	'editor.element_selector.messenger_disabled': 'There can only be one Messenger block on the page.',
	'editor.element_selector.snackeet_interaction_disabled':
		'Cannot add <b>interaction</b> to <b>End page</b> or <b>Welcome page</b>',

	// Page Templates
	'editor.page_templates.modal.title': 'Apply new page template',
	'editor.page_templates.modal.description': `All visuals will be modified (colors, images, videos...).<br></br>Interactions will be preserved (forms, buttons...).`,

	// Page Types
	'editor.page_type.start_page': 'Starting page',
	'editor.page_type.ending_page': 'Ending page',
	'editor.page_type.blank': 'Buttons / Form / Interactions',
	'editor.page_type.form': 'Form page',
	'editor.page_type.rating': 'NPS/Rating',
	'editor.page_type.answer': 'Classic button',
	'editor.page_type.media_answer': 'Media answer',
	'editor.page_type.action': 'Link/Upload/Download page',
	'editor.page_type.game': 'Game page',
	'editor.page_type.change_page_type': 'Change answer type',

	// Media Answer
	'editor.interaction.media_answer.video': 'Video',
	'editor.interaction.media_answer.audio': 'Audio',
	'editor.interaction.media_answer.text': 'Text',
	'editor.interaction.media_answer.video_only': 'Record your answer',
	'editor.interaction.media_answer.audio_only': 'Record your answer',
	'editor.interaction.media_answer.text_only': 'Enter your answer',

	// Countdown
	'editor.block.countdown.days': 'Days',
	'editor.block.countdown.hours': 'Hours',
	'editor.block.countdown.minutes': 'Minutes',
	'editor.block.countdown.seconds': 'Seconds',

	// Preview Page
	'editor.page_preview.tooltip.timer_set': 'Auto Advance Timer Set',
	'editor.page_preview.tooltip.animation_set': 'Animation Set',
	'editor.page_preview.tooltip.logic_set': 'Logic Set',
	'editor.page_preview.tooltip.interaction_required': 'Required Response',
	'editor.page_preview.tooltip.no_redirection': 'No Redirection',

	// Form Consent
	// 'editor.form_consent.message': 'By clicking on Send, I agree with the',
	// 'editor.form_consent.message.policy': '<b><u>privacy policy</u></b>.',

	'editor.story_name.tooltip': 'You can edit the story name here',
	'editor.story_name.label': 'Story name',

	'editor.selector.interaction.poll_text': 'Survey',
	'editor.selector.interaction.poll_image': 'Survey with image',
	'editor.selector.interaction.quiz_text': 'Quiz',
	'editor.selector.interaction.quiz_image': 'Quiz with image',
	'editor.selector.interaction.vote_text': 'Vote',
	'editor.selector.interaction.vote_image': 'Vote with image',
	'editor.selector.interaction.answer_text': 'Text',
	'editor.selector.interaction.answer_image': 'Answer with image',

	'editor.selector.interaction.media_answer_image': 'Photo',
	'editor.selector.interaction.media_answer_video': 'Video Recorder',
	'editor.selector.interaction.media_answer_audio': 'Audio Recorder',
	'editor.selector.interaction.media_answer_all': 'Multiple Options',
	'editor.selector.interaction.media_answer_image.title': 'Answer by photo',
	'editor.selector.interaction.media_answer_video.title': 'Answer by video',
	'editor.selector.interaction.media_answer_audio.title': 'Answer by audio',

	'editor.selector.interaction.form_standard.title': 'Standard Form',
	'editor.selector.interaction.form_basics.title': 'Basic Form',

	'editor.selector.interaction.contact_form': 'Short Contact Form',
	'editor.selector.interaction.contact_form_complet': 'Long Contact Form',
	'editor.selector.interaction.leaderboard_form': 'Leaderboard Form',
	'editor.selector.interaction.newsletter_form': 'Newsletter Form',
	'editor.selector.interaction.event_registration': 'Event Registration',

	'editor.selector.interaction.number_form': 'Input Number',
	'editor.selector.interaction.dropdown_form': 'Dropdown',
	'editor.selector.interaction.checkbox_form': 'Checkbox',
	'editor.selector.interaction.date_form': 'Input Date',

	'editor.selector.interaction.wheel': 'Wheel of Fortune',

	// Button block
	'editor.button.text_button': 'Text Button',
	'editor.button.icon_button': 'Icon Button',
	'editor.button.invisible_button': 'Invisible Button',
	'editor.button.navigation_button': 'Navigation Button',
	'editor.button.url_button': 'URL Button',
	'editor.button.download_button': 'Download Button',
	'editor.button.popup_button': 'Popup Button',
	'editor.button.communication_button': 'Communication Button',
	'editor.button.copyText_button': 'Text Copy Button',

	'editor.button.text_rectangle': 'Rectangle Button',
	'editor.button.text_outlined': 'Outlined Button',
	'editor.button.text_filled': 'Filled Button',
	'editor.button.text_circle_filled': 'Circle Filled',
	'editor.button.text_circle_outlined': 'Circle Outlined',

	'editor.button.invisible_rectangle': 'Rectangle Button',
	'editor.button.invisible_circle': 'Circle Button',

	'editor.button.navigation_back': 'Go Back',
	'editor.button.navigation_next': 'Go Next',

	'editor.button.invisible.descrpition': `Striped background is just for editing.`,

	'editor.selector.carousel.card': 'Card',
	'editor.selector.carousel.detailed': 'Detailed Card',
	'editor.selector.carousel.rounded': 'Circle Card',
	'editor.selector.carousel.product': 'Product',

	'editor.selector.section.interactions': 'Interactions:',
	'editor.selector.section.common_elements': 'Common Elements:',
	'editor.selector.section.media': 'Media:',
	'editor.selector.section.shape': 'Shape:',
	'editor.selector.section.communication': 'Communication:',
	'editor.selector.section.iframe': 'Iframe:',
	'editor.selector.section.product': 'Product and Event:',
	'editor.selector.section.leaderboard': 'Leaderboard:',

	'editor.selector.leaderboard.upgrade': 'Leaderboard feature is not available for your current plan.',
}
