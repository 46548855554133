export const en = {
	'modal.trigger.project_creation': 'Add a workspace',
	'modal.header.project_creation': 'New workspace',
	'modal.header.project_creating': 'Creating your workspace...',
	'modal.project_creation.name': 'Type a name... ',
	'modal.project_creation.description': 'Description',
	'modal.project_creation.avatar': 'Workspace image',
	'modal.project_creation.error.missing_project_name': 'Please indicate the name of the workspace.',
	'modal.project_creation.error.project_name_already_exists':
		'This workspace already exists. Please enter another name.',
	'modal.project_deletion.header': 'Delete workspace',
	'modal.project_deletion.confirmation.header': 'Are you sure?',
	'modal.project_deletion.confirmation.instructions':
		'<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div>To confirm, enter the name of the workspace:</div>',
	'modal.project_deletion.pending': 'The workspace is being deleted...',

	'modal.project_update.header': 'Update workspace info',
	'modal.project_update.pending': 'The workspace is being updated...',

	// Organization
	'modal.trigger.organization_creation': 'Create an organization',
	'modal.header.organization_creation': 'Create a new organization',
	'modal.header.organization_creation_button': 'New organization',
	'modal.header.license_activation': 'Activate license',
	'modal.header.organization_creating': 'The organization is being created...',
	'modal.header.license_activating': 'The license is being activating...',
	'modal.organization_creation.name': 'Type a name...',
	'modal.organization_creation.slug': 'Type an unique ID...',
	'modal.organization_creation.error.missing_organization_name': 'Please indicate the name of the organization.',
	'modal.organization_creation.error.missing_organization_slug': 'Please indicate the ID of the organization.',
	'modal.organization_leave.title': 'Leave this organization?',
	'modal.organization_kick.title': 'Kick this member?',
	'modal.organization_change_role.title': 'Change member role?',
	'modal.organization_deletion.header': 'Delete organization',
	'modal.organization_deletion.confirmation.instructions':
		'<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div>To confirm, enter the name of the organization:</div>',
	'modal.organization_deletion.pending': 'Organization is being deleted...',

	'modal.organization.creation_description.header':
		'A new organization will be linked to a new <b>monthly subscription.</b>',
	'modal.organization.creation_description.content':
		'It is different from a <b>WORKSPACE</b>.<br></br>To Create a <b>WORKSPACE</b>, select an organization first.',
	'modal.organization.creation_description.support':
		'If you can not access workspaces page, upgrade your subscription or contact support.',

	'modal.organization_accept.forbidden.header': 'Accepting invitation...',
	'modal.organization_accept.forbidden.title': 'The request to join the organization was denied',
	'modal.organization_accept.forbidden.description':
		'Organization has reached the maximum number of members.<br></br>Notice of your request has been sent to the admins of the organization.',

	// Delete Story
	'modal.snackeet_deletion.name': 'Type the name of the story...',
	'modal.snackeet_deletion.header': 'Delete story',
	'modal.snackeet_deletion.confirmation.header': 'Are you sure?',
	'modal.snackeet_deletion.confirmation.instructions':
		'<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div>To confirm, enter the name of the story:</div>',
	'modal.snackeet_deletion.pending': 'Story is being deleted...',

	// Story Folder Selection
	'modal.story_folder_selection.description': 'Assign a folder to story <b>{ storyName }</b>',
	'modal.story_folder_selection.button.create': 'Create a folder',
	'modal.story_folder_selection.input.label': 'New folder name',
	'modal.story_folder_selection.empty.title': `You don't have any folders`,
	'modal.story_folder_selection.empty.instruction': `Create some to organize your stories within a workspace`,
	'modal.story_folder_creation.header': `New folder`,
	'modal.story_folder_creation.input': `Enter the name of the folder`,
	'modal.story_folder_deletion.title': `Delete folder`,
	'modal.story_folder_deletion.question': `Are you sure you want to delete <b>{ folderName }</b> ?`,
	'modal.story_folder_deletion.warning': `All stories in this folder will be moved to the default folder.`,
	'modal.story_folder_edition.title': 'Edit folder',
	'modal.story_folder_edition.input.label': 'Folder name',
	'modal.story_folder_edition.button.edit': 'Update',

	// Delete Widget Banner
	'modal.widget_banner_deletion.name': 'Type the name of the widget banner...',
	'modal.widget_banner_deletion.header': 'Delete widget banner',
	'modal.widget_banner_deletion.confirmation.header': 'Are you sure?',
	'modal.widget_banner_deletion.confirmation.instructions':
		'<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div>To confirm, enter the name of the widget:</div>',
	'modal.widget_banner_deletion.pending': 'Widget banner is being deleted...',

	// Embed code Widget Banner
	'modal.widget_banner_code.header': 'Embed code',
	'modal.widget_banner_code.instructions':
		'Paste this code into your website between "<body>...</body>" tags, where you want the StoryWidget show up in your content.',
	'modal.widget_banner_code.button.copy': 'Copy this code',

	// EditWidgetStoryModal
	'modal.widget_banner.story_edit.header': 'Edit Story',
	'modal.widget_banner.story_edit.display_name': 'Name',
	'modal.widget_banner.story_edit.caption': 'Caption',
	'modal.widget_banner.story_edit.button.preview': 'Preview Story',
	'modal.widget_banner.story_edit.button.editor': 'Open editor',
	'modal.widget_banner.story_edit.button.change_image': 'Change image',
	'modal.widget_banner.story_edit.button.change_video': 'Change video',

	// AddWidgetStoryModal
	'modal.widget_banner.story_add.header': 'Manage Stories',
	'modal.widget_banner.story_add.description': 'Select story from <b>{projectName}</b>',
	'modal.widget_banner.story_add.no_stories': 'You currently have no Stories to connect with the Banner.',

	// CreateBannerModal
	'modal.widget_banner.create_widget.title': 'Create Web Stories Banner',
	'modal.widget_banner.create_widget.select_story.title': 'Select a Webstory type',
	'modal.widget_banner.create_widget.select_story.description':
		'Please note that a banner can <b>only<b/> feature <b>one type</b> of webstory, either <b>Advanced</b> or <b>Simple</b>.',
	'modal.widget_banner.create_widget.select_design.title': 'Select a banner design',
	'modal.widget_banner.create_widget.select_design.description':
		'Choose between bubbles and cards to create a visually appealing banner for your website.',
	'modal.widget_banner.create_widget.select_design.caption.bubbles': 'Round or Square - ratio 1:1',
	'modal.widget_banner.create_widget.select_design.caption.cards': 'Vertical or horizontal Card - Custom ratio',

	// DeleteFile
	'modal.file_deletion.confirmation.header.image': 'Delete Image',
	'modal.file_deletion.confirmation.header.video': 'Delete video',
	'modal.file_deletion.confirmation.sub_header': 'Are you sure?',
	'modal.file_deletion.confirmation.instructions':
		'Be careful, this is an <b>irreversible operation</b>.<br></br><br></br>',

	// SettingsUser
	'modal.settings_user.client_deletion.header': `🗑 Client deletion`,
	'modal.settings_user.client_deletion.incorrect_name': `The name entered does not match the name of the customer to be deleted.`,
	'modal.settings_user.client_deletion.info': `<div>Are you sure you want to delete<b style='margin: 0 5px'>{name}</b> ?</div><div>⚠️ This operation is irreversible!</div><div>👉 Enter the client's name and confirm.</div>`,
	'modal.settings_user.client_deletion.client_name': `Client name`,

	'modal.settings_user.user_creation.client_name.header': `👤 User creation`,
	'modal.settings_user.user_creation.client_name.invalid_mail': `Please specify a valid email address.`,

	'modal.settings_user.user_deletion.header': `👤 User deletion`,
	'modal.settings_user.user_creation.content': `Are you sure you want to delete user {first_name} {last_name} ?`,

	'modal.settings_user.client_creation.header': `🎉 Client creation`,
	'modal.settings_user.client_creation.client_name': `Client's name`,

	// Header confirmation
	'modals.header_confirmation.publish.header': 'Publish your changes',
	'modals.header_confirmation.activate.header': 'Enable story',
	'modals.header_confirmation.deactive.header': 'Disable story',
	'modals.header_confirmation.confirmation.instructions':
		'The changes you made <b>will go live and visible to all you users</b>.<br></br><br></br>',

	// Snackeet modals
	'modals.update_snackeet.title': 'Update Story Name',
	'modals.update_snackeet.language': 'Change Story language',
	'modals.update_snackeet.language.warning':
		'Caution! When changing the language, your custom text preferences for buttons and error messages will be <b>erased and restored to default</b>',

	'modals.create_snackeet.steps.story_type': `Wisely select  your story’s type`,
	'modals.create_snackeet.steps.story_details': 'Enter Story Details',
	'modals.create_snackeet.steps.from_template': 'Use templates',
	'modals.create_snackeet.steps.from_scratch': 'Create from scratch',
	'modals.create_snackeet.steps.choose_one': 'Choose a template below or',
	'modals.create_snackeet.steps.or': 'or',
	'modals.create_snackeet.steps.create_with': 'Or Choose a template and',
	'modals.create_snackeet.steps.from_instagram': 'Import from Instagram',

	'modals.create_snackeet.snackeet_story.title': '<b>Advanced</b> Web Story',
	'modals.create_snackeet.snackeet_story.description': 'Powered by <b>Snackeet</b> - Cookie-less Web App',
	'modals.create_snackeet.amp_story.title': '<b>Light</b> Web Story',
	'modals.create_snackeet.amp_story.description': 'Powered by <b>Google AMP</b>',

	'modals.create_snackeet.description_header.classic':
		'<ul><li><b>Conditionnal logic</b></li><li><b>Highly customizable experience</b></li><li><b>Cookie-Less</b></li></ul>',
	'modals.create_snackeet.description_list.classic':
		'<ul><li>Upload/Download files</li><li>Anwser by video/audio/photo</li><li>Forms</li><li>Buttons</li><li>Ratings</li><li>Advanced Quiz and Poll</li><li>Gamification</li></ul>',
	'modals.create_snackeet.description_header.single_page': 'All in classic +',
	'modals.create_snackeet.description_list.single_page': '<ul><li>Only one page and a confirmation screen</li></ul>',
	'modals.create_snackeet.description_header.amp_story': 'Behaviour is very similiar to Instagram stories',
	'modals.create_snackeet.description_list.amp_story':
		'<ul><li>Only linear navigation</li><li>Quiz or poll</li><li>Fast loading</li><li>Basic forms</li><li>Monetizable (Google Adsense...)</li></ul>',
	'modals.create_snackeet.description.amp_story_learn_more':
		'What is the difference between Snackeet and Google webstories (?)',

	'modals.create_snackeet.story_features.advanced_form': 'Advanced form',
	'modals.create_snackeet.story_features.landing_page': 'Landing Page',
	'modals.create_snackeet.story_features.gaming': 'Gamified experience',
	'modals.create_snackeet.story_features.photo_contest': 'Photo contest',
	'modals.create_snackeet.story_features.fortune_wheel': 'Fortune wheel',
	'modals.create_snackeet.story_features.microsite': 'Microsite',
	'modals.create_snackeet.story_features.collect_feedback': 'Collect feedback',

	'modals.create_snackeet.story_features.interactive_video': 'Interactive video',
	'modals.create_snackeet.story_features.bio_link': 'Landing page & Bio link',
	'modals.create_snackeet.story_features.contests': 'Contests',
	'modals.create_snackeet.story_features.quiz_survey': 'Quiz & Survey',
	'modals.create_snackeet.story_features.acquisition_form': 'Acquisition form',
	'modals.create_snackeet.story_features.review_collection': 'Review collection',

	'modals.create_snackeet.story_features.video_display': 'Video display',
	'modals.create_snackeet.story_features.product_demo': 'Product discovery',
	'modals.create_snackeet.story_features.articles': 'News articles',
	'modals.create_snackeet.story_features.display_content': 'Display content',
	'modals.create_snackeet.story_features.simple_engagement': 'Basic engagement',
	'modals.create_snackeet.story_features.custom_scenarios': 'Custom scenarios',

	'modals.global_settings.reset_warning': 'This will erase all of your changes.',

	// Create Page
	'modals.create_page.title': `New page`,
	'modals.create_page.page_type': `What type of reponse do you want?`,
	'modals.create_page.page_type.blank': 'No Answer',
	'modals.create_page.page_type.multiple_choice': 'Multiple choice',
	'modals.create_page.page_type.form': 'Form',
	'modals.create_page.page_type.rating': 'NPS/Rating',
	'modals.create_page.describe.blank': 'Thought for pure visual content',
	'modals.create_page.describe.multiple_choice': 'Add classic buttons or action buttons (link / file)',
	'modals.create_page.describe.form': `Add form fields`,
	'modals.create_page.describe.rating': `Add a rating modal`,
	'modals.create_page.button_type': `What type of button do you want?`,
	'modals.create_page.button_type.answer': 'Classic',
	'modals.create_page.button_type.action': 'Link/File',
	'modals.create_page.describe.button_type.answer': 'Add answers',
	'modals.create_page.describe.button_type.action': 'Add link/file',
	'modals.create_page.rating_type': `What type of rating do you want ?`,
	'modals.create_page.helper.change_page_type': 'You can always change the type of response later on',
	'modals.create_page.change_type.title': `Change response type`,
	'modals.create_page.change_type.page_type': `Choose your new response type`,

	'modals.create_page.step_title.new_page': 'Create a new page',
	'modals.create_page.step_title.template': 'Use templates',
	'modals.create_page.step_title.interaction': 'Configure page interaction',

	'modals.change_response.step_title.page_type': 'What type of response do you want?',
	'modals.change_response.step_title.interaction': 'Configure page response',

	'modals.interaction_settings.multiple_choice': 'Configure page buttons',
	'modals.interaction_settings.form': 'Configure page form',

	// Snackeet Page
	'modals.delete_page.title': `Delete page`,
	'modals.delete_page.header': `Are you sure you want to delete the page <b>{name}</b> ?`,
	'modals.duplicate_page.title': `Duplicate page`,
	'modals.duplicate_page.header': `Are you sure you want to duplicate the page <b>{name}</b> ?`,
	'modals.switch_page_type.header': `Change page type`,
	'modals.switch_button_type.header': `Change button type`,
	'modals.switch_page_type.sub_header': `<b>Are you sure you want to change the response type ?</b>`,
	'modals.switch_button_type.sub_header': `<b>Are you sure you want to change the button type ?</b>`,

	// Recorder Options
	'modals.recorder_options.title': `Recording options`,

	// Hotspots
	'modals.hotspot.redirection.title': 'Redirection',
	'modals.hotspot.redirection.message': `The user will be redirected to the chosen page after the page's timer is over or if he taps to go to next page.`,
	'modals.hotspot.redirection_none.message': `The user WILL NOT be automatically redirected to another page.`,
	'modals.hotspot.project.intvite_member.title': 'Invite Members enter workspace',
	'modals.hotspot.project.intvite_member.message': 'Coming soon',
	'modals.hotspot.tag.title': 'Product Tags',
	'modals.hotspot.tag.message':
		'Tags allow you to highlight your content or product and to redirect your users to the product page for exemple',
	'modals.hotspot.calendar.title': 'Calendar',
	'modals.hotspot.calendar.message':
		'Allow your prospects to book a meeting directly from a story and capture more leads.',
	'modals.hotspot.subtitle.title': 'Subtitles',
	'modals.hotspot.subtitle.message': 'You can automatically create subtitles for the videos you upload.',
	'modals.hotspot.whitelist.title': 'Whitelist',
	'modals.hotspot.whitelist.message':
		'Add a domain name to display the widget on a website’s pages. You can add up to 10 domains.',
	'modals.hotspot.whitelist.footer': 'For example : snackeet.com',
	'modals.hotspot.widget_settings.title': 'Widget Editor',
	'modals.hotspot.widget_settings.message':
		'Decide what the widget should look like. You can always modify its appearance after its integration. ',
	'modals.hotspot.start_page.title': 'Starting Page',
	'modals.hotspot.start_page.message': 'On the first page no other interaction than the start button is possible.',
	'modals.hotspot.start_page.information':
		'On the first page no other interaction than the start button is possible. If you want to add an interaction create a new page by clicking on {icon}.',
	'modals.hotspot.start_end_page.actions': 'Cannot be duplicated or deleted, please create a new page.',
	'modals.hotspot.end_page.title': 'Ending Page',
	'modals.hotspot.end_page.message':
		'Depending on the type of story you chose, the ending page allows your users’ score (quizzes) or comparative results (surveys).',
	'modals.hotspot.end_page.information':
		'On the last page, only the restart button and social media sharing (available in the templates) are possible as an interaction. Do not hesitate to look at the models for ideas. You can still use the toolbar to customize your end page',
	'modals.hotspot.url_variables.title': 'URL Variables',
	'modals.hotspot.url_variables.message': `By passing value in URL's variables, Snackeet can fill up these variables in text or can prefill form.`,
	'modals.hotspot.game_required.description': `The user is forced to interact with the game in order to continue`,
	'modals.hotspot.game.lose_option.message': `*If this option is activated the user who will come across this price will be redirected to the losing window`,
	'modals.hotspot.game.replayable.description': `With each new session the wheel resets (example: When a user starts a story again)`,
	'modals.hotspot.game.replayable.description': `With each new session the wheel resets (example: When a user starts a story again)`,
	'modals.hotspot.block_cta.tooltip.description':
		'When enabled, an intermediate window will appear when the element is clicked, if disabled the redirection will be direct',
	'modals.hotspot.block_cta.redirect.tooltip.description': 'When enabled, the redirection will be direct',

	// Consent Modal
	'modals.consent.title': 'Privacy Policy',
	'modals.consent.redirection': 'Visit privacy policy',

	// Variables
	'modals.variables.header': 'Choose a { count, plural, one { variable } other { variables }}',
	'modals.variables.default.title': 'DEFAULT',
	'modals.variables.default.subtitle': 'Variables built by default',
	'modals.variables.leaderboard.subtitle': 'Leaderboard Variables',
	'modals.variables.custom.title': 'Custom Variables',
	'modals.variables.custom.subtitle': `Create and use your own variables. Spaces and special characters aren't supported, apart from "-" and "_"`,
	'modals.variables.custom.create': `Create a variable`,
	'modals.variables.assigned': 'Assigned',
	'modals.variables.assigned_here': 'Assigned here',
	'modals.variables.tooltip.header': 'Variable is assigned into',
	'modals.variables.tooltip.page_name': 'Page name: ',
	'modals.variables.tooltip.page_type': 'Page type: ',
	'modals.variables.tooltip.field': 'Field: ',
	'modals.variables.allowType.head': 'Only variables of type',
	'modals.variables.allowType.foot': 'are permitted for assignment',

	'modals.variables.create_variable.name': 'Variable name',
	'modals.variables.create_variable.type': 'Select variable type',
	'modals.variables.create_variable.create_btn': 'Create Variable',

	'modals.variables.description.text': 'Used for text value from text input, button answer,...',
	'modals.variables.description.number':
		'Used for value number from number input, classic rating and for calcul in advanced logic.',
	'modals.variables.description.date': 'Used for date value from date picker and advanced logic configuation.',
	'modals.variables.description.boolean':
		'Used to provide TRUE and FALSE values and can be assigned to checkbox input.',

	// Story Slug
	'modals.story_slug.header': `Edit the URL slug`,
	'modals.story_slug.input.label': 'Custom Link',
	'modals.story_slug.input.error': 'This URL already exists or is invalid',

	// Story Custom Domain
	'modals.story_custom_domain.header': `Custom domain setup`,
	'modals.story_custom_domain.input.label': `Select a custom domain`,
	'modals.story_custom_domain.button.add': `Manage domains`,
	'modals.story_custom_domain.no_results.instruction': `You need to <b>create</b> a custom domain first or <b>assign a workspace</b> to an existing domain.`,
	'modals.story_custom_domain.no_results.instruction.agent': `<div>No custom domain has been configured.<br><br/>Please contact your administrator for more information.</div>`,

	'modals.custom_domain.limit_reached.title': 'Domains limit reached',
	'modals.custom_domain.limit_reached.description': `You have reached <b>the limit {count} of {count, plural, one { domain } other { domains}}</b> for your subscription.<br></br><br></br>If you want to use a new domain, you can either upgrade your offer or remove all custom domains from another story.`,

	// Create Custom Domain
	'modals.create_custom_domain.header': 'Create a new custom domain',
	'modals.create_custom_domain.stepper.label.specify_domain': 'Domain Selection',
	'modals.create_custom_domain.stepper.label.verify_domain': 'DNS Setup',
	'modals.create_custom_domain.stepper.label.select_projects': 'Workspace Selection',
	'modals.create_custom_domain.step.1.title': 'Enter your subdomain',
	'modals.create_custom_domain.step.1.description': `For example:  `,
	'modals.create_custom_domain.step.2.title': 'Set up your DNS records',
	'modals.create_custom_domain.step.2.description.a':
		'In your DNS provider, create a <b>CNAME</b> record for your custom domain',
	'modals.create_custom_domain.step.2.description.b': 'Point the record to',
	'modals.create_custom_domain.step.2.redirection_status': 'Redirection Status',
	'modals.create_custom_domain.alert.redirection_status':
		'<b>Click on verify</b> once you have added the CNAME record.<br></br>It might take up to <i>24 hours</i> for the changes to take effect.',
	'modals.create_custom_domain.access_admin':
		'This domain will be available for all users of the workspaces your will select below:',
	'modals.create_custom_domain.access_agent': 'This domain will be available for all users of this workplace.',
	'modals.create_custom_domain.warning_agent':
		'If you need to make it available in several workspaces, please get in touch with your admin.',
	'modals.create_custom_domain.article_link': 'Learn more about adding a custom domain name',
	'modals.create_custom_domain.invalid':
		'This domain name is invalid.<br></br>Make sure it is of type <b>story.customer.com</b>',
	'modals.create_custom_domain.unavailable': 'This domain name is already being used',

	// Import Modal
	'modals.import.header': 'Media file import',
	'modals.import.status.uploading': 'Uploading & compressing file...',
	'modals.import.status.verifying': 'Verifying...',
	'modals.import.status.done': 'The file is ready to use',
	'modals.import.status.error': 'Upload Error',
	'modals.import.status.uploading.description':
		'Once a file is imported, it will be available in the gallery of all the Stories in this workspace.',
	'modals.import.status.done.description': 'It is available in the gallery of all the Stories in this workspace.',
	'modals.import.overtime': 'Process may take longer than expected',

	// List Settings Modal
	'modals.list_settings.header': 'Field list settings',
	'modal.list_settings.instruction': 'Please choose a column to configure your list',

	// Integration Modal
	'modals.integration.header': 'Integration with {name} Unavailable',
	'modals.integration.sub_header': 'Unable to connect with {name}!',
	'modals.integration.description': `Variable <b>email</b> must be assigned to connect with {name}`,
	'modals.integration.intruction.addVariable': 'How to assign variables in your Story',
	'modals.integration.intruction.connection': 'How to connect with {name}',

	// Widget Icon
	'modals.widget_icon.header': 'Widget icon',
	'modals.widget_icon.media_type.title': 'Miniature',
	'modals.widget_icon.caption': 'Inner Text (Optional)',
	'modals.widget_icon.caption.placeholder': 'Click me !',

	// BackgroundModal
	'modals.background_helper.text': 'Choose your background',
	'modals.background_helper.close_text': "Don't show this again",

	// Story Errors Popover
	'modals.story_errors.title': 'Errors List',
	'modals.story_errors.message': 'You need to fix all ERRORS issues before publishing',

	// Condition Page
	'modals.condition.header': 'Page condition settings',
	'modals.condition.error.field': '*Value cannot be empty!',
	'modals.condition.error.group': '*Group cannot be empty!',

	'modals.condition.translations.fields.title': 'Fields',
	'modals.condition.translations.operators.title': 'Operators',
	'modals.condition.translations.value.title': 'Value',
	'modals.condition.translations.removeRule.label': 'x',
	'modals.condition.translations.removeRule.title': 'Remove Rule',
	'modals.condition.translations.removeGroup.label': 'x',
	'modals.condition.translations.removeGroup.title': 'Remove group',
	'modals.condition.translations.addRule.label': 'Add Condition',
	'modals.condition.translations.addRule.title': 'Add Rule',
	'modals.condition.translations.addGroup.label': 'Group ()',
	'modals.condition.translations.addGroup.title': 'Add Group',
	'modals.condition.translations.combinators.title': 'Combinators',
	'modals.condition.translations.notToggle.label': 'Not',
	'modals.condition.translations.notToggle.title': 'Invert this group',
	'modals.condition.translations.cloneRule.label': '⧉',
	'modals.condition.translations.cloneRule.title': 'Clone Rule',
	'modals.condition.translations.cloneRuleGroup.label': '⧉',
	'modals.condition.translations.cloneRuleGroup.title': 'Clone Group',

	// Redirect to AppSumo
	'modal.subscription.redirect_appsumo.title': 'Change your plan tier',
	'modal.subscription.redirect_appsumo.instruction_1': 'All changes of tier must be made on AppSumo',
	'modal.subscription.redirect_appsumo.instruction_2': 'Redirect to AppSumo page to apply changes',
	'modal.subscription.redirect_appsumo.instruction_downgrade':
		'Before downgrading in AppSumo please ensure you <b>remove</b> team mates in organization first.<br></br><br></br>You need to remove <b>{members}</b> team mates.',
	'modal.subscription.redirect_appsumo.button_cancel': 'Cancel',
	'modal.subscription.redirect_appsumo.button_confirm': 'Redirect',

	'modal.settings_user.account.delete_redirection':
		'You have an organization activated with AppSumo purchasing.<br></br>All data will be deleted and you will lose benefits if your purchase.',

	// Webhook
	'modals.webhook.title': 'Edit your Webhook',
	'modals.webhook.eventType.snackeet_interaction': `Webhook for user’s responses`,
	'modals.webhook.eventType.snackeet_interaction.description': `This webhook is triggered when a user answers and fill a form. It send all data collected.`,
	'modals.webhook.eventType.file_uploaded': `Webhook for files uploaded`,
	'modals.webhook.eventType.file_uploaded.description': `This webhook is triggered <b>ONLY</b> when a file is uploaded by a user. It allows you to store only files.`,
	'modals.webhook.url_header': `Wehook's URL`,
	'modals.webhook.url_placeholder': 'Enter your URL',
	'modals.webhook.headers_title': `Set your secret header (Optional)`,
	'modals.webhook.headers_name': 'Header name',
	'modals.webhook.headers_value': 'Header value',

	'modals.webhook.url_error.empty': 'URL cannot be empty',
	'modals.webhook.url_error.invalid': 'Please enter a valid URL',

	// License Activation
	'modals.license.header': 'Choose an organization in the list below to replace its current plan by AppSumo plan',
	'modals.license.no_organization':
		'No organizations available to apply AppSumo plan tier. Please create one to activate this License.',

	// Image Selector
	'modals.images.header': 'Choose an image',
	'modals.images.type_import': 'Library',
	'modals.images.type_link': 'Link',
	'modals.images.type_pexels': 'Pexels',
	'modals.images.type_gif': 'GIF',
	'modals.images.type_sticker': 'Sticker',
	'modals.images.type_pixabay': 'Pixabay',
	'modals.images.type_recently_used': 'Recent',
	'modals.images.type_illustrations': 'Illustrations',
	'modals.images.type_instagram': 'Instagram',
	'modals.images.type_shopify': 'Shopify',
	'modals.images.button_import': 'Import new image',
	'modals.images.link.invalid': 'Please enter a valid URL and secure (https).',
	'modals.images.import.dropzone': 'Drop your images here to import',
	'modals.images.import.instruction': 'or drag and drop',

	// Video Selector
	'modals.videos.header': 'Choose a video',
	'modals.videos.type_import': 'Library',
	'modals.videos.type_link': 'Link',
	'modals.videos.type_pexels': 'Pexels',
	'modals.videos.type_pixabay': 'Pixabay',
	'modals.videos.type_recently_used': 'Recent',
	'modals.videos.type_instagram': 'Instagram',
	'modals.videos.type_story_videos': 'Story Videos',
	'modals.videos.button_import': 'Import new video',
	'modals.videos.link.invalid': `Invalid or incompatible video URL. Please ensure you've entered a valid link. If the problem persists, contact our support team.`,
	'modals.videos.import.dropzone': 'Drop your videos here to import',

	// Editor Dropzone
	'modals.editor_import.instructions': 'Drop files to import',
	'modals.editor_import.instructions.images': 'Images',
	'modals.editor_import.instructions.videos': 'Videos',

	// Subtitles Editor
	'modals.subtitles.unsaved.title': 'Your subtitles are unsaved',
	'modals.subtitles.unsaved.description': 'Would you like to leave without saving your subtitles?',
	'modals.subtitles.unsaved.cancel': 'No',
	'modals.subtitles.unsaved.confirm': 'Yes',

	'modals.subtitles.saved.title': 'Save your subtitles',
	'modals.subtitles.saved.description':
		'Would you like to save your subtitles?<br></br><br></br>These changes will be apply into all stories which are using this video.',
	'modals.subtitles.saved.cancel': 'Cancel',
	'modals.subtitles.saved.confirm': 'Confirm',

	// Story Settings
	'modals.story_settings.refresh_user.instructions':
		'Enabling this option may <b>significantly increase</b> the number of users.',
	'modals.story_settings.delete_all_users.header': 'Users deletion',
	'modals.story_settings.delete_all_users.instructions': `Careful, this is an <b>irreversible operation</b>.<br></br><br></br>Do you want to delete <b>all the users</b> of this Story ?`,

	// Story Favicon
	'modals.story_favicon.title': '{ count, plural, =0 {Add} other {Select}} a Favicon ',
	'modals.story_favicon.remove_button': 'Remove Favicon',

	// Widget Loader
	'modals.widget_loader.title': '{ count, plural, =0 {Add} other {Select}} a Loader Image ',
	'modals.widget_loader.button.default': `Set as default`,
	'modals.widget_loader.label.default': `Default`,
	'modals.widget_loader.tooltip.default': `The default loader is automatically used in all new created widgets`,

	'modals.widget_loader.confirm_default.firstLine': `Do you want to set this image as the default loader ?`,
	'modals.widget_loader.confirm_default.secondLine': `It will update all the widgets using the default loader.`,

	// WHITELIST / BLACKLIST Confirmation
	'modals.whitelist_confirmation.title': 'Display on all pages',
	'modals.whitelist_confirmation.text':
		'By displaying on all pages, the current list of authorized pages will be <b>removed</b>.<br></br><br></br>Do you want to proceed?',
	'modals.blacklist_confirmation.title': 'Erase Exceptions',
	'modals.blacklist_confirmation.text':
		'The current list of exceptions will be <b>removed</b>.<br></br><br></br>Do you want to proceed?',

	// Single Page Overlay
	'modals.page_overlay.title': 'Final Screen',
	'modals.page_overlay.description': 'Customize the message displayed after a user submits an answer.',
	'modals.page_overlay.section.message': 'Text',
	'modals.page_overlay.section.link_button': 'Link button',
	'modals.page_overlay.section.link_button.tooltip': 'Redirect your users to another website.',
	'modals.page_overlay.section.restart': 'Restart button',
	'modals.page_overlay.section.restart.tooltip': 'Allow users to restart and resubmit.',

	'modals.page_overlay.text.input': 'Confirmation Text',
	'modals.page_overlay.text.input.placeholder': 'Add custom text',
	'modals.page_overlay.link_button.input': 'Button text',
	'modals.page_overlay.link_button.input.placeholder': 'Visit our website',
	'modals.page_overlay.link_button.input2': 'URL',

	// Form Settings
	'modals.form.field_remove.title': 'Remove form field',
	'modals.form.field_remove.sub_title': 'Do you want to remove this field?',

	// GDPR Settings
	'modals.form.gdpr_settings.title': 'GDPR Consent Settings',
	'modals.form.gdpr_deactivate.title': 'Deactivate GDPR Consent',
	'modals.form.gdpr_deactivate.sub_title': 'Do you want to deactivate GDPR Consent?',

	// Save As Template
	'modals.save_template.title': 'Save as template',
	'modals.save_template.page.title': 'Save <b>page</b> as template',
	'modals.save_template.story.title': 'Save <b>story</b> as template',
	'modals.save_template.name': 'Template name:',
	'modals.save_template.header.agent': 'This page template will be available for all users of this workplace.',
	'modals.save_template.warning.agent':
		'If you need to make it available in several workspaces, please get in touch with your admin.',
	'modals.save_template.info.agent':
		'If you need to modify this page template, you must first change the original page and save it as a template. You can then delete the outdated template.',
	'modals.save_template.header.admin':
		'This page template will be available for all users of the workspaces your will select below :',
	'modals.save_template.info.admin':
		'If you need to modify this page template, you must first change the original page and save it as a template. You can then delete the outdated template.',

	'modals.save_template.story.header.agent': 'This story template will be available for all users of this workplace.',
	'modals.save_template.story.warning.agent':
		'If you need to make it available in several workspaces, please get in touch with your admin.',
	'modals.save_template.story.info.agent':
		'If you need to modify this story template, you must first change the original story and save it as a template. You can then delete the outdated template.',
	'modals.save_template.story.header.admin':
		'This story template will be available for all users of the workspaces your will select below:',
	'modals.save_template.story.info.admin':
		'If you need to modify this story template, you must first change the original story and save it as a template. You can then delete the outdated template.',

	// Templates
	'modals.templates.tabs.default': 'Default Templates',
	'modals.templates.tabs.current_pages': 'Story Current Pages',
	'modals.templates.tabs.current_pages_select': 'Story Current Pages To Copy',
	'modals.templates.tabs.custom': 'Your Templates',
	'modals.templates.delete.title': 'Delete Template',
	'modals.templates.button.choose': 'Choose this template',
	'modals.templates.button.select': 'Select',
	'modals.templates.button.manage': 'Manage',
	'modals.templates.instruction':
		'This template will be available for all users of the workspaces your will select below :',
	'modals.templates.page.empty': 'You currently have no templates for this type of page.',
	'modals.templates.story.empty': 'You currently have no templates for this type of story.',
	'modals.templates.attachment.empty': 'You currently have no templates for this type of pop up.',

	// Verification email
	'modals.verification_email.title': 'Last step: Verify your email',
	'modals.verification_email.header_1':
		'As part of our security standard, verify customer account’s email address is mandatory.',
	'modals.verification_email.header_2': `A confirmation link has been sent by mail to <b>{email}</b>.`,
	'modals.verification_email.header_3': `Refresh your page after clicking on the verification link.`,
	'modals.verification_email.delay_message': `Resend verification link available after <b>{counter}</b> seconds`,
	'modals.verification_email.resend_button': 'Resend verification link',
	'modals.verification_email.instruction': `If you need help, please contact our support team by chat or at <b>hello@snackeet.com</b>.`,

	'verification_email.success': 'Sending verification link success',
	'verification_email.error': 'Sending verification link error',

	// Brands
	'modals.create_brand.title': 'Create a new brand',
	'modals.create_brand.name_label': 'Brand name',
	'modals.create_brand.name_placeholder': 'Enter brand name',
	'modals.create_brand.access_admin':
		'This brand will be available for all users of the workspaces your will select below:',
	'modals.create_brand.access_agent': 'This brand will be available for all users of this workplace.',
	'modals.create_brand.warning_agent':
		'If you need to make it available in several workspaces, please get in touch with your admin.',

	'modals.delete_brand.modal_title': 'Delete brand',
	'modals.delete_brand.header': 'Are you sure?',
	'modals.delete_brand.instruction': `<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div>To confirm, enter the name of the brand:</div>`,
	'modals.delete_brand.placeholder': 'Type the name of the brand',

	// leaderboards
	'modals.create_leaderboard.title': 'Create a new leaderboard',
	'modals.create_leaderboard.name_label': 'Leaderboard name',
	'modals.create_leaderboard.name_placeholder': 'Enter leaderboard name',

	'modals.delete_leaderboard.title': 'Delete leaderboard',
	'modals.delete_leaderboard.header': 'Are you sure?',
	'modals.delete_leaderboard.instruction': `<div>Be careful, this is an <b>irreversible operation</b>.</div><br></br><div>To confirm, enter the name of the leaderboard:</div>`,
	'modals.delete_leaderboard.placeholder': 'Type the name of the leaderboard',

	// Fonts
	'modals.delete_fonts.title': 'Delete { count, plural, one { font } other { fonts }}',
	'modals.delete_fonts.header':
		'Are you sure you want to delete the { count, plural, one { font } other { fonts }} ?',
	'modals.delete_fonts.instruction': `Deleting { count, plural, one { this font } other { those fonts }} will affect all the stories that use { count, plural, one { it } other { them }}`,

	// Export Users Data
	'modals.export_users_data.title': 'Export Initiated',
	'modals.export_users_data.description': `Your data is currently being exported, and you will receive an email notification at <b>{ email }</b> once the process is complete.<br></br><br></br>It might take up to 15 min.<br></br><br></br>Please note that the download link will remain active for 14 days.<br></br><br></br>If you want to download it now, don’t close this window.`,
	'modals.export_users_data.file_downloaded': 'File Downloaded',

	// AMP Form
	'modals.amp_form.modal.create': 'Create a form attachment',
	'modals.amp_form.modal.template': 'Choose a template',
	'modals.amp_form.modal.settings': 'Form settings',

	// AMP Attachment
	'modals.amp_attachment.modal.create': 'Create a pop up',
	'modals.amp_attachment.modal.template': 'Choose a template',
	'modals.amp_attachment.modal.settings': 'Pop up settings',
	'modals.amp_attachment.modal.save_only': 'Save and Close',
	'modals.amp_attachment.modal.save_as_template': 'Save as template',

	// Stale Story
	'modals.stale_story.title': 'Obsolete version',
	'modals.stale_story.description': 'A more recent version of the story exists.<br></br>Please refresh.',
	'modals.stale_story.button': 'Refresh',

	// Export Pages Modal
	'modals.story_export.title': 'Story export',
	'modals.story_export.instructions': 'Choose which pages to export as image.',

	// Element Selector Drawer
	'modals.element_selector_drawer.title': 'Add new element',
	'modals.element_selector_drawer.change_interaction': 'Change current interaction',
	'modals.element_selector_drawer.description':
		'Pages support a single interaction at a time. Confirm the change to the current interaction?',

	// Create Page
	'modals.create_page.single_to_classic_page':
		'Adding a new page will create a mandatory <b>End Page</b>.<br></br><br></br>End page has specific limitations to improve UX',
	'modals.create_page.add_page_to_single_page': 'Add a new page',

	// Delete Page
	'modals.delete_page.ending_page_title': `Delete the ending page`,
	'modals.delete_page.ending_page_instruction': `Switch from <b>Advanced</b> to <b>One Page</b>.`,
}
