export const fr = {
	'integrations.filter.title': 'Filtrer par catégories',
	'integrations.filter.all': 'Toutes catégories',
	'integrations.filter.analytics': 'Analyses & rapports',
	'integrations.filter.files': 'Gestion des fichiers',
	'integrations.filter.automation': 'CRM & automatisation du marketing',
	'integrations.filter.webhook': 'Webhooks',

	'integrations.platform.zapier.description': `Zapier vous permet de connecter Snackeet à des milliers d'applications parmi les plus populaires, afin que vous puissiez automatiser votre travail et avoir plus de temps pour ce qui compte le plus, aucun code requis.`,
	'integrations.platform.zapier.button': `Connectez Snackeet à 4000+ apps`,
	'integrations.platform.pabblyConnect.description': `Connectez Snackeet à plus de 800 applications. Sélectionnez simplement l'application et vous êtes prêt à partir. Aucune installation requise!`,
	'integrations.platform.pabblyConnect.button': `Connectez Snackeet à 800+ apps`,

	'integrations.webhook.header': 'Webhook Integrations',
	'integrations.webhook.description':
		'Un webhook est un moyen d’envoyer des données d’une application à une autre en temps réel sur Internet.',
	'integrations.webhook.add_new': 'Ajouter un nouveau webhook',
	'integrations.webhook.instruction': `En savoir plus sur l'intégration du webhook`,
	'integratins.webhook.completed_only': 'Terminé uniquement',
	'integratins.webhook.hotspot.completed_only.title': 'Envoyer uniquement les interactions terminées',
	'integratins.webhook.hotspot.completed_only.message': `Webhook ne se déclenche que lorsque les utilisateurs ont terminé la Story. (Compte terminé en atteignant la page de fin)`,

	'integrations.trackingCode': 'Code de suivi:',

	'integrations.googleAnalytics.title': 'Google Analytics',
	'integrations.googleAnalytics.description':
		'Découvrez comment les gens trouvent et interagissent avec votre Story. Mesurez vos campagnes, améliorez vos conversions et plus encore.',
	'integrations.googleAnalytics.placeholder': 'Code de suivi - ex. UA-XXXXX-Y ou G-XXXXXXXXXX',
	'integrations.googleAnalytics.placeholder_amp': 'Code de suivi - ex. UA-XXXXX-Y',
	'integrations.googleAnalytics.amp_warning':
		'Google AMP ne prend pas encore en charge Google Analytics 4. Ne prend en charge que le code UA-XXXXXXX-X.',

	'integrations.googleTagManager.title': 'Google Tag Manager',
	'integrations.googleTagManager.description': `Ajoutez votre propre code GTM à votre Story Snackeet pour le suivi des conversions, l'analyse du site et plus encore.`,
	'integrations.googleTagManager.placeholder': 'Code de suivi - ex. GTM-XXXXXX',

	'integrations.facebookPixel.title': 'Facebook Pixel',
	'integrations.facebookPixel.description':
		'Ajoutez votre <b>identifiant de pixel Facebook</b> et obtenez toutes les données dont vous avez besoin pour mesurer et optimiser vos campagnes marketing.',
	'integrations.facebookPixel.placeholder': 'Code de suivi - ex. 123456789012345',

	'integrations.googleAdSense.title': 'Google AdSense',
	'integrations.googleAdSense.description':
		'Vous pouvez utiliser AdSense pour diffuser des annonces dans vos Web Stories.',
	'integrations.googleAdSense.data_client.description': 'Exemple: ca-pub-0000000000000000',
	'integrations.googleAdSense.data_slot.description': 'Exemple: 00000000',
	'integrations.google_adsense.error': `<b>data-ad-client</b> et <b>data-ad-slot</b> doivent être renseignés.`,

	'integrations.googleAdManager.title': 'Google Ad Manager',
	'integrations.googleAdManager.data_slot.placeholder': 'Exemple: /30497360/a4a/amp_story_dfp_example',
	'integrations.google_ad_manager.error': `<b>data-slot</b> ne peux pas être vide`,

	'integrations.ads.title': 'Configurer la publicité',
	'integrations.ads.subtitle': `Monétisez vos Webstories en affichant des publicités pendant que l'utilisateur consomme leur contenu.<br></br>Votre story doit avoir au moins <b>8 pages</b> pour afficher des publicités.`,
	'integrations.ads.platform.label': 'Plate-forme publicitaire',
	'integrations.ads.platform.placeholder': 'Choisissez une plateforme',

	'integrations.code.error.empty': 'Impossible de soumettre un code de suivi vide !',
	'integrations.code.error.facebookPixel': 'Identifiant de pixel non valide',
	'integrations.code.error.googleAnalytics': 'Supporte uniquement le code UA',

	'integrations.hubSpot.title': 'HubSpot',
	'integrations.hubSpot.description': `Créez de nouveaux contacts et joignez des réponses dans HubSpot directement à partir d'une Story.`,
	'integrations.connectWith': 'Connecté avec: ',
	'integrations.hubSpot.chooseAccount': 'Sélectionner un compte existant :',
	'integrations.hubSpot.chooseAccount.placeholder': 'Sélectionnez un compte',
	'integration.hubSpot.createNew': 'Ajouter un nouveau compte HubSpot',
	'integration.hubSpot.properties_title': 'Propriétés de contact (Facultatif)',
	'integration.hubSpot.properties_description': `Dans la colonne de gauche, saisissez le nom de votre propriété HubSpot. Dans la colonne de droite, mappez-les avec les variables de Snackeet.<br></br> Ex. First Name : '{{firstname}}'`,
	'integration.hubSpot.property_name.placeholder': 'Choisissez une propriété',
	'integration.hubSpot.property_value.placeholder': 'Entrez votre valeur',
	'integration.hubSpot.add_property': 'Ajouter une nouvelle propriété',

	'integrations.intercom.title': 'Intercom (Beta)',
	'integrations.intercom.description': `Créer de nouveaux contacts dans Intercom directement à partir d'une Story.`,
	'integrations.intercom.createNew': 'Ajouter un nouveau compte Intercom',

	'integrations.sendinblue.title': 'Brevo - SendinBlue',
	'integrations.sendinblue.description': 'Créer de nouveaux contacts dans <b>Brevo</b> via le contact utilisateur.',
	'integrations.sendinblue.createNew': 'Ajouter un nouveau compte Brevo',
	'integrations.sendinblue.choose_account': 'Sélectionner un compte existant :',
	'integrations.sendinblue.choose_account.placeholder': 'Sélectionnez un compte',
	'integrations.sendinblue.lists_title': 'Listes (Obligatoire)',
	'integrations.sendinblue.lists_placeholder': 'Ajoutez une liste',
	'integrations.sendinblue.lists_description':
		'Les listes et les dossiers sont utiles pour organiser et classer vos contacts.',
	'integrations.sendinblue.attributes_title': 'Attributs (Facultatif)',
	'integrations.sendinblue.attributes_description': `Dans la colonne de gauche, saisissez le nom de votre attribut Brevo <b>(Les attributs doivent être préalablement créés dans votre compte Brevo)</b>. Dans la colonne de droite, mappez-les avec les variables de Snackeet.<br></br> Ex. FIST_NAME: '{{firstname}}'`,
	'integrations.sendinblue.attributes_name.placeholder': 'Sélectionnez un attribut',
	'integrations.sendinblue.attributes_value.placeholder': 'Entrez votre valeur',
	'integrations.sendinblue.add_attribute': 'Ajouter un nouvel attribut',

	'integrations.sendinblue.add_modal.title': 'Autoriser Snackeet à accéder à votre Compte Brevo ?',
	'integrations.sendinblue.add_modal.header': 'Clé API (obligatoire)',
	'integrations.sendinblue.add_modal.description': `Copiez votre <b>API key v3</b> depuis la page <b>SMTP & API</b> dans l'option <b>Clés API</b> de votre compte Brevo pour la coller ci-dessous.`,
	'integrations.sendinblue.add_modal.error': 'Clé API invalide',
	'integrations.sendinblue.helper': 'Obtenez votre clé API',

	'integrations.klaviyo.title': 'Klaviyo',
	'integrations.klaviyo.description': 'Créer de nouvelles profiles dans Klaviyo via le contact utilisateur.',
	'integrations.klaviyo.createNew': 'Ajouter un nouveau compte Klaviyo',
	'integrations.klaviyo.choose_account': 'Sélectionner un compte existant :',
	'integrations.klaviyo.choose_account.placeholder': 'Sélectionnez un compte',
	'integrations.klaviyo.lists_title': 'Listes (Obligatoire)',
	'integrations.klaviyo.lists_placeholder': 'Ajoutez une liste',
	'integrations.klaviyo.lists_description':
		'Les listes et les dossiers sont utiles pour organiser et classer vos profiles.',
	'integrations.klaviyo.firstName_title': 'Prénom nom (Facultatif)',
	'integrations.klaviyo.lastName_title': 'Nom (Facultatif)',
	'integrations.klaviyo.organization_title': 'Organisation (Facultatif)',
	'integrations.klaviyo.attributes_title': 'Propriétés personnalisées (Facultatif)',
	'integrations.klaviyo.attributes_name.placeholder': 'Entrez une propriété',
	'integrations.klaviyo.attributes_value.placeholder': 'Entrez votre valeur',
	'integrations.klaviyo.add_attribute': 'Ajouter une nouvelle propriété personnalisée',

	'integrations.klaviyo.add_modal.title': 'Autoriser Snackeet à accéder à votre Compte Klaviyo ?',
	'integrations.klaviyo.add_modal.header': 'Clé API privée (obligatoire)',
	'integrations.klaviyo.add_modal.description': `Copiez votre <b>clé API privée</b> depuis la <b>page de compte</b> de votre compte klaviyo pour la coller ci-dessous.`,
	'integrations.klaviyo.add_modal.error': 'Clé API invalide',

	'integrations.mailerLite.title': 'MailerLite',
	'integrations.mailerLite.description': 'Créer de nouveaux Abonnées dans MailerLite via le contact utilisateurs.',
	'integrations.mailerLite.createNew': 'Ajouter un nouveau compte MailerLite',
	'integrations.mailerLite.choose_account': 'Sélectionner un compte existant :',
	'integrations.mailerLite.choose_account.placeholder': 'Sélectionnez un compte',
	'integrations.mailerLite.groups_title': 'Groupes (Facultatif)',
	'integrations.mailerLite.groups_placeholder': 'Ajoutez une groupe',
	'integrations.mailerLite.groups_not_found': `Nous n'avons trouvé aucun groupe dans votre compte MailerLite. Créez-en pour utiliser cette fonctionnalité.`,
	'integrations.mailerLite.groups_description': 'Les groupes sont utiles pour organiser et classer vos abonnés.',
	'integrations.mailerLite.fields_title': 'Champs (Facultatif)',
	'integrations.mailerLite.fields_description': `Dans la colonne de gauche, tapez le nom de votre champ d'abonné MailerLite. Dans la colonne de droite, mappez-les avec les variables de Snackeet.<br></br> Ex. company: '{{company}}'`,
	'integrations.mailerLite.fields_name.placeholder': 'Sélectionnez un champ',
	'integrations.mailerLite.fields_value.placeholder': 'Entrez votre valeurs',
	'integrations.mailerLite.add_field': 'Ajouter un nouveau champ',

	'integrations.mailerLite.add_modal.title': 'Autoriser Snackeet à accéder à votre Compte MailerLite ?',
	'integrations.mailerLite.add_modal.header': 'Clé API (Obligatoire)',
	'integrations.mailerLite.add_modal.description': `Copiez votre <b>API key v3</b> depuis la page <b>SMTP & API</b> de votre compte MailerLite pour la coller ci-dessous.`,
	'integrations.mailerLite.add_modal.error': 'Clé API invalide',

	'integrations.activeCampaign.title': 'ActiveCampaign',
	'integrations.activeCampaign.description':
		'Créer un nouveau contact dans ActiveCampaign via les données utilisateur.',
	'integrations.activeCampaign.createNew': 'Ajouter un nouveau compte ActiveCampaign',
	'integrations.activeCampaign.choose_account': 'Choisir un compte existant',
	'integrations.activeCampaign.choose_account.placeholder': 'Choisir un compte',
	'integrations.activeCampaign.lists_title': 'Listes (Facultatif)',
	'integrations.activeCampaign.lists_placeholder': 'Ajouter une liste',
	'integrations.activeCampaign.lists_not_found': `Nous n'avons trouvé aucune liste dans votre compte ActiveCampaign. Créez-en pour utiliser cette fonctionnalité.`,
	'integrations.activeCampaign.lists_description': 'Les listes sont utiles pour organiser et classer vos contacts.',
	'integrations.activeCampaign.firstName_title': 'Prénom (Facultatif)',
	'integrations.activeCampaign.lastName_title': 'Nom (Facultatif)',
	'integrations.activeCampaign.phone_title': 'Numéro de téléphone (Facultatif)',
	'integrations.activeCampaign.fieldValues_title': 'Custom Fields (Facultatif)',
	'integrations.activeCampaign.fieldValues_description': `Dans la colonne de gauche, sélectionnez votre champ de contact ActiveCampaign. Dans la colonne de droite, mappez-les avec les variables de Snackeet.<br></br> Ex. city: '{{city}}'`,
	'integrations.activeCampaign.fieldValues_name.placeholder': 'Choisir un champ',
	'integrations.activeCampaign.fieldvalues_value.placeholder': 'Entrez votre valeur',
	'integrations.activeCampaign.add_field': 'Ajouter un nouveau champ',

	'integrations.activeCampaign.add_modal.title': 'Autoriser Snackeet à accéder à votre compte ActiveCampaign ?',
	'integrations.activeCampaign.add_modal.header': `URL et clé de l'API (Obligatoire)`,
	'integrations.activeCampaign.add_modal.description': `Connectez-vous à votre compte ActiveCampaign; cliquez sur <b>Mes paramètres</b> dans le menu de votre compte, puis cliquez sur l'onglet <b>Développeur</b>.`,
	'integrations.activeCampaign.add_modal.error': `URL et clé d'API non valides`,

	// Google Sheets
	'integrations.googleSheets.title': 'Google Sheets',
	'integrations.googleSheets.title_step_1': 'Sélectionner un compte',
	'integrations.googleSheets.title_step_2': 'Sélectionner la destination des données',
	'integrations.googleSheets.choose_account': 'Sélectionner un compte existant :',
	'integrations.googleSheets.choose_account.placeholder': 'Sélectionnez un compte',
	'integrations.googleSheets.add_account': 'Ajouter un nouveau compte Google',
	'integrations.googleSheets.or': 'OU',
	'integrations.googleSheets.sheet_name': 'Nom de la feuille de calcul :',
	'integrations.googleSheets.sheet_name.placeholder': 'Entrez nom de la feuille de calcul',
	'integrations.googleSheets.sheet_select': 'Sélectionner une feuille de calcul :',
	'integrations.googleSheets.sheet_select.placeholder': 'Sélectionner une feuille de calcul',
	'integrations.googleSheets.sheet_creation': 'Créer un nouveau document',
	'integrations.googleSheets.sheet_use_existing': 'Utiliser un document existant',
	'integrations.googleSheets.description':
		'Collectez les réponses de vos utilisateurs et envoyez les directement dans votre tableau personnel.',
	'integrations.googleSheets.accountAddedInAnotherOrganization.title':
		'Ce compte est déjà utilisé dans une autre organisation.',
	'integrations.googleSheets.accountAddedInAnotherOrganization.description': `Un compte Google ne peut être utilisé que dans une seule organisation en même temps. Pour utiliser ce compte dans cette organisation veuillez retirez l'app Snackeet depuis votre compte Google : `,
	'integrations.googleSheets.accessRevoked.title': 'Accès refusé',
	'integrations.googleSheets.accessRevoked.description': `Snackeet n'a plus accès à ce compte Google. Veuillez reconnecter le compte à l'aide du bouton de connexion.`,

	'integrations.googleSheets.updating_modal.title': 'Connexion avec Google Sheet',
	'integrations.googleSheets.error_modal.title': `Erreur d'intégration de Google Sheets`,
	'integrations.googleSheets.error_modal.description': `Une erreur s'est produite lors de l'intégration avec Google Sheets.<br></br><br></br>Veuillez <b>actualiser</b> la page, puis <b>reconnecter</b> votre compte Google avec le bouton "Ajouter un nouveau compte Google" et assurez-vous que <b>toutes les demandes</b> ont été <b>autorisées</b>.`,

	'integrations.googleSheets.instructions_title': 'Instructions',
	'integrations.googleSheets.instructions': `Assurez-vous que <b>toutes les services</b> ont été <b>vérifiées</b> pour effectuer l'intégration avec <b>GoogleSheet</b>.<br></br><br></br> Si <b>Accès révoqué</b>, <b>actualisez</b> la page puis <b>reconnectez</b> votre compte Google avec le bouton "Ajouter un nouveau compte Google", assurez-vous que <b> toutes les demandes</b> ont été <b>autorisées</b>.`,
	'integrations.googleDrive.instructions': `Assurez-vous que <b>toutes les services</b> ont été <b>vérifiées</b> pour effectuer l'intégration avec <b>GoogleDrive</b>.<br></br><br></br> Si <b>Accès révoqué</b>, <b>actualisez</b> la page puis <b>reconnectez</b> votre compte Google avec le bouton "Ajouter un nouveau compte Google", assurez-vous que <b> toutes les demandes</b> ont été <b>autorisées</b>.`,

	// Google Drive
	'integrations.googleDrive.title': 'Google Drive',
	'integrations.googleDrive.title_step_1': 'Sélectionner un compte',
	'integrations.googleDrive.title_step_2': 'Sélectionner la destination des fichiers',
	'integrations.googleDrive.description':
		'Collectez facilement des fichiers de téléchargement de Snackeet Story dans Google Drive.',
	'integrations.googleDrive.destination': 'Destination :',
	'integrations.googleDrive.folder': 'Dossier :',
	'integrations.googleDrive.to_root': 'Root',
	'integrations.googleDrive.use_existing': 'Choisissez un dossier',
	'integrations.googleDrive.folder_select.placeholder': 'Choisissez un dossier',

	// Zapier
	'integrations.zapier.googleSheets.title': 'Google Sheets',
	'integrations.zapier.googleSheets.description':
		'Collectez les réponses de vos utilisateurs et envoyez les directement dans votre tableau personnel via Zapier.',

	'integrations.zapier.googleDrive.title': 'Google Drive',
	'integrations.zapier.googleDrive.description':
		'Collectez facilement des fichiers de téléchargement de Snackeet Story dans Google Drive via Zapier.',

	'integrations.zapier.dropbox.title': 'Dropbox',
	'integrations.zapier.dropbox.description':
		'Transférez automatiquement les fichiers de téléchargement des utilisateurs vers votre Dropbox via Zapier.',

	'integrations.zapier.gmail.title': 'Gmail',
	'integrations.zapier.gmail.description': `Envoyez des e-mails automatiquement via Gmail après chaque interaction avec l'intégration de Zapier.`,

	'integrations.zapier.googleDocs.title': 'Google Docs',
	'integrations.zapier.googleDocs.description':
		'Déclenchez la mise à jour de Google Docs via Zapier pour chaque interaction utilisateur sur votre Snackeet Story.',

	'integrations.zapier.slack.title': 'Slack',
	'integrations.zapier.slack.description':
		'Envoyez une notification à un individu ou dans le canal Slack via Zapier Bot chaque fois que vous recevez de nouvelles interactions des utilisateurs.',

	'integrations.zapier.microsoftTeams.title': 'Microsoft Teams',
	'integrations.zapier.microsoftTeams.description': `Avertissez votre chaîne Teams avec des interactions Snackeet en temps réel afin que vous et votre équipe puissiez réagir en un instant.`,

	'integrations.zapier.oneDrive.title': 'OneDrive',
	'integrations.zapier.oneDrive.description':
		'Collectez facilement des fichiers de téléchargement de Snackeet Story dans OneDrive.',

	'integrations.zapier.mailchimp.title': 'Mailchimp',
	'integrations.zapier.mailchimp.description': `Envoyez de nouveaux contacts à vos listes Mailchimp et identifiez-les afin qu'ils soient faciles à organiser.`,

	'integrations.zapier.twitter.title': 'Twitter',
	'integrations.zapier.twitter.description':
		'Diffusez les interactions Snackeet sur Twitter. Partagez du contenu avec le monde.',

	'integrations.zapier.discord.title': 'Discord',
	'integrations.zapier.discord.description':
		'Envoyez une notification à un individu ou dans le canal Discord via Zapier Bot chaque fois que vous recevez de nouvelles interactions des utilisateurs.',

	// Instagram
	'integrations.instagram.delete_account.title': 'Supprimer le compte',
	'integrations.instagram.delete_account.instructions.first_line': `Voulez-vous supprimer l'accès à ce compte ?`,
	'integrations.instagram.delete_account.instructions.second_line': `En supprimant l'accès, vous ne pourrez plus importer les posts et les stories Instagram depuis cet éditeur.<br></br><br></br>Cependant, vous pourrez toujours ajouter le compte anouveau et réautoriser.`,

	'integrations.instagram.accounts.title': 'Comptes des Réseaux Sociaux',
	'integrations.instagram.accounts.empty': 'Pas encore de compte',
	'integrations.instagram.accounts.button.back': 'Retour',
	'integrations.instagram.accounts.button.manage': 'Gérer les comptes',
	'integrations.instagram.accounts.button.login': 'Connexion avec Facebook',
	'integrations.instagram.accounts.alert.error.title': 'Autorisations',
	'integrations.instagram.accounts.alert.error.description': `Afin d'importer les images et les vidéos des posts et des stories, nous avons besoin des autorisations suivantes:`,
	'integrations.instagram.accounts.alert.warning.title': 'Intégration Instagram',
	'integrations.instagram.accounts.alert.warning.description': `Afin d'utiliser les stories Instagram, veuillez vous assurer d'avoir un <b>Compte professionel Instagram</b> et que ce compte soit <b>connecté à une Page Facebook</b>.`,
	'integrations.instagram.accounts.alert.warning.link': 'Comment?',
	'integrations.instagram.accounts.error':
		'La synchronisation avec Instagram rencontre temporairement des problèmes sur votre navigateur. Veuillez rafraîchir la page web et réessayer. Si le problème persiste, envisagez de changer de navigateur ou de contacter le support.',

	'integrations.instagram.media_view.empty': 'Pas de {assetsType} disponibles',
	'integrations.instagram.media_view.error.permission.title': '<b>Autorisations manquantes:</b>',
	'integrations.instagram.media_view.error.permission.description':
		'Assurez vous d’avoir accepté toutes les autorisations.',
	'integrations.instagram.media_view.limitations.availability':
		'Les stories Instagram sont affichées ici uniquement pendant <b>24 heures après leur publication.</b>',
	'integrations.instagram.media_view.limitations.story_types':
		'Les stories de vidéos en direct et les nouvelles stories créées lorsqu’un utilisateur repartage <b>ne sont pas</b> affichées',
	'integrations.instagram.media_view.button.open_link': 'Ouvrir dans un nouvel onglet',
}
