export const fr = {
	'login_register.mail_placeholder': 'Email',
	'login_register.username_placeholder': "Nom d'utilisateur",
	'login_register.pwd_placeholder': 'Mot de passe',
	'login_register.confirm_pwd_placeholder': 'Confirmer votre mot de passe',
	'login.main_button': 'SE CONNECTER',
	'login.secondary_button': 'Mot de passe oublié ?',

	'register.title': 'Créer un nouveau compte',

	'login.title': 'Se connecter',
	'password_reset.title': 'Réinitialiser le mot de page',
	'password_reset.main_button': 'CRÉER NOUVEAU MOT DE PASSE',
	'password_reset.secondary_button': 'Retourner à la page de connexion',

	'login.error_header': 'Identifiants invalides',
	'login.error_content': 'Vos identifiants sont incorrects.',
	'login.reset_password_header': 'Votre nouveau mot de passe a été envoyé par email.',
	'login.reset_password_message': 'Veillez à vérifier votre dossier spam.',

	'login.button.sign_in': 'Se connecter avec Google',
	'login.no_account_yet': 'Nouveau sur Snackeet ?',
	'login.signup': `Créez un compte gratuitement`,

	'register.use_what_for': 'Que voulez-vous accomplir?',

	'register.create_account': 'Créer votre compte',
	'register.creating_account': 'Création du compte...',
	'register.has_account': 'Vous avez déjà un compte ?',
	'register.secondary_button': 'Page de connexion',
	'register.cgu': `J'accepte les <a href={{TOS}} rel="noopener noreferrer" target="_blank">conditions d'utilisation</a> et la <a href={{PP}} rel="noopener noreferrer">politique de confidentialité</a>`,

	'register.create_username.title': 'Création nom utilisateur',
	'register.create_username.instruction': `Dernière étape: vous devez créer un nom d'utilisateur unique pour utiliser nos services`,
	'register.create_username.button.default': "Créer nom d'utilisateur",
	'register.create_username.button.submitting': 'Création en cours...',

	'register.app_sumo': 'Votre compte est presque prêt',

	'captcha.error.missing-input-secret': '[CAPTCHA] Le paramètre secret est manquant.',
	'captcha.error.invalid-input-secret': '[CAPTCHA] Le paramètre secret est invalide ou malformé.',
	'captcha.error.missing-input-response': '[CAPTCHA] Le paramètre de réponse est manquant.',
	'captcha.error.invalid-input-response': '[CAPTCHA] Le paramètre de réponse est invalide ou malformé.',
	'captcha.error.bad-request': '[CAPTCHA] La requête est invalide ou malformée.',
	'captcha.error.timeout-or-duplicate':
		"[CAPTCHA] La réponse n'est plus valide : elle est trop ancienne ou a déjà été utilisée.",

	'password_reset.error.too-soon': 'Toujours en cours de traitement... Veuillez réessayer dans quelques minutes',
	'password_reset.error.email-error': `Le courriel n'a pas pu être envoyé... Veuillez réessayer dans quelques minutes`,
	'password_reset.error.email-unregistered': `Cette adresse email n'est pas enregistrée`,

	'form_error.email_required': 'Requis',
	'form_error.password_required': 'Aucun mot de passe fourni.',
	'form_error.password_too_short': 'Le mot de passe est trop court - 8 caractères minimum.',
	'form_error.password_too_long': 'Le mot de passe est trop long - 48 caractères maximum.',
	'form_error.password_no_number': 'Le mot de passe doit contenir un chiffre.',
	'form_error.password_confirm_required': 'Ce champ est requis',
	'form_error.password_confirm_mismatch': 'Les deux mots de passe doivent être identiques',
	'form_error.cgv_accepted': `L'acceptation des conditions d'utilisation et de la politique de confidentialité est requise pour s'inscrire`,

	'shopify_oauth.login_title': 'Connectez-vous pour vous connecter à Shopify',
	'shopify_oauth.connect_title':
		'Choisissez une organisation et des espaces de travail à connecter à votre boutique Shopify',
	'shopify_oauth.organization_selection': 'Sélectionnez une organisation',
	'shopify_oauth.organization_placeholder': 'Choisissez une organisation',
	'shopify_oauth.workspace_selection': 'Sélectionnez des espaces de travail',

	'shopify_oauth.connect_successfully': 'Connexion réussie avec Shopify',
}
