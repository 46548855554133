export const en = {
	'integrations.filter.title': 'Filter by categories',
	'integrations.filter.all': 'All categories',
	'integrations.filter.analytics': 'Analytics & reporting',
	'integrations.filter.files': 'Files management',
	'integrations.filter.automation': 'CRM & marketing automation',
	'integrations.filter.webhook': 'Webhooks',

	'integrations.platform.zapier.description': `Zapier lets you connect Snackeet with thousands of the most popular apps, so you can automate your work and have more time for what matters most—no code required.`,
	'integrations.platform.zapier.button': `Connect Snackeet to 4000+ apps`,
	'integrations.platform.pabblyConnect.description': `Connect Snackeet with more than 800+ apps. Just select the app and you're good to go. No installation required!`,
	'integrations.platform.pabblyConnect.button': `Connect Snackeet to 800+ apps`,

	'integrations.webhook.header': 'Webhook Integrations',
	'integrations.webhook.description': `A webhook is a way to send data from one application to another in real-time over the internet.`,
	'integrations.webhook.add_new': 'Add a new Webhook',
	'integrations.webhook.instruction': 'Learn more about webhook integration',
	'integratins.webhook.completed_only': 'Completed Only',
	'integratins.webhook.hotspot.completed_only.title': 'Only completed interactions',
	'integratins.webhook.hotspot.completed_only.message':
		'Webhook only triggers when users complete the Story. (Story completed by reaching end page)',

	'integrations.trackingCode': 'Tracking Code:',

	'integrations.googleAnalytics.title': 'Google Analytics',
	'integrations.googleAnalytics.description':
		'Discover how people find and interact with your Story. Measure campaigns, improve conversions, and more.',
	'integrations.googleAnalytics.placeholder': 'Tracking Code - ex. UA-XXXXX-Y or G-XXXXXXXXXX',
	'integrations.googleAnalytics.placeholder_amp': 'Tracking Code - ex. UA-XXXXX-Y',
	'integrations.googleAnalytics.amp_warning':
		'Google AMP does not support Google Analytics 4 yet. Only support UA-XXXXXXX-X code.',

	'integrations.googleTagManager.title': 'Google Tag Manager',
	'integrations.googleTagManager.description':
		'Add your own code snippets to your Snackeet Story for conversion tracking, site analytics, and more.',
	'integrations.googleTagManager.placeholder': 'Tracking Code - ex. GTM-XXXXXX',

	'integrations.facebookPixel.title': 'Facebook Pixel',
	'integrations.facebookPixel.description':
		'Add your <b>Facebook pixel ID</b> and get all the data you need to measure and optimize your marketing campaigns.',
	'integrations.facebookPixel.placeholder': 'Tracking Code - ex. 123456789012345',

	'integrations.googleAdSense.title': 'Google AdSense',
	'integrations.googleAdSense.description': 'You can use AdSense to show ads in your Web Stories.',
	'integrations.googleAdSense.data_client.description': 'Example: ca-pub-0000000000000000',
	'integrations.googleAdSense.data_slot.description': 'Example: 00000000',
	'integrations.google_adsense.error': `Both <b>data-ad-client</b> and <b>data-slot</b> must be filled.`,

	'integrations.googleAdManager.title': 'Google Ad Manager',
	'integrations.googleAdManager.data_slot.placeholder': 'Example: /30497360/a4a/amp_story_dfp_example',
	'integrations.google_ad_manager.error': `<b>data-slot</b> cannot be empty`,

	'integrations.ads.title': 'Setup Advertising',
	'integrations.ads.subtitle': `Monetize your Web Stories by showing ads while the user consumes their content.<br></br>Your story must have at least <b>8 pages</b> to display ads.`,
	'integrations.ads.platform.label': 'Advertisement Platform',
	'integrations.ads.platform.placeholder': 'Choose a platform',

	'integrations.code.error.empty': 'Cannot submit empty tracking code!',
	'integrations.code.error.facebookPixel': 'Invalid Pixel ID',
	'integrations.code.error.googleAnalytics': 'Only support UA code',

	'integrations.hubSpot.title': 'HubSpot',
	'integrations.hubSpot.description': 'Create new contacts and attach responses in HubSpot directly from a Story.',
	'integrations.connectWith': 'Connected with: ',
	'integrations.hubSpot.chooseAccount': 'Choose an existing account:',
	'integrations.hubSpot.chooseAccount.placeholder': 'Choose an account',
	'integration.hubSpot.createNew': 'Add a new HubSpot account',
	'integration.hubSpot.properties_title': 'Contact Properties (Optional)',
	'integration.hubSpot.properties_description': `In the left column, type the name of your HubSopt property. In the right column, map them with variables of Snackeet.<br></br> Ex. First Name: '{{firstname}}'`,
	'integration.hubSpot.property_name.placeholder': 'Choose a property',
	'integration.hubSpot.property_value.placeholder': 'Enter your value',
	'integration.hubSpot.add_property': 'Add New Property',

	'integrations.intercom.title': 'Intercom (Beta)',
	'integrations.intercom.description': `Create new contacts in Intercom directly from a Story.`,
	'integrations.intercom.createNew': 'Add a new Intercom account',

	'integrations.sendinblue.title': 'Brevo - SendinBlue',
	'integrations.sendinblue.description': 'Create new contacts in <b>Brevo</b> via user contact.',
	'integrations.sendinblue.createNew': 'Add a new Brevo account',
	'integrations.sendinblue.choose_account': 'Choose an existing account:',
	'integrations.sendinblue.choose_account.placeholder': 'Choose an account',
	'integrations.sendinblue.lists_title': 'Lists (Required)',
	'integrations.sendinblue.lists_placeholder': 'Add a list',
	'integrations.sendinblue.lists_description':
		'Lists and folders are helpful to organize and categorize your contacts.',
	'integrations.sendinblue.attributes_title': 'Attributes (Optional)',
	'integrations.sendinblue.attributes_description': `In the left column, type the name of your Brevo attribute <b>(Attributes have to be created in your Brevo account beforehand)</b>. In the right column, map them with variables of Snackeet.<br></br> Ex. FIRST_NAME: '{{firstname}}'`,
	'integrations.sendinblue.attributes_name.placeholder': 'Choose an attribute',
	'integrations.sendinblue.attributes_value.placeholder': 'Enter your value',
	'integrations.sendinblue.add_attribute': 'Add New Attribute',
	'integrations.sendinblue.helper': 'Get your API key',

	'integrations.sendinblue.add_modal.title': 'Allow Snackeet to access your Brevo Account?',
	'integrations.sendinblue.add_modal.header': 'API Key (Required)',
	'integrations.sendinblue.add_modal.description': `Copy your <b>API v3 key</b> from the <b>SMTP & API page</b>, option <b>API Keys</b> of your Brevo account to paste it below.`,
	'integrations.sendinblue.add_modal.error': 'API Key invalid',

	'integrations.klaviyo.title': 'Klaviyo',
	'integrations.klaviyo.description': 'Create new profiles in Klaviyo via user contact.',
	'integrations.klaviyo.createNew': 'Add a new Klaviyo account',
	'integrations.klaviyo.choose_account': 'Choose an existing account:',
	'integrations.klaviyo.choose_account.placeholder': 'Choose an account',
	'integrations.klaviyo.lists_title': 'Lists (Required)',
	'integrations.klaviyo.lists_placeholder': 'Add a list',
	'integrations.klaviyo.lists_description': 'Lists are helpful to organize and categorize your profiles.',
	'integrations.klaviyo.firstName_title': 'First name (Optional)',
	'integrations.klaviyo.lastName_title': 'Last name (Optional)',
	'integrations.klaviyo.organization_title': 'Organization (Optional)',
	'integrations.klaviyo.attributes_title': 'Custom properties (Optional)',
	'integrations.klaviyo.attributes_name.placeholder': 'Enter a property',
	'integrations.klaviyo.attributes_value.placeholder': 'Enter your value',
	'integrations.klaviyo.add_attribute': 'Add A New Custom Property',

	'integrations.klaviyo.add_modal.title': 'Allow Snackeet to access your klaviyo Account?',
	'integrations.klaviyo.add_modal.header': 'Private API Key (Required)',
	'integrations.klaviyo.add_modal.description': `Copy your <b>Private API Key</b> from the <b>Account page</b> of your klaviyo account to paste it below.`,
	'integrations.klaviyo.add_modal.error': 'Private API Key invalid',

	'integrations.mailerLite.title': 'MailerLite',
	'integrations.mailerLite.description': 'Create new subscriber in MailerLite via user contact.',
	'integrations.mailerLite.createNew': 'Add a new MailerLite account',
	'integrations.mailerLite.choose_account': 'Choose an existing account:',
	'integrations.mailerLite.choose_account.placeholder': 'Choose an account',
	'integrations.mailerLite.groups_title': 'Groups (Optional)',
	'integrations.mailerLite.groups_placeholder': 'Add a group',
	'integrations.mailerLite.groups_not_found': `We couldn't find any groups in your MailerLite account. Create some to use this feature.`,
	'integrations.mailerLite.groups_description': 'Groups are helpful to organize and categorize your Subscribers.',
	'integrations.mailerLite.fields_title': 'Fields (Optional)',
	'integrations.mailerLite.fields_description': `In the left column, type the name of your MailerLite Subscriber field. In the right column, map them with variables of Snackeet.<br></br> Ex. company: '{{company}}'`,
	'integrations.mailerLite.fields_name.placeholder': 'Choose an field',
	'integrations.mailerLite.fields_value.placeholder': 'Enter your value',
	'integrations.mailerLite.add_field': 'Add New Field',

	'integrations.mailerLite.add_modal.title': 'Allow Snackeet to access your MailerLite Account?',
	'integrations.mailerLite.add_modal.header': 'API Key (Required)',
	'integrations.mailerLite.add_modal.description': `Copy your <b>API v3 key</b> from the <b>SMTP & API page</b> of your MailerLite account to paste it below.`,
	'integrations.mailerLite.add_modal.error': 'API Key invalid',

	'integrations.activeCampaign.title': 'ActiveCampaign',
	'integrations.activeCampaign.description': 'Create new contact in ActiveCampaign via user data.',
	'integrations.activeCampaign.createNew': 'Add a new ActiveCampaign account',
	'integrations.activeCampaign.choose_account': 'Choose an existing account:',
	'integrations.activeCampaign.choose_account.placeholder': 'Choose an account',
	'integrations.activeCampaign.lists_title': 'Lists (Optional)',
	'integrations.activeCampaign.lists_placeholder': 'Add a list',
	'integrations.activeCampaign.lists_not_found': `We couldn't find any lists in your ActiveCampaign account. Create some to use this feature.`,
	'integrations.activeCampaign.lists_description': 'Lists are helpful to organize and categorize your contacts.',
	'integrations.activeCampaign.firstName_title': 'First name (Optional)',
	'integrations.activeCampaign.lastName_title': 'Last name (Optional)',
	'integrations.activeCampaign.phone_title': 'Phone number (Optional)',
	'integrations.activeCampaign.fieldValues_title': 'Custom Fields (Optional)',
	'integrations.activeCampaign.fieldValues_description': `In the left column, select your ActiveCampaign contact field. In the right column, map them with variables of Snackeet.<br></br> Ex. city: '{{city}}'`,
	'integrations.activeCampaign.fieldValues_name.placeholder': 'Choose a field',
	'integrations.activeCampaign.fieldvalues_value.placeholder': 'Enter your value',
	'integrations.activeCampaign.add_field': 'Add New Field',

	'integrations.activeCampaign.add_modal.title': 'Allow Snackeet to access your ActiveCampaign Account?',
	'integrations.activeCampaign.add_modal.header': 'API URL & Key (Required)',
	'integrations.activeCampaign.add_modal.description': `Login to your ActiveCampaign account; click <b>My Settings</b> in your account menu, then click the <b>Developer</b> tab.`,
	'integrations.activeCampaign.add_modal.error': 'API Url & Key invalid',

	// GoogleSheets
	'integrations.googleSheets.title': 'Google Sheets',
	'integrations.googleSheets.title_step_1': 'Select an account',
	'integrations.googleSheets.title_step_2': 'Choose Data destination',
	'integrations.googleSheets.choose_account': 'Choose an existing account:',
	'integrations.googleSheets.choose_account.placeholder': 'Choose an account',
	'integrations.googleSheets.add_account': 'Add a new Google account',
	'integrations.googleSheets.or': 'OR',
	'integrations.googleSheets.sheet_name': 'Name of the Worksheet:',
	'integrations.googleSheets.sheet_name.placeholder': 'Enter name of the Worksheet',
	'integrations.googleSheets.sheet_select': 'Select a Worksheet:',
	'integrations.googleSheets.sheet_select.placeholder': 'Select a worksheet',
	'integrations.googleSheets.sheet_creation': 'Create a new document',
	'integrations.googleSheets.sheet_use_existing': 'Use an existing document',
	'integrations.googleSheets.description':
		'Collect responses from your users and send them directly to your personal sheet.',
	'integrations.googleSheets.accountAddedInAnotherOrganization.title':
		'This account is already used in an other organization.',
	'integrations.googleSheets.accountAddedInAnotherOrganization.description': `A Google Account can only be used in one organization at the same time. To use this account in this organization please revoke the Snackeet app from your Google account : `,
	'integrations.googleSheets.accessRevoked.title': 'Access revoked',
	'integrations.googleSheets.accessRevoked.description':
		'Snackeet no longer has access to this Google account. Please reconnect it using the connect button.',

	'integrations.googleSheets.updating_modal.title': 'Connecting with Google Sheet',
	'integrations.googleSheets.error_modal.title': 'Connect Google Sheets Error',
	'integrations.googleSheets.error_modal.description': `Something went wrong with the integration with Google Sheets.<br></br><br></br>Please <b>refresh</b> the page then <b>reconnect</b> your Google Account with the button 'Add a new Google Account' and make sure that <b>all demands</b> have been <b>checked</b>.`,

	'integrations.googleSheets.instructions_title': 'Instructions',
	'integrations.googleSheets.instructions': `Make sure that <b>all demands</b> have been <b>checked</b> to perform the integration with <b>GoogleSheet</b>.<br></br><br></br>If <b>Access Revoked</b>, <b>refresh</b> the page then <b>reconnect</b> your Google Account with the button 'Add a new Google Account', make sure that <b>all demands</b> have been <b>checked</b>.`,
	'integrations.googleDrive.instructions': `Make sure that <b>all demands</b> have been <b>checked</b> to perform the integration with <b>GoogleDrive</b>.<br></br><br></br>If <b>Access Revoked</b>, <b>refresh</b> the page then <b>reconnect</b> your Google Account with the button 'Add a new Google Account', make sure that <b>all demands</b> have been <b>checked</b>.`,

	// Google Drive
	'integrations.googleDrive.title': 'Google Drive',
	'integrations.googleDrive.title_step_1': 'Select an account',
	'integrations.googleDrive.title_step_2': 'Choose Files destination',
	'integrations.googleDrive.description': 'Easily collect uploaded files from Snackeet Story into your Google Drive.',
	'integrations.googleDrive.destination': 'Destination:',
	'integrations.googleDrive.folder': 'Folder:',
	'integrations.googleDrive.to_root': 'Root',
	'integrations.googleDrive.use_existing': 'Choose a folder',
	'integrations.googleDrive.folder_select.placeholder': 'Choose a folder',

	// Zapier
	'integrations.zapier.googleSheets.title': 'Google Sheets',
	'integrations.zapier.googleSheets.description':
		'Collect responses from your users and send them directly to your personal sheet via Zapier.',

	'integrations.zapier.googleDrive.title': 'Google Drive',
	'integrations.zapier.googleDrive.description':
		'Easily collect uploaded files from Snackeet Story into your Google Drive with Zapier integration.',

	'integrations.zapier.dropbox.title': 'Dropbox',
	'integrations.zapier.dropbox.description': 'Automatically transfer user upload files to your Dropbox via Zapier.',

	'integrations.zapier.gmail.title': 'Gmail',
	'integrations.zapier.gmail.description':
		'Send mail automatically via Gmail after each interaction with Zapier integration.',

	'integrations.zapier.googleDocs.title': 'Google Docs',
	'integrations.zapier.googleDocs.description':
		'Trigger update Google Docs via Zapier for each user interaction on your Snackeet Story.',

	'integrations.zapier.slack.title': 'Slack',
	'integrations.zapier.slack.description':
		'Send notification to individual or into Slack channel via Zapier Bot whenever receive new interactions from users.',

	'integrations.zapier.microsoftTeams.title': 'Microsoft Teams',
	'integrations.zapier.microsoftTeams.description':
		'Notify your Teams channel with real-time Snackeet interactions so you and your team can react in an instant.',

	'integrations.zapier.oneDrive.title': 'OneDrive',
	'integrations.zapier.oneDrive.description':
		'Easily collect uploaded files from Snackeet Story into your OneDrive with Zapier integration.',

	'integrations.zapier.mailchimp.title': 'Mailchimp',
	'integrations.zapier.mailchimp.description': `Send new contacts to your Mailchimp lists, and tag them so they're easy to organize.`,

	'integrations.zapier.twitter.title': 'Twitter',
	'integrations.zapier.twitter.description':
		'Broadcast Snackeet interactions to Twitter. Share content with the world.',

	'integrations.zapier.discord.title': 'Discord',
	'integrations.zapier.discord.description':
		'Send notification to individual or into Discord channel via Zapier Bot whenever receive new interactions from users.',

	// Instagram
	'integrations.instagram.delete_account.title': 'Delete the account',
	'integrations.instagram.delete_account.instructions.first_line': `Do you want to remove access to this account?`,
	'integrations.instagram.delete_account.instructions.second_line': `By removing access, you will no longer be able to import Instagram posts and stories from this editor.<br></br><br></br>However, you will still be able to add the new account and reauthorize.`,

	'integrations.instagram.accounts.title': 'Social Media Accounts',
	'integrations.instagram.accounts.empty': 'No accounts yet',
	'integrations.instagram.accounts.button.back': 'Back to media',
	'integrations.instagram.accounts.button.manage': 'Manage accounts',
	'integrations.instagram.accounts.button.login': 'Login with Facebook',
	'integrations.instagram.accounts.alert.error.title': 'Permissions',
	'integrations.instagram.accounts.alert.error.description':
		'In order to import the images and videos of posts and stories, we need the following permissions:',
	'integrations.instagram.accounts.alert.warning.title': 'Instagram integration',
	'integrations.instagram.accounts.alert.warning.description':
		'In order to use Instagram stories, make sure you have an <b>Instagram Business Account</b> and the account is <b>connected to a Facebook Page</b>.',
	'integrations.instagram.accounts.alert.warning.link': 'Learn how?',
	'integrations.instagram.accounts.error':
		'Synchronization with Instagram is temporarily experiencing issues on your browser. Please refresh the webpage and try again. If the issue persists, consider changing your browser or contacting support.',

	'integrations.instagram.media_view.empty': 'No {assetsType} available',
	'integrations.instagram.media_view.error.permission.title': '<b>Missing permissions:</b>',
	'integrations.instagram.media_view.error.permission.description':
		'Please make sure all necessary permissions are granted.',
	'integrations.instagram.media_view.limitations.availability':
		'Your Instagram stories are displayed here only for <b>24h after their publication.</b>',
	'integrations.instagram.media_view.limitations.story_types':
		'Live Video stories and new stories created when a user reshares will <b>not be displayed.</b>',
	'integrations.instagram.media_view.button.open_link': 'Open in new tab',
}
